.single-payment {
  .download-pdf {
    font-size: 1rem !important;
    display: flex;
    align-items: center;

    svg {
      width: 1.125em;
      height: 1.125em;
      stroke: black;
      margin-right: 0.75em;
    }
  }
}
