// Theme
$theme-font: "Muli", sans-serif;
$theme-color: #bd9732;
$theme-color-body-background: #f4f4f6;
$theme-color-alt: #61666c;

// Header

// Footer
$footer-color: #000;
// Expert availability
$main-container-background: #33333f;

//Navbar background color
$bg-background-color-black: #33333f;

//card
$card-header-default-color: #75f0ce;

:export {
  cardHeaderDefaultColor: $card-header-default-color;
}

// Buttons
$card-button-color: #52c297;
$file-preview-delete-button-hover: #00668e;
$button-google-login: #298dfa;
$button-office-login: #d93c01;
$button-trash-hover: #00668e;
$button-btn-login: #df674c;
$button-btn-login-hover: #ff876c;

// Primary Button
$primary-button-color: #009dda;

// Buttons
$button-outline-grayed-color: rgba(41, 41, 52, 0.5);
$button-outline-grayed-border: 1px solid rgba(41, 41, 52, 0.5);

// Secondary Button
$secondary-button-color-hover: #037eae;

// Link
$color-link-active: #20a8d8;

// Label
$color-label: #666;
$text-inline-grayed: rgba(41, 41, 52, 0.5);

// Text
$input-group-text-border: rgba(41, 41, 52, 0.25);

// Skills Badge
$badge-dark-border: 1px solid rgba(41, 41, 52, 0.25);

// input colors
$color-input-gray: #e7e7ea;
$color-input-gray-dark: #b8b8bd;
$color-input-dark: #292934;

$color-primary: #1e5389;
$color-primary-alt: #20a8d8;

// Error Message
$error-message-color: #dc3545;

//Status
$color-draft: #f8c017;
$color-active: #59c497;

// Tabs specific colors
$tab-inactive-border: #e2e2e8;

// Common Colors
$color-white: #fff;
$color-dark: #292934;
$color-light-grey: #e9e9e9;
$color-black: #000;
$color-light-black: #23282c;
$color-blue: #458bd6;
$color-red: #f00;
$color-orange: #df674c;
$color-light-orange: #ea6953;
$color-grey-dark: #9a9a9a;
$color-grey-darker: #676d72;
$color-cerulean: #009dda;
$color-cerulean-faded: #e5f5fb;
$color-cerulean-alt: #00b3d3;
$color-cerulean-hover: #d9f1fa;
$color-science-blue: #006dda;
$color-alert-red: #a94442;
$color-desert: #f5eee5;
$primary-blue-color: #0a9dda;
$color-pale-background: #f8f6ed;
$color-background-gray: #f3f3f4;
$color-background-gray1: #f3f3f5;
$color-background-gray2: #ddd;
$color-notification-orange: #e86c61;
$color-notification-orange-faded: #fcf0ed;
$color-yellow: #bd9732;
$color-green: #008000;
$color-dark-purple: #2a2a34;
$color-success: #008000;
$color-pending: #cacacd;
$color-danger: #2f353a;
$color-alert-green: #0ac297;
$color-light-red: #f61a1e;
$color-violet: #636bf7;
$color-violet-dark: #5a45f8;

//font size variables
$small-font-size: 12px;
$span-font-size: 14px;
$message-font-size: 15px;
$button-font-size: 16px;
$label-font-size: 18px;
$sub-menu-font-size: 19px;
$paragraph-font-size: 20px;
$header-section-font-size: 22px;
$header-font-size: 27px;
$footer-font-size: 14px;

// bootstrap overwrites
$color-success: #0ac297;
$color-success-faded: #e6f8f4;

$theme-colors: (
  "dark": #292934,
  "success": $color-success,
  "primary": $color-cerulean,
  "danger": $color-orange
);

//Custom Colors
$color-light-greyed: rgba(51, 51, 51, 0.16);
$color-white-light-gray: #ccc;
$color-nav-active: #009dda;
$color-nav-link: #212529;
$color-light-white: #e6f3ff;
$color-light-white1: #cce7ff;
$color-light-white2: #f0f0f0;
$color-light-white3: rgb(243, 243, 244);
$color-light-white4: #e2e2e7;
$color-grey-dark1: #a7a7a7;
$color-grey-dark2: #aaa;
$color-light-grey1: #c1c1c1;
$color-light-grey2: #c8c8cb;
$color-light-grey3: #d3d3d3;
