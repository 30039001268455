.find-an-expert-left-side-section-background-image {
  .partner-logos {
    .img-wrapper {
      img {
        &:not(:last-child) {
          margin-right: 4rem;
        }
      }
    }
  }
  .welcome-alternative {
    margin-bottom: 209px !important;
    + .partner-logos {
      margin-bottom: 27vh;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .find-an-expert-budget-right-side-section {
    padding: 7rem 2rem 7rem 2rem !important;
  }
}
// wizard related styles
.find-an-expert-budget-right-side-section {
  padding: 7rem 7rem 7rem 7.375rem;
  margin-top: 0;
  margin-bottom: 0;
  justify-content: stretch;
  flex-wrap: nowrap !important;
  min-height: 100%;
  .btn-wrapper {
    margin: 1.5rem 0 0;
    .btn {
      font-size: 0.875rem;
      font-weight: bold;
    }
    svg {
      height: 20px;
      margin: 0 0 0 0.5rem;
    }
  }
  > form {
    margin-top: auto;
    margin-bottom: auto;
  }
  &.form-wrapper {
    .field-wrapper {
      margin-bottom: 0;
      label {
        font-weight: 700;
      }
    }
  }
  .content-wrapper {
    max-width: 484px;
    margin-bottom: 2.5rem;
    &.custom-width {
      @include medium-desktop {
        margin-right: -30px;
      }
      @include tablet {
        margin-right: 0;
      }
    }
    .content {
      margin-top: 1.5rem;
    }
  }
  .accepted-terms {
    margin-right: 0 !important;
    padding-bottom: 0;
  }
}

// salesforce alternative styling
.welcome-alternative {
  span.d-flex {
    flex-wrap: wrap;
  }
  img {
    margin: 0 1rem;
    // max-width: 95px;
  }
}

// Expert Request Square Box starts
.expert-request-active-btn-section {
  margin: 0 0 1.5rem;
  .box {
    border-radius: 100%;
    height: 11px;
    width: 11px;
    background-color: $color-background-gray;
    &:not(:last-child) {
      margin: 0 0.5rem 0 0;
    }
    &.active {
      background-color: #3a9eda;
    }
  }
}

// signup buttons
.signup-buttons {
  margin-top: 2rem;
  .icon-wrapper {
    width: 26px;
    display: block;
    margin-right: 2rem;
    height: 18px;
    svg {
      width: auto;
      display: block;
      height: 100%;
      max-width: 100%;
    }
  }
  .signup-button {
    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  }
  .signup-button a,
  .google-login {
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: flex-start;
    padding: 0.325rem 1.25rem !important;
    width: 360px;
    border-radius: 4px;
    &:not(.btn-link) {
      color: $color-white;
      &:hover {
        color: $color-white;
        opacity: 0.9;
        text-decoration: none;
      }
    }
  }
  .google-login {
    border-radius: 4px !important;
    font-family: inherit !important;
    font-size: 1rem !important;
    > div {
      width: 26px;
      display: block;
      margin-right: 2rem !important;
      height: 18px;
      padding: 0 !important;
      svg {
        width: auto;
        display: block;
        height: 100%;
        max-width: 100%;
      }
    }
    > span {
      padding: 0 !important;
    }
  }
  .button-salesforce {
    background-color: #01a0e1;
  }
  .button-google {
    background-color: #4287f6;
  }
  .button-office {
    background-color: #d93c01;
  }
  .button-linkedin {
    background-color: #0277b6;
  }
  .btn-link {
    color: $color-cerulean;
  }
}
// Expert Request Square Box Ends
// Wizard logos
.find-an-expert-contact-information-icon {
  width: 46px;
  height: 46px;
  background: url("assets/pages/find-an-expert/icon-sprite.png") -145px -10px;
}

.find-an-expert-experts-icon {
  width: 40px;
  height: 46px;
  background: url("assets/pages/find-an-expert/icon-sprite.png") -275px -10px;
}

.find-an-expert-experts-type-icon {
  width: 46px;
  height: 46px;
  background: url("assets/pages/find-an-expert/icon-sprite.png") -10px -83px;
}

.find-an-expert-get-started-icon {
  width: 46px;
  height: 35px;
  background: url("assets/pages/find-an-expert/icon-sprite.png") -74px -149px;
}

.find-an-expert-location-icon {
  width: 46px;
  height: 46px;
  background: url("assets/pages/find-an-expert/icon-sprite.png") -76px -83px;
}

.find-an-expert-project-type-icon {
  width: 44px;
  height: 44px;
  background: url("assets/pages/find-an-expert/icon-sprite.png") -10px -149px;
}

.find-an-expert-request-received-icon {
  width: 46px;
  height: 45px;
  background: url("assets/pages/find-an-expert/icon-sprite.png") -142px -83px;
}

.find-an-expert-start-date-icon {
  width: 49px;
  height: 47px;
  background: url("assets/pages/find-an-expert/icon-sprite.png") -76px -10px;
}

.find-an-expert-timeline-icon {
  width: 44px;
  height: 48px;
  background: url("assets/pages/find-an-expert/icon-sprite.png") -211px -10px;
}

.find-an-expert-time-commitment-icon {
  width: 44px;
  height: 48px;
  background: url("assets/pages/find-an-expert/icon-sprite.png") -211px -78px;
}

.find-an-expert-budget-icon {
  width: 46px;
  height: 53px;
  background: url("assets/pages/find-an-expert/icon-sprite.png") -10px -10px;
}
