// global dropdowns?
.dropdown-wrapper {
  .dropdown-menu {
    border-radius: 0;
    border: none;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    z-index: 100;
  }
  .dropdown-item {
    margin: 0.5rem 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    &:focus {
      outline: none;
    }
  }
  .dropdown-item,
  .dropdown-item:active,
  .dropdown-item.active {
    background-color: transparent;
    box-shadow: none;
    color: inherit;
  }
  .btn-group {
    #caret + .dropdown-toggle {
      &:after {
        content: none;
      }
    }
    .dropdown-toggle {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
  .btn-gray {
    background-color: $color-input-gray;
  }
  &.select-dropdown {
    display: inline-flex;
    .dropdown-toggle {
      display: flex;
      align-items: center;
      svg {
        margin: 0 0 0 0.5rem;
        max-width: 15px;
      }
    }
  }
  .dropdown-menu {
    width: auto;
  }
  .hide-caret {
    &:after {
      content: none;
    }
  }
}

// more dropdown from cards .. etc
.more-dropdown {
  .btn {
    &.btn-secondary {
      background-color: transparent;
      padding: 0.375rem;
      box-shadow: none !important;
      svg {
        color: $color-white;
        fill: $color-white;
      }
      &:not(:disabled) {
        &:focus,
        &:active,
        &.focus,
        &.active {
          background-color: transparent;
        }
      }
    }
  }
}

.more-dropdown {
  .fill-black {
    .btn {
      &.btn-secondary {
        svg {
          fill: #000000 !important;
        }
      }
    }
  }
}

// header dropdown
.navbar {
  .dropdown-wrapper {
    .edit-profile-item {
      padding-top: 1rem;
      padding-bottom: 1rem;
      display: flex;
      align-items: flex-start;
    }
    .dropdown-menu {
      min-width: 265px;
    }
    .dropdown {
      .edit-profile-actions {
        margin-left: 1.25rem;
      }
    }
  }
}

.dropdown {
  .dropdown-inline-item {
    padding: 0.5rem 1.5rem;
  }
}

#partner-manager {
  .dropdown-toggle {
    width: 85%;
  }
  .dropdown-menu {
    width: 100%;
  }
}

.user-dropdown {
  .dropdown-toggle {
    justify-content: space-between;
    align-items: center;
    min-height: 2.5rem;
    width: 100%;
    font-size: 0.875rem;
    svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
    }
  }
  .user-badge {
    padding: 0;
    background-color: transparent;
    height: auto;
    margin-right: 1rem;
    font-size: 0.875rem;
    img {
      width: 30px;
      height: 30px;
      margin-right: 0.625rem;
    }
  }
  .no-assignee {
    padding-left: 0.5rem;
  }
  .dropdown-menu {
    width: 100%;
  }
  .dropdown-item,
  .dropdown-toggle {
    padding: 0.25rem;
    display: flex;
  }
  .dropdown-menu {
    .dropdown-item {
      &.active-selection {
        background-color: #d9f1fa;
      }
    }
  }
}
