.filters-wrapper {
  margin: 2rem 0 1.5rem 0;
  display: flex;
  flex-wrap: wrap;
  .badge {
    margin: 0 0.5rem 0.5rem 0;
    display: inline-flex;
    align-items: center;
    border: none;
    box-shadow: none;
    background-color: $color-cerulean-alt;
    border-radius: 7px;
    padding: 0.25rem 0.5rem;
    outline: none;
    font-weight: 700;
    svg {
      margin-left: 0.5rem;
      max-width: 12px;
      max-height: 16px;
      pointer-events: none;
    }
  }
}
