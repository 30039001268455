.expert-onboarding {
  margin-bottom: 1.5rem;
}

.quick-link {
  background: $color-white;
  padding: 2.5rem 2.5rem 0 2.5rem;
  display: flex;
  justify-content: stretch;
  align-items: center;
  color: $color-black;

  .profile-completing svg {
    width: 45px;
    height: 45px;
  }
}

.quick-link-account-mapping {
  background: $color-white;
  padding: 0rem 2.5rem 0 2.5rem;
  display: flex;
  justify-content: stretch;
  align-items: center;
  color: $color-black;
}

.need-attention {
  padding: 0 2.5rem 0 2.5rem;

  @media only screen and (min-width: 1200px) {
    width: 650px;
  }

  @media screen and (min-width: 991px) and (max-width: 1200px) {
    width: 520px;
  }

  @media screen and (max-width: 990px) {
    width: 480px;
  }

  @media screen and (max-width: 767px) {
    width: auto;
  }
}

.partner-task {
  padding: 1.5rem 2.5rem 1.5rem 0 !important;
}

.partner-onboarding {
  padding: 1.5rem 2.5rem 1.5rem 0 !important;
  display: flex;
  color: #000;
  text-align: center;
  justify-content: center;
  align-items: center;

  &:hover {
    text-decoration: none;
    color: #000;
  }
}

.onboarding-link {
  background: $color-white;
  padding: 2.5rem 2.5rem 2.5rem 0;
  display: flex;
  justify-content: stretch;
  align-items: center;
  color: $color-black;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    background: #f2fafd;
    color: $color-black;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $tab-inactive-border;
  }

  .onboarding-header {
    min-width: 180px;
    max-width: 180px;
    display: flex;
    justify-content: center;

    .icon-wrapper {
      align-self: center;

      svg {
        margin-top: -20px;
        margin-left: 10px;
      }
    }
  }

  .onboarding-icon {
    min-width: 180px;
    max-width: 180px;

    @media (max-width: "670px") {
      min-width: 70px;
      max-width: 180px;
    }

    display: flex;
    justify-content: center;

    .icon-wrapper {
      align-self: center;

      svg {
        margin-top: -20px;
        margin-left: 10px;
      }
    }
  }

  .step-wrapper {
    display: flex;
    align-items: stretch;
    margin: 0 auto;

    .step-count {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      margin: auto;
    }
  }

  .onboarding-url {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: auto 0 auto auto;
  }

  .onboarding-main {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .availability {
    text-decoration: underline solid;
    margin-bottom: -1.25rem;
    display: block;

    &.not-available {
      color: $color-notification-orange;

      &:before {
        background-color: $color-notification-orange;
      }
    }

    &.partially-available {
      color: $color-yellow;

      &:before {
        background-color: $color-yellow;
      }
    }

    &.available {
      color: $color-success;

      &:before {
        background-color: $color-success;
      }
    }

    &:before {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      content: " ";
      display: inline-flex;
      margin-right: 0.5rem;
    }
  }

  .completion-gauge {
    margin: 0 0 -10px auto;
    max-width: 150px;
    min-width: 150px;
    width: 150px;

    .feather {
      height: 44px;
      width: 44px;
      color: $color-cerulean;
    }
  }
}

#incoming-projects-tooltip {
  margin-bottom: 1.5rem;
}

.incoming-project {
  position: relative;

  .project-list {
    margin-top: 0;
  }

  .new-message,
  .new-alert-message,
  .card-body,
  .field-wrapper,
  .project-timeline {
    opacity: 0.5;
    cursor: pointer;
    position: relative;
    z-index: 1;

    &:hover {
      opacity: 1;
    }
  }

  .card-header {
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      bottom: -1px;
      width: 100%;
      content: " ";
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 0;
    }
  }
}

.how-it-works {
  padding: 1.5rem 0 0;
  margin: 0 0 1.5rem;
  background-color: $color-white;
  border-top: 1px solid #e2e2e8;

  .how-it-works-item {
    display: flex;
    justify-content: stretch;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 80px;
    }

    &:not(.item-right) {
      padding-right: 70px;
    }

    &.item-right {
      flex-direction: row-reverse;
      padding-left: 70px;

      .img-wrapper {
        margin-left: 60px;
        margin-right: 0;
      }
    }

    .img-wrapper {
      margin-right: 60px;

      img {
        max-width: 400px;
      }
    }

    .img-placeholder {
      width: 400px;
      height: 300px;
      background: $tab-inactive-border;
    }

    .content-wrapper {
      h5 {
        font-weight: 700;
        margin-bottom: 1.25rem;
        position: relative;
      }
    }

    .inline-number {
      font-size: 0.75rem;
      color: $color-orange;
      line-height: 1;
      display: block;
      text-transform: uppercase;
    }

    .title,
    .inline-number {
      position: relative;
      z-index: 2;
    }

    .number {
      width: 61px;
      height: 126px;
      position: absolute;
      top: -63px;
      left: -30px;
      font-size: 100px;
      color: $color-desert;
      font-weight: 700;
      display: block;
      opacity: 0.3;
      z-index: 0;
    }
  }
}

.set-expert-visibility {
  background-color: $color-white;
  display: flex;
  justify-content: space-between;
  margin: 0 0 1.5rem;
  position: sticky;
  top: 0;
  z-index: 11;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

  .switch-wrapper {
    p {
      margin-bottom: 0 !important;
      font-size: 1rem;
      min-width: 24px;
    }

    h6 {
      color: $text-inline-grayed;
      font-weight: 300;
    }
  }

  .btn {
    font-size: 0.875rem;
  }
}

@media (min-width: 992px) {
  .expert-details-sidebar {
    max-width: 240px;
    min-width: 240px;
    width: 100%;
    position: relative;

    .sidebar-content {
      .upload-image {
        margin: -1.25rem -1.25rem 1.25rem !important;
      }
    }

    .upload-image {
      .avatar-placeholder,
      .avatar-picture {
        padding-top: 73%;
      }

      .profile-picture-preview {
        width: auto;
        height: auto;
        max-width: 100%;
        border-radius: 0 !important;
      }
    }

    .form-wrapper {
      .field-wrapper {
        &:not(:last-child) {
          margin-bottom: 0.75rem;
        }

        .form-group {
          label {
            color: $text-inline-grayed;
          }
        }
      }
    }

    .form-group {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 992px) {
  .expert-details-sidebar {
    max-width: 240px;
    min-width: 240px;
    width: 100%;
    position: relative;

    .sidebar-content {
      .upload-image {
        margin: -1.25rem -1.25rem 1.25rem !important;
      }
    }

    .sidebar-content-box {
      width: 330px;
    }

    .upload-image {
      .avatar-placeholder,
      .avatar-picture {
        padding-top: 73%;
      }

      .profile-picture-preview {
        width: auto;
        height: auto;
        max-width: 100%;
        border-radius: 0 !important;
      }
    }

    .form-wrapper {
      .field-wrapper {
        &:not(:last-child) {
          margin-bottom: 0.75rem;
        }

        .form-group {
          label {
            color: $text-inline-grayed;
          }
        }
      }
    }

    .form-group {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 992px) {
  .expert-details-sidebar {
    min-width: 240px;
    width: 100%;
    position: relative;

    .sidebar-content {
      .upload-image {
        margin: -1.25rem -1.25rem 1.25rem !important;
      }
    }

    .upload-image {
      .avatar-placeholder,
      .avatar-picture {
        padding-top: 73%;
      }

      .profile-picture-preview {
        width: auto;
        height: auto;
        max-width: 100%;
        border-radius: 0 !important;
      }
    }

    .form-wrapper {
      .field-wrapper {
        &:not(:last-child) {
          margin-bottom: 0.75rem;
        }

        .form-group {
          label {
            color: $text-inline-grayed;
          }
        }
      }
    }

    .form-group {
      margin-bottom: 0;
    }
  }
}

.edit-user-profile {
  .datepicker {
    font-size: 0.875rem;
  }

  .marketplace-section-content {
    z-index: 0;
  }

  .edit-btn {
    position: absolute;
    z-index: 2;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    padding: 2px;
    color: $color-black;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  textarea {
    min-height: 200px;
  }

  .form-wrapper {
    .field-wrapper {
      position: relative;

      .form-group {
        margin-bottom: 0.125rem;

        &:last-of-type {
          margin-right: 0;
        }
      }

      .invalid-feedback {
        top: 100%;
        margin-top: -0.275rem;
        font-size: 0.675rem;
      }

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}

.edit-user-profile {
  .error-messages {
    background-color: $color-notification-orange;
    color: $color-white;
    padding: 1.25rem;
    border: 1px solid $tab-inactive-border;
    font-size: 1rem;
    position: sticky;
    z-index: 11;
    top: 72px;
    margin-bottom: 1.5rem;
  }
}

.alert-banner {
  border: 1px solid $tab-inactive-border;
  display: grid;
  grid-template-columns: repeat(4, auto);
  background-color: #e96d61;

  @media (max-width: "630px") {
    border: 1px solid $tab-inactive-border;
    display: block;
    grid-template-columns: repeat(4, auto);
  }

  &.options-list {
    display: flex;
    flex-wrap: wrap;
    border: none;

    ul {
      margin: auto;
    }

    label.alert-option {
      padding: 0;
      border: none;
    }

    .card-logo {
      margin-top: 1rem;

      svg {
        margin: 0;
      }
    }

    label {
      .alert-option {
        &:before {
          content: " ";
          width: 24px;
          height: 24px;
          min-width: 24px;
          min-height: 24px;
          border-radius: 100%;
          border: 2px solid $color-input-gray-dark;
          margin-right: 1.5rem;
        }
      }

      input[type="radio"]:checked {
        + .alert-option {
          &:before {
            border-color: $color-cerulean;
            background-color: $color-cerulean;
            box-shadow: inset 0 0 0 4px rgba(255, 255, 255, 1);
          }
        }
      }
    }
  }

  .alert-option {
    padding: 1rem 2rem 0.5rem;
    background-color: #e96d61;
    color: white;

    @media (max-width: "630px") {
      padding: 1rem 2rem 0.5rem;
      background-color: #e96d61;
      color: white;
    }
  }
}

.alert-icon {
  padding: 1.3rem 0rem 0.5rem;
  background-color: #e96d61;
  max-width: 45px !important;
  color: white;
}

@media (max-width: "630px") {
  .alert-icon {
    display: none;
  }
}

.account-banner {
  border: 1px solid $tab-inactive-border;
  display: grid;
  grid-template-columns: repeat(3, auto);

  @media (max-width: "630px") {
    border: 1px solid $tab-inactive-border;
    display: block;
    grid-template-columns: repeat(3, auto);
  }

  &.options-list {
    display: flex;
    flex-wrap: wrap;
    border: none;

    ul {
      margin: auto;
    }

    label.payment-option {
      padding: 0;
      border: none;
    }

    .card-logo {
      margin-top: 1rem;

      svg {
        margin: 0;
      }
    }

    label {
      .payment-option {
        &:before {
          content: " ";
          width: 24px;
          height: 24px;
          min-width: 24px;
          min-height: 24px;
          border-radius: 100%;
          border: 2px solid $color-input-gray-dark;
          margin-right: 1.5rem;
        }
      }

      input[type="radio"]:checked {
        + .payment-option {
          &:before {
            border-color: $color-cerulean;
            background-color: $color-cerulean;
            box-shadow: inset 0 0 0 4px rgba(255, 255, 255, 1);
          }
        }
      }
    }
  }

  .payment-option {
    padding: 1rem 1rem 0.5rem;
  }
}

.team-banner {
  display: grid;
  grid-template-columns: repeat(5, auto);
  border-bottom: none;

  @media (max-width: "630px") {
    display: block;
    grid-template-columns: repeat(5, auto);
    border-bottom: 1px solid;
  }

  .team-option {
    padding: 1rem 1rem 0.5rem;
    padding-top: none;
    overflow: hidden;

    @media (max-width: 630px) {
      padding: 0rem 1rem 0.5rem;
      padding-top: 1rem;
    }
  }
}

.team-emal {
  width: 400px;

  @media (max-width: 620px) {
    width: 300px;
  }
}

.payment-options {
  border: 1px solid $tab-inactive-border;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: "630px") {
    border: 1px solid $tab-inactive-border;
    display: block;
    grid-template-columns: repeat(4, 1fr);
  }

  &.options-list {
    display: flex;
    flex-wrap: wrap;
    border: none;

    ul {
      margin: auto;
    }

    label.payment-option {
      padding: 0;
      border: none;
    }

    .payment-option {
      padding: 1.25rem;
      width: 100%;
      min-height: 139px;
      display: flex;
      align-items: center;
      justify-content: stretch;
      text-align: left;
      border: 1px solid $tab-inactive-border;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      .btn-wrapper {
        margin: auto 0 auto auto;

        .btn {
          display: flex;
          align-items: center;

          svg {
            margin: 0;
            width: 24px;
            height: auto;
          }
        }
      }
    }

    .card-logo {
      margin-top: 1rem;

      svg {
        margin: 0;
      }
    }

    label {
      .payment-option {
        &:before {
          content: " ";
          width: 24px;
          height: 24px;
          min-width: 24px;
          min-height: 24px;
          border-radius: 100%;
          border: 2px solid $color-input-gray-dark;
          margin-right: 1.5rem;
        }
      }

      input[type="radio"]:checked {
        + .payment-option {
          &:before {
            border-color: $color-cerulean;
            background-color: $color-cerulean;
            box-shadow: inset 0 0 0 4px rgba(255, 255, 255, 1);
          }
        }
      }
    }
  }

  .payment-option {
    padding: 3rem 1rem 2.5rem;
    text-align: center;

    button {
      &:hover {
        svg {
          stroke: #00668e;
        }
      }
    }

    svg {
      margin: 0 auto 0.25rem;
      max-height: 20px;
      width: auto;
      stroke: $color-dark;
    }

    border-right: 1px solid $tab-inactive-border;
  }
}

.billing-details {
  justify-content: stretch;
  align-items: flex-start;
}

.billing-details,
.payment-methods-wrapper {
  .btn {
    font-size: 0.875rem;
    padding-top: 0;
    margin-left: 1.25rem;

    svg {
      stroke: $color-dark;
    }

    &:hover {
      svg {
        stroke: currentColor;
      }
    }
  }
}

.edit-user-profile,
.accounting-settings {
  .Toastify {
    position: sticky;
    top: 91px;
    z-index: 2;
    margin: 0 0 1.5rem;

    &__toast-container {
      &--relative {
        position: relative;
        padding: 0;
        width: 100%;
      }
    }

    &__toast-body {
      display: flex;
      align-items: center;
      justify-content: stretch;

      svg {
        margin-right: 0.75rem;
      }
    }

    &__toast {
      padding: 1.25rem;

      &--success {
        background-color: $color-success;
      }

      &--error {
        background-color: $color-notification-orange;
      }

      &--warning {
        background-color: #fec12f;
      }
    }

    &__close-button {
      font-size: 0;
      font-weight: 300;
      position: relative;

      &:before,
      &:after {
        position: absolute;
        left: 0;
        content: " ";
        height: 20px;
        width: 2px;
        background-color: $color-white;
        top: -8px;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

.accounting-settings {
  .Toastify {
    top: 0;
  }
}

.lets-talk {
  .btn {
    padding: 0.75rem;

    svg {
      margin-right: 0.5rem;
    }
  }
}

.card-2x2-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  margin-bottom: 1.5rem;
}

.elite-resource-wrapper {
  background-color: #5b44f8;
  width: 33px;
  height: 33px;
  padding: 3px;

  svg {
    width: 27px;
    height: 25px;
    fill: $color-white;
  }
}

.open-deal-item {
  border-bottom: 1px solid $tab-inactive-border;
}

.deal-status-border {
  @media only screen and (min-width: 1200px) {
    width: 50px;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 35px;
  }

  @media screen and (min-width: 991px) and (max-width: 1200px) {
    width: 40px;
  }

  @media screen and (min-width: 768px) and (max-width: 990px) {
    width: 40px;
  }

  @media screen and (max-width: 320px) {
    width: 40px;
  }
}

.profile-percentage-pending {
  border: 2px solid $color-grey-dark;
}

.profile-percentage-completed {
  background: $color-success;
  color: $color-white;
}

.crossbeam-icon svg {
  width: 30px;
  height: 30px;
  color: lightskyblue;
}

.crossbeam-logo svg {
  width: 120px;
  height: 35px;
}
