// dashboard site maincontent styles
.site-maincontent {
  padding-top: 1.5rem;
  @include mobile-xs {
    padding-top: 1rem;
  }
}

// admin action nav
.admin-action-navigation {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $tab-inactive-border;
  a {
    font-size: 0.875rem;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  + .page-title {
    margin-top: 2rem !important;
  }
  .steps-wrapper {
    .step {
      .step-status-text {
        font-size: 0.875rem;
      }
    }
  }
  .project-name {
    max-width: 320px;
  }
  @include mobile-xs {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
}

@include mobile-xs {
  .with-hamburger-menu {
    display: flex;
    justify-content: stretch;
    .admin-action-navigation {
      flex-grow: 1;
    }
  }
}

.activties-page-size {
  max-width: 90px !important;
}

// global page heading related styles
.page-heading {
  margin-bottom: 1.5rem;
  .btn-wrapper {
    .btn {
      white-space: nowrap;
      font-size: 1rem;
      padding-left: 0.5rem;
      height: 100%;
      padding-right: 1rem;
      svg {
        margin-bottom: 0;
      }
      span {
        font-size: 0.875rem;
      }
    }
  }
  .page-search {
    max-width: 100%;
    width: auto;
    &.input-group > {
      .input-group-prepend {
        &:not(:first-child) > {
          .input-group-text {
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
          }
        }
      }
    }
  }
  &.cover {
    background: #fff;
    padding: 1.25rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    .form-control {
      &:focus {
        background-color: #e7e7ea;
        border: 1px solid #e7e7ea;
      }
    }
  }
  @include tablet {
    .page-search {
      margin-bottom: 1rem;
    }
    .page-search,
    .dropdown-wrapper {
      p {
        min-width: 55px;
      }
    }
  }
  @include mobile {
    .dropdown-wrapper {
      &.select-dropdown {
        min-width: 100%;
        justify-content: start !important;
        .btn-group {
          width: 100%;
          .dropdown-toggle {
            position: relative;
            svg {
              position: absolute;
              right: 5px;
              top: auto;
            }
          }
        }
      }
    }
  }
  @include mobile-xs {
    .page-search,
    .dropdown-wrapper {
      p {
        display: none !important;
      }
    }
    .page-search {
      width: 100%;
      .input-group-prepend {
        display: none;
      }
      &.input-group > {
        .form-control {
          border-top-left-radius: 7px;
          border-bottom-left-radius: 7px;
        }
      }
    }
  }
}

.hamburger-btn {
  position: relative;
  z-index: 10;
  display: none !important;
  margin-right: 3px;
  @include mobile-xs {
    display: block !important;
  }
}

// global page search component
.page-search {
  max-width: 295px;
  .input-group-text {
    background-color: $color-input-gray;
    svg {
      fill: $color-input-gray-dark;
    }
  }
  input {
    background-color: $color-input-gray;
    outline: none !important;
    &::placeholder {
      color: $color-input-dark;
    }
  }
  .input-group-text,
  .form-control {
    border-radius: 7px;
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
}

// main-content styles for public pages
.content {
  margin-top: 2rem;
  p {
    &:not(:last-child) {
      margin-bottom: 1.75rem;
    }
    img {
      float: left;
      max-width: 270px;
      margin: 0 1.75rem 1rem 0;
    }
    + ul {
      margin-top: -0.75rem;
      + ul {
        margin-top: auto;
      }
    }
  }
  ul {
    padding-left: 0;
    list-style-position: inside;
  }
}

// cart page related
.cart-item {
  margin-bottom: 1.5rem;
  padding-bottom: 1.875rem;
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
  p {
    &.h6 {
      font-size: 1rem;
    }
    b + form {
      margin-top: 0.5rem;
    }
  }
  form {
    .btn-secondary {
      padding: 0.5rem 0.675rem;
    }
  }
}

.sidebar-content {
  .cart-item {
    padding-bottom: 0.75rem;
    margin-bottom: 0.875rem;
    > p,
    > ul {
      &:last-child {
        margin-bottom: 0;
      }
    }
    &:last-of-type {
      border: none;
    }
  }
}

// order-item
.order-item {
  .media-left {
    max-width: 270px;
    margin-right: 1.25rem;
  }
}

// custom checkbox styles , let's have them here for the time being, might move it to it's own stylesheet.
.custom-checkbox {
  &.form-group {
    margin-bottom: 0;
  }
  label {
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
    margin-bottom: 0;
  }
  .input-wrapper {
    input {
      display: none;
    }
  }
}

// content with bg and shadow
.marketplace-section-content {
  background-color: $color-white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  padding: 2.5rem 1.5rem;
  width: 100%;
  margin-bottom: 1.5rem;
  z-index: 1;
  .content {
    margin-top: 0;
  }
  .cart-item {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.recommendations {
  margin-top: 1rem;
  p {
    &:last-child {
      margin-bottom: 1.75rem;
    }
  }
}

.recommendations-editing,
.certifications-editing,
.employment-editing,
.education-editing {
  .recommendation,
  .certification-editing,
  .employment,
  .education {
    padding: 16px;
    border: 1px dashed $color-input-gray-dark;
    border-radius: 3px;
    margin: 0 0 1rem;
    .field-wrapper {
      &:last-child {
        .form-group {
          //margin-bottom: 0;
        }
      }
    }
  }
}

.certifications {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  .certification {
    display: flex;
    padding: 1.5rem;
    border: 1px solid $tab-inactive-border;
    &:not(:last-child) {
      margin: 0 0 1.5rem;
    }
    .certification-image {
      max-width: 85px;
      width: 100%;
      margin-right: 1.5rem;
    }
    .certification-content {
      margin: auto 0;
      p {
        font-weight: 600;
        margin: 0 0 0.25rem;
        font-size: 1rem;
      }
      span {
        display: block;
        font-size: 0.875rem;
      }
    }
  }
  &.certifications-editing {
    display: flex;
    flex-direction: column;
  }
  @include mobile {
    .certification {
      flex-direction: column;
      align-items: center;
      .certification-image {
        margin: 0 0 1.5rem;
      }
    }
  }
}

.employments {
  .employment {
    &:not(:last-child) {
      margin-bottom: 2rem;
      &:after {
        content: " ";
        width: 140px;
        height: 1px;
        background-color: $color-black;
        margin: 2rem 0 0;
        display: block;
      }
    }
  }
  &.employment-editing {
    .employment {
      &:after {
        content: none;
      }
    }
  }
}

.recommendations,
.certifications,
.employments,
.educations {
  margin-top: 1rem;
  .content-title {
    p {
      font-size: 1rem;
      margin-bottom: 1.125rem;
    }
  }
  p {
    font-size: 0.875rem;
  }
}

// become an advisor large cta-s
.advisor-link {
  padding-top: 53.45%;
  position: relative;
  display: block;
  color: $color-white;
  overflow: hidden;
  box-shadow: inset 20px 20px 2px rgba(255, 255, 255, 1);
  .overlay,
  .background-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .overlay {
    z-index: 3;
    padding: 20px;
    .h1 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      margin: 0;
      border: 2px solid transparent;
      letter-spacing: 1px;
      transition: all 280ms ease;
      flex-direction: column;

      > p {
        font-size: 1rem;
      }
    }
  }
  .background-holder {
    z-index: 2;
    background-size: cover;
    transition: all 280ms ease;
  }
  &:hover {
    color: $color-white;
    .background-holder {
      transform: scale(1.1);
    }
    .overlay {
      .h1 {
        border-color: $color-white;
      }
    }
  }
}

.main-wrapper {
  background-color: $theme-color-body-background;
}

@include tablet {
  .main-wrapper {
    &.pr-md-0 {
      padding-right: 15px !important;
    }
  }
}

@include mobile {
  .main-wrapper {
    padding-left: 0 !important;
  }
}
