.site-footer {
  // padding: 1rem 0 2rem;
  background-color: #f8f6ed;
  #footer-logo {
    max-width: 136px;
    margin: 0 0 1rem;
    display: block;
    svg {
      width: 100%;
      height: auto;
    }
  }
  // .container {
  //   padding: 0 56px;
  // }
}
.footer-widgets {
  flex-wrap: wrap;
  .footer-widget {
    max-width: 300px;
    min-width: 155px;
    margin-top: 1rem;
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}

.footer-widget {
  p {
    line-height: 1.25rem;
  }
  dl {
    dt {
      font-size: 0.75rem;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0 0 1rem;
    }
    dd {
      font-size: 0.875rem;
      line-height: 1.25rem;
      &:not(:last-child) {
        margin: 0 0 0.5rem;
      }
    }
  }
}

.newsletter-wrapper {
  max-width: 240px;
  input {
    background: #eceae3;
    border-color: transparent;
    font-size: 0.875rem;
    height: 40px;
  }
  .btn {
    padding: 8px;
    width: 40px;
    height: 40px;
  }
}

.end-footer {
  padding: 1.25rem 0;
  .sidebar-content-box {
    box-shadow: none;
    padding: 0;
    background-color: transparent;
    a {
      display: block;
    }
    svg {
      display: block;
      fill: $color-white;
      color: $color-white;
      height: 20px;
      width: 20px;
    }
  }
  .container {
    padding: 0 56px;
  }
  .endfooter-wrapper {
    align-items: center;
    .social-icons {
      margin-left: auto;
    }
  }
  p {
    font-size: 0.75rem;
    margin: 0 1rem 0 0;
  }
}
.salesforce-footer {
  padding: 114px 0;
  background: $color-dark;
  .container {
    display: flex;
    justify-content: stretch;
    align-items: center;
  }
  .text-wrapper {
    p {
      font-size: 1.125rem;
    }
  }
  .logo-wrapper {
    padding: 0 3rem 0 0;
    margin: 0 3rem 0 0;
    border-right: 1px solid $color-white;
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
    }
    span {
      margin-left: 1.25rem;
    }
    img {
      max-height: 43px;
    }
    .h5 {
      margin: 0 0 0 1.25rem;
    }
  }
  .link-wrapper {
    margin: 4rem auto 0;
    ul {
      list-style-type: none;
      display: flex;
      padding-left: 0;
      margin: 0;
      text-align: center;
      color: $color-white;
      justify-content: space-between;
      li {
        margin: 0 1rem;
        max-width: 300px;
        svg {
          margin: 0 auto 1.25rem;
        }
        a {
          color: $color-cerulean;
          font-size: 19px;
          text-decoration: underline;
          margin-bottom: 1rem;
          display: block;
        }
        p {
          margin-bottom: 0;
          font-weight: 500;
          font-size: 1rem;
        }
      }
    }
  }
  @include small-desktop {
    .container {
      flex-wrap: wrap;
    }
    .logo-wrapper {
      margin: auto auto 2rem;
    }
    .link-wrapper {
      margin: auto;
      ul {
        flex-wrap: wrap;
        justify-content: center;
        li {
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.pre-footer {
  background-color: #fbf8f3;
}

.marketplace-landing + .site-footer {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
  .footer-widgets {
    justify-content: space-between !important;
    .footer-widget {
      margin: 0 2rem 0 0 !important;
    }
  }
}
