body {
  font-family: $theme-font !important;
  font-size: $button-font-size;
}

.loggedin-layout {
  background-color: $theme-color-body-background;
}

.app-body {
  min-height: 94vh;
}

@media (max-width: 991px) {
  .app-body {
    margin-top: 0;
  }
}

.btn-primary {
  background: $theme-color;
  border: 1px solid $theme-color;
  color: $color-white;

  &:hover {
    background: $theme-color-alt;
    border: 1px solid $theme-color-alt;
    color: $color-white;
  }
}

button,
a {
  &.btn {
    -moz-border-radius: 0.4375rem;
    -webkit-border-radius: 0.4375rem;
    border-radius: 0.4375rem;
  }
}

.btn {
  &.outline-bold {
    border-width: 2px;

    &:hover {
      background: 1px solid $theme-color-alt;
      text-decoration: none;
    }
  }

  &.btn-secondary {
    background-color: $primary-button-color;
    border: 0;
    color: $color-white;

    &:hover {
      background-color: $secondary-button-color-hover;
      border: 0;
    }

    &:focus {
      background-color: $primary-button-color;
      border: 0;
      color: $color-white;
    }

    &.focus {
      background-color: $primary-button-color;
      border: 0;
      color: $color-white;
    }

    &.disabled {
      background-color: $primary-button-color;
      border: 0;
      color: $color-white;
    }

    &:disabled {
      background-color: $primary-button-color;
      border: 0;
      color: $color-white;
    }
  }
}

.btn .plain-btn {
  border-color: $color-white;
  font-size: 14px;
  padding: 5px 15px !important;
}

.btn.plain-btn:hover {
  background-color: $color-white;
  color: $color-black !important;
}

.astric {
  color: $color-red;
}

.header--content {
  padding-bottom: 200px;
  padding-top: 100px;
}

.availability-update-form {
  label {
    font-size: $label-font-size;
  }
}

@media screen and (min-width: 768px) {
  .expert-availability-form-content {
    margin: auto;
    width: 55%;
  }
}

.radio-btn-label,
.checkbox-btn-label {
  font-size: $button-font-size;
  font-weight: 500;
}

.checkbox {
  .checkbox-inline {
    .form-check-input {
      margin-top: 6px;
    }
  }
}

.TextAlignment {
  margin-left: -3%;
}

.gradient-blue {
  background-image: linear-gradient(
    -180deg,
    $color-cerulean 0%,
    $color-science-blue 100%
  );
}

.find-an-expert-left-side-section-background-image {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 -15px;
  padding: 0 15px;
  position: relative;
}

.main-container {
  background: $main-container-background;
  float: left;
  margin-top: -50px;
  width: 100%;

  .availability-update-section {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 767px) {
      margin-bottom: 20px;
    }
    height: 420px;
    position: relative;

    @media screen and (min-width: 320px) and (max-width: 767px) {
      height: 280px;
    }
  }

  .availability-update-header-section {
    @media screen and (min-width: 767px) {
      -webkit-transform: translate(-50%, -50%);
      left: 50%;
      margin-top: 17%;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    @media screen and (min-width: 320px) and (max-width: 400px) {
      h1 {
        font-size: $header-font-size;
      }
    }

    @media only screen and (min-width: 1500px) {
      margin-top: 13%;
    }
    @media only screen and (min-width: 1305px) and (max-width: 1500px) {
      margin-top: 15%;
    }
    @media screen and (min-width: 320px) and (max-width: 767px) {
      margin-top: 10%;
    }
    @media screen and (min-width: 991px) and (max-width: 1200px) {
      margin-top: 20%;
    }
    @media screen and (min-width: 768px) and (max-width: 990px) {
      margin-top: 25%;
    }

    .header-section {
      font-size: $header-section-font-size;

      @media screen and (min-width: 320px) and (max-width: 500px) {
        font-size: $span-font-size;
      }
      @media screen and (min-width: 500px) and (max-width: 767px) {
        font-size: $button-font-size;
      }
      @media screen and (min-width: 768px) and (max-width: 990px) {
        font-size: $sub-menu-font-size;
      }
    }
  }
}

.footer {
  background-color: $footer-color;
  bottom: 0;
  color: $color-white;
  font-size: $footer-font-size;
  left: 0;
  text-align: center;
  width: 100%;
}

.google-login {
  background: $button-google-login !important;
  border-radius: 8px !important;
  color: $color-white !important;
  font-size: $paragraph-font-size !important;
  justify-content: center;
  min-height: 46px;
  position: relative;
  width: 100%;

  > div {
    background: $button-google-login !important;
    position: relative;

    svg {
      g {
        path {
          &:not(:last-child) {
            fill: $color-white !important;
          }
        }
      }
    }
  }
}

.linkedin-login {
  border-radius: 8px;
  padding: 0;
}

.resigter-with-my-email {
  background: none;
  border: 0;
  color: $color-blue !important;
  display: contents;
  font-family: Muli;
  font-size: 14px;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  width: 206px;
}

.resigter-with-my-email:hover {
  color: $color-black !important;
}

.office365-login {
  min-height: 46px;
  position: relative;
  width: 100%;

  &:hover {
    opacity: 0.9;
  }

  svg {
    path {
      &:not(:last-child) {
        fill: $color-white !important;
      }
    }
  }

  > div {
    background: transparent;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1;
  }

  > span {
    background-color: $color-light-orange;
    border-radius: 4px;
    bottom: 0;
    color: $color-white;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    left: 0;
    line-height: 46px;
    padding-left: 50px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 0;

    > span {
      background-color: $color-light-greyed;
      bottom: 6px;
      display: inline-block;
      left: 50px;
      position: absolute;
      top: 6px;
      width: 2px;
    }

    img {
      left: 11px;
      position: absolute;
      top: 7px;
      width: 28px;
    }
  }
}

.separator {
  border-bottom: 1px solid $color-white-light-gray;
  margin: 30px 0;
  position: relative;
  width: 100%;

  &::before {
    background-color: $color-white;
    content: "OR";
    left: 50%;
    margin-left: -20px;
    padding: 0 10px;
    position: absolute;
    top: -10.5px;
  }
}

.vertical-center {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
}

.vertical-top {
  vertical-align: top !important;
}

.login-container {
  color: $color-dark;

  .accepted-terms {
    span {
      color: $color-dark;
    }
  }
}

.btn-login {
  background: $button-btn-login !important;
  color: $color-white !important;
  font-size: $paragraph-font-size !important;
  font-weight: bold !important;
  min-height: 46px;

  &:hover {
    background: $button-btn-login-hover !important;
  }
}

.dropdown-item {
  cursor: pointer;
}

.dropdown-menu {
  margin-top: 7px !important;
  padding: 0 !important;
  width: 100%;
}

.dropdown-toggle {
  &:hover {
    text-decoration: none;
  }

  &::after {
    color: $color-white;
  }
}

.nav-item {
  list-style: none;
}

.logout-sidebar-button {
  bottom: 118px;
  display: inline-block !important;
  position: absolute;
  @media screen and (min-width: 768px) and (max-width: 991px) {
    bottom: 128px;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    bottom: 154px;
  }
  width: 100%;
}

// Page Not Found Starts
.page-not-found {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.page-not-found-404 {
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 3.5rem;
  }
}
// Page Not Found Ends

// Error message starts
.error-message {
  color: $error-message-color;
  font-size: $message-font-size;
  margin-bottom: 5px;
  margin-top: -20px;
}
// Error message Ends

// removed .components class, it's unnecessary

.bg-color-black {
  background-color: $bg-background-color-black;
}

.right-side-content {
  margin-bottom: 50px;
}

.assign-partner-checkbox {
  float: left;
  padding-bottom: 15px;

  span {
    color: $color-light-black;
    float: left;
    margin-top: -3px;
    padding-left: 20px;
  }

  input {
    &[type="checkbox"] {
      cursor: pointer;
      float: left;
      position: absolute;

      &::before {
        background-color: $color-white;
        border: 2px solid $color-light-black;
        border-radius: 3px;
        content: "";
        display: block;
        height: 16px;
        left: 0;
        position: absolute;
        top: 0;
        width: 16px;
      }

      &:checked {
        &::after {
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          border: solid $color-black;
          border-width: 0 2px 2px 0;
          content: "";
          display: block;
          height: 10px;
          left: 6px;
          position: absolute;
          top: 2px;
          transform: rotate(45deg);
          width: 5px;
        }
      }
    }
  }

  .invalid-feedback {
    display: block;
    margin-top: 20px;
  }
}

.accepted-terms {
  float: left;
  padding-bottom: 15px;

  span {
    color: $color-light-black;
    float: left;
    font-size: $span-font-size;
    padding-left: 25px;
  }

  input {
    &[type="checkbox"] {
      cursor: pointer !important;
      float: left !important;
      position: absolute !important;
      -webkit-cursor: pointer !important;
      -webkit-float: left !important;
      -webkit-position: absolute !important;

      &::before {
        background-color: $color-white;
        border: 2px solid $color-light-black;
        border-radius: 3px;
        content: "";
        display: block;
        height: 16px;
        left: 0;
        position: absolute;
        top: 0;
        width: 16px;
      }

      &:checked {
        &::after {
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          border: solid $color-black;
          border-width: 0 2px 2px 0;
          content: "";
          display: block;
          height: 10px;
          left: 6px;
          position: absolute;
          top: 2px;
          transform: rotate(45deg);
          width: 5px;
        }
      }
    }
  }

  .invalid-feedback {
    display: block;
    margin-top: 20px;
  }
}

.select__multi-value {
  background-color: $color-white !important;
}

.profile-picture-preview {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: 110px;
  object-fit: cover;
  width: 110px;
}

.term-and-condition {
  float: left;
  font-size: $span-font-size;
  padding-left: 5px;
  text-decoration: none;
}

.btn.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: $primary-button-color;
  border: 0;
  color: $color-white;
}

.btn.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: $color-white;
}

.fa {
  &.fa-plus {
    font-size: $small-font-size;
    padding-left: 0;
    padding-right: 6px;
  }
}

.table-filter {
  a {
    color: $theme-color-alt;

    &:hover {
      color: $color-black;
      text-decoration: none;
    }
  }

  .active {
    border-bottom: 2px solid $color-primary-alt;
    color: $color-black;

    a {
      color: $color-black;
    }
  }
}

@media (max-width: 765px) {
  .count-card {
    margin-top: 10px;
  }
}

// Update Collection Page
.collection-playbook {
  .playbook-field {
    .margin-top-30 {
      margin-top: 30px;

      @media screen and (min-width: 320px) and (max-width: 767px) {
        margin-top: 0;
      }
    }
  }
}

.project-list-card {
  .small {
    font-size: 12px;
    opacity: 0.5;
  }

  .status-header {
    font-size: 12px;
    font-weight: bold;

    .notification {
      background-color: $color-red;
    }
  }
}

.approve-btn-hover {
  &:hover {
    background-color: $color-green !important;
    color: $color-white !important;
  }
}
.edit-icon-hover:hover {
  background-color: $color-black !important;
}
.reject-btn-hover {
  &:hover {
    background-color: $color-red !important;
    color: $color-white !important;
  }
}

.landing-page-status-active {
  background-color: $color-active;
}

.landing-page-status-draft {
  background-color: $color-draft;
}

.landing-create-popup {
  .modal-content {
    border-radius: 15px;
  }
}

.landing-group-dropdown {
  .more-dropdown {
    .btn {
      &.btn-secondary {
        svg {
          fill: $color-light-grey2 !important;
        }
      }
    }
  }

  .show {
    width: auto;
  }
}

//Redux table action menu
.redux-table {
  .table-responsive {
    overflow-x: auto;
  }
}

.redux-table-responsive {
  .table-responsive {
    overflow-x: scroll !important;
  }
}

.landing-page-status-dropdown {
  background-color: $color-white;
  border: 0;
  padding: 5px;
  @media screen and (min-width: 767px) {
    width: 150px;
  }
}

.select-style {
  background: $color-input-gray;
  border: $color-input-gray;
  outline: none;
  width: 187px;
}

.default-input {
  background: $color-light-white3 !important;
  border: 0 !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  height: 40px !important;
}

.banner-image-upload-link {
  color: $color-cerulean;
  cursor: pointer;
  text-decoration: underline;
}

.Toastify__toast-container--top-right {
  top: 2.8em !important;
  @media only screen and (min-width: 1200px) {
    right: calc(50vw - 35rem) !important;
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    right: calc(50vw - 29.4rem) !important;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    right: 2em !important;
  }
  @media screen and (min-width: 481px) and (max-width: 767px) {
    right: 2.1em !important;
  }
  @media screen and (min-width: 320px) and (max-width: 480px) {
    top: 2.8em !important;
  }
}

// Inline Edit in the landing page edit page starts
.inline-edit-section {
  margin-bottom: 2.75rem;

  .landing-page-name {
    font-size: 2.5rem;
    height: 55px;
    width: -webkit-fill-available;
  }

  .landing-page-edit {
    background-color: $color-light-white2;
    border: 1px solid $color-grey-dark2;
    margin-top: -1px;
    padding: 5px;
    position: absolute;
    right: 15px;
    z-index: 2;

    i {
      border: 1px solid $color-grey-dark2;
      cursor: pointer;
      height: 30px;
      line-height: 28px;
      text-align: center;
      width: 30px;
    }
  }

  .landing-page-vanity-url {
    width: -webkit-fill-available;
  }
}

// Experts and playbooks Cards Active Starts
.active-playbooks {
  background-color: $color-success;
  border: 3px;
  border-radius: 27px;
}

.inactive-playbooks {
  background-color: transparent;
  border: 3px;
  border-radius: 27px;
}
// Experts and playbooks Cards Active Ends

// Landing page blocks edit expert list
.playbook-list-section {
  margin-left: -30px !important;
  margin-right: -30px !important;
}

// Success message popup starts
.modal {
  padding: 0 !important;
  text-align: center;

  &::before {
    content: "";
    display: inline-block;
    height: 100%;
    margin-right: -4px;
    vertical-align: middle;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.success-modal-popup {
  .modal-content {
    border-radius: 0;
    max-width: 100% !important;
  }
}
// Success message popup ends

//Multiline Ellipsis
.multiline-ellipsis {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
}

.tabs-and-preview {
  .selected-experts {
    max-width: none !important;
  }
}

//selected Expert card remove
.remove-expert {
  color: $color-white;
  font-size: 50px;
  position: absolute;
  right: 20px;
  top: 0.75rem;

  &:hover {
    background-color: $color-red;
    border-radius: 27px;
  }
}

//selected photo  remove
.remove-photo {
  color: $color-black;
  font-size: 32px;
  position: relative;
  left: 70px;
  bottom: 111px;

  &:hover {
    background-color: $color-red;
    border-radius: 27px;
  }
}

.imagecarousel-photos {
  .remove-icon {
    visibility: hidden;

    &:hover {
      color: #fff;
      background-color: red;
      border-radius: 54px;
    }
  }

  &:hover {
    .remove-icon {
      visibility: visible;
    }
  }
}

.slick-prev:before,
.slick-next:before {
  left: 1px !important;
  color: black !important;
}

.source-more {
  color: $color-cerulean;

  .feather-trash-2 {
    &:hover {
      color: $button-trash-hover;
      text-decoration: underline solid;
    }
  }

  &:hover {
    color: $button-trash-hover;
    text-decoration: underline solid;
  }
}

.discussion-editor-edit {
  width: 100%;

  .editor {
    background: $color-background-gray;
    border: 1px solid $color-background-gray2;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: text;
    margin-bottom: 2em;
    padding: 16px;
  }

  .editor :global(.public-DraftEditor-content) {
    max-height: 500px;
    min-height: 40px;
  }

  .rich-text-editor {
    background: $color-background-gray;
    border: 1px solid $color-background-gray2;
    border-radius: 6px;
    box-sizing: border-box;
    cursor: text;
    margin-bottom: 2em;
    padding: 16px;
    padding-top: 31px;
    padding-bottom: 31px;
    z-index: 0;
    max-height: 544px;
    min-height: 12rem;
    position: relative;
  }

  .rich-text-editor :global(.public-DraftEditor-content) {
    max-height: 500px;
    min-height: 10rem;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: -1;
  }

  .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    height: 8rem;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    scrollbar-width: none;
    z-index: -1;
  }

  .RichEditor-hidePlaceholder
    .public-DraftEditorPlaceholder-root::-webkit-scrollbar {
    display: none;
  }

  .options {
    margin-bottom: 20px;
  }
}

.link-hover {
  &:hover {
    text-decoration: underline !important;
  }
}

// Ellipsis
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Supporting material preview
.supporting-material-preview {
  border: 2px solid $color-grey-dark;
  border-radius: 0 0 5px 5px;
  border-top: 0;
  margin-top: -4px;
  padding: 15px;
}

// Project deliverable material button section
.file-preview-button-section {
  .delete-preview {
    color: $color-cerulean;

    &:hover {
      color: $file-preview-delete-button-hover;
      text-decoration: underline;
    }
  }
}

.credit-card-error {
  color: $color-orange;
  font-size: 0.75rem;
  height: 1px;
}

.invalid-feedback {
  display: block !important;
  margin-top: 20px;
}

.remove-link {
  color: $color-light-red !important;
  cursor: pointer;
  font-family: Muli;
  font-size: 14px;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: 16px;
  width: 53px;
}

.button-link {
  background: none;
  border: 0;
  color: $primary-button-color;
  font-family: Muli;
  font-size: 14px;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 0;
  width: 38px;
}

.button-link:focus {
  outline: none;
}

/* Draft Js - Mention */
.mention-suggestions-entry-container {
  padding: 5px;
  width: 100%;
}

.mention-suggestions-entry-container:hover {
  cursor: pointer;
}

.mention-suggestions-entry-container-left,
.mention-suggestions-entry-container-right {
  display: table-cell;
  vertical-align: middle;
}

.mention-suggestions-entry-container-right {
  padding-left: 8px;
  width: 100%;
}

.mention-suggestions-entry {
  padding: 7px 10px 3px;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}

.mention-suggestions-entry:active {
  background-color: $color-light-white1;
}

.mention-suggestions-entry-focused {
  background-color: $color-light-white;
}

.mention-suggestions-entry-text,
.mention-suggestions-entry-title {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mention-suggestions-entry-title {
  color: $color-grey-dark1;
  font-size: 80%;
}

.mention-suggestions-entry-avatar {
  border-radius: 50%;
  display: block;
  height: 30px;
  width: 30px;
}

.mention-suggestions-entry-email {
  font-size: 14px;
  font-weight: normal;
  padding-left: 10px;
}
/* Draft Js - Mention END */

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.Toastify__toast--success {
  background-color: $color-alert-green !important;
}

.Toastify__toast-container--top-right {
  top: 44px !important;
}

.applied-coupon-code {
  align-items: center;
  background-color: $color-active !important;
  border-radius: 28px;
  box-sizing: border-box;
  color: $color-white;
  display: inline-flex;
  font-size: 18px;
  padding: 6px 10px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#declined-message-tooltip {
  svg {
    height: 20px;
    width: 16px;
  }
}

hr {
  border: 0;
  border-top: 1px solid $color-white-light-gray;
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
}

.partner-profile-location {
  p {
    font-weight: bold;

    svg {
      display: inline;
    }
  }
}

.elite-partner {
  p {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.5px;

    svg {
      display: inline;
    }
  }
}

.embedded-partners-directory {
  background: $color-white;
  min-height: 100vh;
}

.deal-status-line {
  @media only screen and (min-width: 1200px) {
    width: 80px;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 80px;
  }
  @media screen and (min-width: 991px) and (max-width: 1200px) {
    width: 70px;
  }
  @media screen and (min-width: 768px) and (max-width: 990px) {
    width: 60px;
  }
  @media screen and (max-width: 320px) {
    width: 60px;
  }
}

// Admin Setting sidebar
.admin-setting {
  @media only screen and (min-width: 770px) {
    margin-left: -30px !important;
  }

  .sidemenu {
    background-color: $color-white;

    .nav-pills {
      .nav-item {
        border-bottom: 1px solid $color-light-white4;
        cursor: pointer;

        .active {
          background-color: $color-white;
          color: $color-nav-active !important;
        }

        .nav-link {
          border-bottom: 0;
          border-left: 0;
          border-right: 0;
          color: $color-nav-link;
        }
      }
    }
  }
}

.dashboard-banner-image {
  @media only screen and (min-width: 1200px) {
    max-height: 225px;
  }
  @media screen and (min-width: 991px) and (max-width: 1200px) {
    max-height: 225px;
  }
  @media screen and (min-width: 768px) and (max-width: 990px) {
    max-height: 210px;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    max-height: 170px;
  }
  @media screen and (max-width: 320px) {
    max-height: 125px;
  }
}

.text-circle {
  background: $color-violet;
  border-radius: 50%;
  color: $color-white;
  font-size: 20px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;
}

.left-nav-bg {
  height: 99%;
  position: absolute;
  width: 50%;
  z-index: -1;
}

// stats and activity
.stats-and-activity {
  .content {
    h4 {
      padding-bottom: 20px;
    }
  }
}

@media screen and (min-width: 767px) {
  .validation {
    .invalid-feedback {
      position: absolute;
    }
  }
}
@media screen and (min-width: 767px) {
  .check {
    .invalid-feedback {
      position: absolute;
    }
  }
}

// activity line
.single-activity {
  &:not(:last-child) {
    .activity-line {
      border-left: 2px solid $color-light-grey3;
      height: 90px;
      left: 4.5%;
      margin: 32px 0 0;
      position: absolute;
    }
  }
}

.alert-salesforce {
  color: $color-alert-green;
}

.alert-referral-danger {
  color: $error-message-color;
}

.opportunity-list {
  background-color: $color-background-gray;
}

.account-in-salesforce {
  .popover {
    max-width: 300px !important;
    width: 300px !important;
  }

  &-inner {
    max-width: 300px !important;
    width: 300px !important;
  }
}

.partner-directory-filter {
  .popover {
    margin-top: 0 !important;
    max-height: 500px !important;
    max-width: 300px !important;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 300px !important;
  }

  &-inner {
    max-width: 300px !important;
    width: 300px !important;
  }
}

.font-size-13 {
  font-size: 13px;
}

.referral-modal-popup {
  .modal-content {
    border-radius: 0;
    padding: 0 !important;
  }
}

// Progress Bar
/* background: */
progress::-webkit-progress-bar {
  background-color: $color-background-gray1;
  width: 100%;
}

progress {
  background-color: $color-background-gray1;
  color: $color-violet-dark;
}

/* value: */
progress::-webkit-progress-value {
  background-color: $color-violet-dark !important;
}

progress::-moz-progress-bar {
  background-color: $color-violet-dark !important;
}

.progressbar-wrapper {
  width: 100%;

  &:not(:first-child) {
    border-left: 1px $color-light-grey1 solid;
  }

  p {
    padding-left: 7px;
  }
}

.blue-progress-bar {
  progress::-webkit-progress-value {
    background-color: $primary-button-color !important;
  }

  progress::-moz-progress-bar {
    background-color: $primary-button-color !important;
  }

  progress {
    color: $primary-button-color;
  }
}

.red-progress-bar {
  progress::-webkit-progress-value {
    background-color: $color-light-red !important;
  }

  progress::-moz-progress-bar {
    background-color: $color-light-red !important;
  }

  progress {
    color: $color-light-red;
  }
}

.count {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (max-width: 992px) {
  .count {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }
}

.green-progress-bar {
  progress::-webkit-progress-value {
    background-color: $color-alert-green !important;
  }

  progress::-moz-progress-bar {
    background-color: $color-alert-green !important;
  }

  progress {
    color: $color-alert-green;
  }
}

.lessonCheckbox {
  margin-right: 1rem;
  @include force-width(32px);
  height: 32px;
  border-radius: 100%;
  border: 2px solid #cacacd;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: $color-white;
    max-height: 18px;
  }

  &.add-task {
    border-style: dotted;
    border-color: $color-cerulean;
    color: $color-cerulean;

    svg {
      width: 16px;
      height: 16px;
      color: $color-cerulean;
    }
  }

  &.checkbox-pending {
    background-color: $color-draft;
    border-color: $color-draft;
  }

  &.checkbox-completed {
    background-color: $color-success;
    border-color: $color-success;
  }
}

.pre-wrap {
  white-space: pre-wrap;
}

.photo-gallery-corousel {
  align-items: center;
  gap: 15px;
}

.edit-profile-media {
  @media only screen and (min-width: 1200px) {
    max-width: 495px;
  }
  @media screen and (min-width: 991px) and (max-width: 1200px) {
    max-width: 350px;
  }
  @media screen and (min-width: 768px) and (max-width: 990px) {
    max-width: 350px;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    max-width: 350px;
  }
  @media screen and (max-width: 320px) {
    max-width: 125px;
  }
}

.sidemenu .admin-setting {
  @media screen and (min-width: 991px) and (max-width: 1200px) {
    width: 50%;
  }
}

@media (max-width: 630px) {
  .mobile-wrapper {
    position: fixed;
    z-index: 29;
    width: 345px;
    color: black;
    background-color: white;
  }
}

.admin-setting {
  @media screen and (min-width: 991px) and (max-width: 1400px) {
    margin-left: -30px !important;

    .secondary-menu {
      background-color: $color-white;
      width: 244px;

      .nav-pills {
        .nav-item {
          border-bottom: 1px solid $color-light-white;
          cursor: pointer;

          .active {
            background-color: $color-white;
            color: $color-nav-active !important;
          }

          .nav-link {
            border-bottom: 0;
            border-left: 0;
            border-right: 0;
            color: $color-nav-link;
          }
        }
      }
    }
  }
}

.admin-setting {
  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-left: -30px !important;
  }

  .secondary-menu {
    background-color: $color-white;
    width: 207px;

    .nav-pills {
      .nav-item {
        border-bottom: 1px solid $color-light-white4;
        cursor: pointer;

        .active {
          background-color: $color-white;
          color: $color-nav-active !important;
        }

        .nav-link {
          border-bottom: 0;
          border-left: 0;
          border-right: 0;
          color: $color-nav-link;
        }
      }
    }
  }
}

.side-menu {
  display: block;
  @media (max-width: 630px) {
    display: none;
  }
}

.mobile-menu {
  display: none;
  @media (max-width: 630px) {
    display: block;
  }
}

@media screen and (min-width: 989px) and (max-width: 1199px) {
  .main-wrapper {
    max-width: 71% !important;
  }
}

.slick-track {
  margin-left: 0 !important;
}

.app-globe-icon {
  width: 15px !important;
  height: 15px !important;
}

.DraftEditor-editorContainer {
  height: fit-content !important;
  word-break: break-word;
}

.notranslate {
  height: 250px !important;
  max-width: 500px;
}

.message-card {
  height: 100px !important;
  overflow-y: scroll;
}

.edit-option:hover {
  background-color: #000;
}

@media (max-width: 765px) {
  .btn-wrapper {
    margin-left: 24px;
  }
}

@media (max-width: 500px) {
  .profile-picture-preview {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    object-fit: cover !important;
    width: 100% !important;
  }

  .avatar-picture {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    object-fit: cover !important;
    width: 100%;
  }
}
