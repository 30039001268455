.dropzone-wrapper {
  background-color: $color-background-gray;
  border: 2px dashed $color-grey-dark;
  border-radius: 5px;
  display: flex;
  max-height: 250px;
  min-height: 250px;
  .dropzone {
    flex: 1;
    outline: none;
  }
}

.assets-uploader {
  padding: 12px;
  background-color: #dfdfe1;
  .dropzone-wrapper {
    min-height: 56px;
    background-color: #eee;
    border-color: $color-grey-dark;
  }
  .file-preview {
    width: 100%;
    align-items: stretch;
    .card__corner-triangle {
      border-color: transparent #dfdfe1 transparent transparent;
    }
  }
  progress {
    border-radius: 200px;
  }
  progress::-webkit-progress-bar {
    background-color: transparent;
    border: 2px solid #dfdfe1;
    border-radius: 200px;
    overflow: hidden;
  }
  progress::-webkit-progress-value {
    height: 16px;
    background: $color-success;
  }
  progress::-moz-progress-bar {
    height: 16px;
    border-radius: 200px;
    background: $color-success;
    border: 2px solid #dfdfe1;
  }
}
