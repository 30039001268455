.inline-alert {
  display: inline-flex;
  width: 100%;
  &.alert {
    margin: 0 0 2rem;
  }
  &.alert-light {
    border-radius: 0;
    svg {
      color: $color-cerulean;
    }
    span {
      margin: 0 0 0 0.75rem;
      color: $color-cerulean;
    }
  }
}

.alerts-wrapper {
  margin-bottom: 1.5rem;
  .alert {
    margin-bottom: 0;
    border-radius: 0;
    &.alert-danger {
      background-color: #e96d61;
    }
    &.alert-success {
      background-color: $color-success;
    }
    &.alert-info {
      background-color: $color-cerulean;
    }
    .btn {
      white-space: nowrap;
    }
  }
}

.alert {
  &.alert-dark-info {
    background-color: #335262;
    padding: 2rem 2rem 2rem 2.5rem;
    .btn {
      font-size: 0.875rem;
      min-width: 160px;
    }
  }
  &.alert-light-info {
    background-color: $color-white;
    color: $color-dark;
  }
}

.alert {
  &.alert-dark-success {
    background-color: #0ac297;
    border-color: #0ac297;
  }
}
