.circle-colour-picker {
  @media (max-width: 620px) {
    grid-template-columns: repeat(8, 22px);
  }
  display: grid;
  grid-template-columns: repeat(11, 22px);
  grid-row-gap: 0.75rem;
  grid-column-gap: 1rem;
  .circle-colour-wrapper {
    padding: 2px;
    background-color: $color-white;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 100%;
  }
  .circle-colour {
    width: 0.875rem;
    height: 0.875rem;
    display: block;
    border-radius: 100%;
    pointer-events: none;
  }
}

.icon-picker {
  @media (max-width: 620px) {
    grid-template-columns: repeat(6, auto);
  }
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-column-gap: 1rem;
  grid-row-gap: 0.75rem;
  span {
    width: 36px;
    height: 36px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    svg {
      pointer-events: none;
      width: 100%;
      height: 100%;
    }
  }
}
