.datepicker {
  background: $color-background-gray;
  border: none;
  height: auto;
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  outline: none;
  cursor: default;
}

.react-date-picker {
  .react-date-picker__wrapper {
    .react-date-picker__inputGroup {
      input {
        pointer-events: none;
      }
      .react-date-picker__inputGroup__input:invalid {
        background: none;
      }
    }
    border: none !important;
    input {
      pointer-events: none;
    }
    input:focus,
    button:focus {
      outline: none;
    }
  }
}
.react-calendar {
  width: 386px;
  .react-calendar__navigation {
    background-color: $color-orange;
    color: $color-white;
    font-size: 1.25rem;
    font-family: "Muli", sans-serif;
    padding: 30px 30px 30px 30px !important;
    height: 80px;
    margin-bottom: 10px;
    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }
    .react-calendar__navigation__prev-button {
      color: $color-white;
      margin-left: 10px;
      &:hover,
      &:focus {
        background-color: $color-orange;
      }
    }
    .react-calendar__navigation__next-button {
      margin-right: 10px;
      color: $color-white;
      &:hover,
      &:focus {
        background-color: $color-orange;
      }
    }
    .react-calendar__navigation__label {
      color: $color-white;
      &:hover,
      &:focus {
        background-color: $color-orange;
      }
    }
    button[disabled] {
      color: #d3d3d3;
      background-color: transparent;
    }
  }
  .react-calendar__viewContainer {
    padding: 10px;
    .react-calendar__month-view {
      .react-calendar__month-view__weekdays {
        line-height: auto;
        border-bottom: 1px solid $color-input-gray-dark;
        padding-bottom: 10px;
        margin-bottom: 10px;
        .react-calendar__month-view__weekdays__weekday {
          abbr[title],
          abbr[data-original-title] {
            text-decoration: none;
            cursor: auto;
          }
        }
      }
      .react-calendar__month-view__days {
        .react-calendar__tile {
          padding: 10px 10px 10px 10px;
          width: 50px;
          height: 47px;
          border-radius: 50%;
          &:enabled {
            &:hover {
              background-color: #0ac297;
              color: $color-white;
            }
          }
        }
        .react-calendar__tile--active {
          background-color: $color-cerulean;
        }
      }
    }
  }
}

.datepicker-wrapper {
  .react-datepicker-popper {
    &[data-placement^="bottom"] {
      .react-datepicker {
        width: 100%;
      }
    }
    .react-datepicker {
      .react-datepicker__triangle {
        border-bottom-color: $color-orange;
        &:before {
          border-bottom-color: $color-orange;
        }
      }
    }
  }
  .react-datepicker {
    border: none;
    border-radius: 0;
  }
}
.react-datepicker {
  .react-datepicker__header {
    border-radius: 0;
    background-color: $color-orange;
    border-bottom: 0;
    padding-top: 0;
  }
  .react-datepicker__header--time {
    padding: 1rem 0.5rem;
  }
  .react-datepicker-time__header {
    color: $color-white;
  }
  .react-datepicker__current-month {
    color: $color-white;
    font-size: 1.25rem;
    font-family: "Muli", sans-serif;
    font-weight: 400;
    padding: 2rem 4rem 2rem;
  }
  .react-datepicker__navigation {
    top: 2px;
    width: 24px;
    height: 24px;
    margin: 2rem 0 0;
    &:after {
      text-indent: 0;
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .react-datepicker__navigation--previous {
    border: none;
    &:after {
      content: url("assets/img/icons/icon-chevron-left-white.svg");
    }
  }
  .react-datepicker__navigation--next {
    border: none;
    &:after {
      content: url("assets/img/icons/icon-chevron-right-white.svg");
    }
  }
  .react-datepicker__day-names {
    background-color: $color-white;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 36px;
    height: 36px;
    margin: 0 2px;
    padding: 4px;
  }
  .react-datepicker__day {
    border-radius: 100%;
    &:hover {
      border-radius: 100%;
    }
  }
  .react-datepicker__day-names {
    border-bottom: 1px solid $color-input-gray-dark;
  }
  .react-datepicker__day--selected {
    background-color: $color-cerulean;
  }
}
