.project-list {
  margin: 1.35rem 0 0;
  .project {
    background-color: $color-white;
    color: #212529;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.35rem;

    .arrow-hover {
      .feather-arrow-right {
        opacity: 0;
      }
    }

    &:hover {
      color: #212529;
      -webkit-transition: box-shadow 0.3s ease-in-out;
      -moz-transition: box-shadow 0.3s ease-in-out;
      -o-transition: box-shadow 0.3s ease-in-out;
      transition: box-shadow 0.3s ease-in-out;

      .arrow-hover {
        .feather-arrow-right {
          color: $color-cerulean;
          opacity: 1;
          -webkit-transition: opacity 0.3s ease-in-out;
          -moz-transition: opacity 0.3s ease-in-out;
          -o-transition: opacity 0.3s ease-in-out;
          transition: opacity 0.3s ease-in-out;
        }
      }

      .project-playbook-card {
        .card {
          .card-body {
            .card-title {
              color: $color-cerulean;
              -webkit-transition: color 0.3s ease-in-out;
              -moz-transition: color 0.3s ease-in-out;
              -o-transition: color 0.3s ease-in-out;
              transition: color 0.3s ease-in;
            }
          }
        }
      }
    }
    .project-maininfo {
      padding: 1.35rem 1.35rem 0.75rem;
      min-height: 224px;
      max-height: 224px;
      border-bottom: 1px solid $color-background-gray;
    }

    .partner-program {
      padding: 1.35rem 1.35rem 0.75rem;
      min-height: 180px;
      max-height: 180px;
    }
    .project-playbook-card {
      min-width: 265px;
      margin-bottom: 0 !important;
      .card {
        box-shadow: none;
        .card-header {
          height: 224px;
          position: relative;
        }
        .card-body {
          height: 148px;
        }
        .new-message,
        .new-alert-message {
          position: absolute;
          background-color: $color-notification-orange;
          color: white;
          font-weight: 300;
        }
        .new-message {
          top: 10px;
          left: 10px;
          display: flex;
          padding: 0.5625rem 0.75rem;
          border-radius: 19px;
          line-height: 1.25rem;
          svg {
            height: 20px;
            width: 20px;
            margin-right: 0.5rem;
          }
        }
        .new-alert-message {
          bottom: -1px;
          left: 0;
          right: 0;
          padding: 0.875rem 1.25rem;
          font-size: 0.75rem;
        }
      }
    }
    .project-finances {
      margin-right: 1.125rem;
      max-width: 250px;
      min-width: 250px;
      width: 100%;
    }
    .project-progress {
      width: 100%;
      min-width: 248px;
      max-width: 248px;
    }
    .project-timeline {
      border-left: 1px solid $color-background-gray;
      height: 100%;
      display: inline-flex;
      align-items: center;
      padding-left: 1.875rem;
    }
  }
  .supporting-materials {
    .supporting-material {
      padding: 0.5rem 0.5rem;
      margin: -0.5rem -0.5rem;
    }
  }
  @include small-desktop {
    .project {
      .project-playbook-card {
        min-width: 235px;
      }
      .project-maininfo {
        padding: 1rem 0.25rem 0.75rem 1rem;
      }
      .project-timeline {
        padding-left: 1rem;
      }
      .project-finances {
        min-width: 210px;
        max-width: 210px;
        margin-right: 1rem;
      }
      .project-progress {
        max-width: 205px;
        min-width: 205px;
      }
    }
  }

  @include tablet {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem;
    .project {
      flex-direction: column;
      .project-playbook-card {
        min-width: 1px;
        .card {
          .card-body {
            height: auto;
            min-height: 1px;
            border-bottom: 1px solid $color-background-gray;
          }
        }
      }
      .project-maininfo {
        flex-direction: column;
        max-height: none;
        min-height: 1px;
        padding: 1.25rem;
        border: none;
      }
      .project-finances,
      .project-progress {
        max-width: none;
        width: 100%;
        min-width: 1px;
        margin: 0;
      }
      .project-finances,
      .project-progress {
        .field-wrapper {
          &:not(:first-child) {
            display: none;
          }
        }
      }
      .project-progress {
        .field-wrapper {
          margin-bottom: 0;
        }
      }
      .project-timeline {
        padding: 0 1.25rem 1.25rem;
        border: none;
      }
    }
  }
  @include mobile {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0;
    .project {
      margin: 0 auto 1.25rem;
      min-width: 285px;
      max-width: 300px;
    }
  }
  @include mobile-xs {
    .project {
      min-width: 1px;
      width: 100%;
    }
  }
}

.steps-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .step {
    display: inline-flex;
    align-items: center;
    .step-icon {
      min-width: 50px;
      min-height: 50px;
      max-width: 50px;
      max-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      border: 2px solid $color-grey-dark;
      margin: 0 1.25rem 0 0;
      svg {
        max-height: 22px;
        color: $color-grey-dark;
      }
    }

    .step-status-text {
      display: none;
    }

    .feather-arrow-right {
      color: $color-input-gray;
    }

    .step-status-text,
    .feather-arrow-right,
    .step-icon {
      margin: 0 0.8rem 0 0;
    }
    &.current-step {
      .step-icon {
        background-color: $color-cerulean;
        border-color: $color-cerulean;
      }
      .step-status-text {
        display: block;
      }
    }
    &.completed-step {
      .step-icon {
        background-color: $color-success;
        border-color: $color-success;
      }
      .step-status-text {
        display: none;
      }
    }
    &.completed-step,
    &.current-step {
      .step-icon {
        svg {
          color: $color-white;
        }
      }
    }
    &:last-child {
      .step-status-text {
        margin-right: 0;
      }
      &:not(.current-step) {
        .step-icon {
          margin-right: 0;
        }
      }
    }
  }
  @include small-desktop {
    .step {
      .feather-arrow-right,
      .step-status-text,
      .step-icon {
        margin-right: 0.5rem;
      }
    }
  }
  @include tablet {
    .step {
      .step-icon {
        min-width: 2rem;
        min-height: 2rem;
        max-width: 2rem;
        max-height: 2rem;
        svg {
          max-height: 15px;
        }
      }
      .step-status-text {
        display: none !important;
      }
      .feather-arrow-right {
        height: 1rem;
        width: 1rem;
      }
    }
  }
}

.project-list .project,
.sidebar-item {
  .field-wrapper {
    p,
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
    &:not(:last-child) {
      margin: 0 0 1.25rem;
    }
    p {
      margin-bottom: 0;
      line-height: 1.125rem;
      span {
        &:before {
          content: " ";
        }
      }
    }
  }
  .supporting-materials {
    .field-wrapper {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
    + .field-wrapper,
    + .supporting-materials {
      margin-top: 1.25rem;
    }
  }
}

.project-list .project,
.details-body-sidebar {
  .user-wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    .field-icon {
      border-radius: 100%;
      background-color: $color-background-gray;
      overflow: hidden;
    }
  }
  .field-icon {
    min-width: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    + p,
    + .flex-column {
      margin: 0 0 0 1rem;
    }
  }
}

.user-wrapper {
  .expert-info {
    min-width: 0;
  }
  .user-actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    a {
      svg {
        max-height: 16px;
        max-width: 16px;
      }
    }
    label {
      display: block;
      margin-bottom: 0;
    }
  }
  .form-wrapper {
    .radio-button {
      + p {
        .radio-placeholder {
          margin-right: 0;
          min-width: 20px;
          max-width: 20px;
          min-height: 20px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
.alerts-wrapper,
.feedback-component {
  + .project-details-wrapper {
    margin: 0;
    padding-top: 1.5rem;
  }
}
.project-details {
  background: $color-white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin: 0 0 2rem;
  .project-details-header {
    display: flex;
    border-bottom: 1px solid #e2e2e8;
  }
  .img-wrapper {
    @include force-width(265px);
  }
  .description {
    padding: 1.25rem;
  }
  .show-more {
    align-items: center;
    font-weight: 700;
    svg {
      margin: 0.1875rem 0 0 0.25rem;
      width: 1rem;
      height: 1rem;
    }
  }
  @include tablet {
    .show-more {
      font-size: 0.875rem;
    }
  }
  @include mobile {
    .project-details-header {
      flex-direction: column;
      .img-wrapper {
        @include force-width(100%);
      }
      .card {
        max-width: none;
      }
      .card-header {
        height: auto;
        padding: 80% 0 0;
      }
    }
  }
}

.thead-light {
  background-color: #292934;
  color: #fff;
  vertical-align: top;
}

.task-list,
.sidebar-task {
  .task {
    border-top: 1px solid #e2e2e8;
    padding: 2rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 120px;
  }
  .task-left,
  .task-right {
    display: flex;
    align-items: center;
    justify-content: stretch;
  }
  .task-left {
    margin-right: 0.5rem;
  }
  .task-left-1,
  .task-right {
    display: flex;
    align-items: center;
    justify-content: stretch;
  }
  .task-right {
    margin-left: auto;
  }
  .checkbox {
    margin-right: 1rem;
    @include force-width(32px);
    height: 32px;
    border-radius: 100%;
    border: 2px solid #cacacd;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: $color-white;
      max-height: 18px;
    }
    &.add-task {
      border-style: dotted;
      border-color: $color-cerulean;
      color: $color-cerulean;
      svg {
        width: 16px;
        height: 16px;
        color: $color-cerulean;
      }
    }
    &.checkbox-pending {
      background-color: $color-draft;
      border-color: $color-draft;
    }
    &.checkbox-completed {
      background-color: $color-success;
      border-color: $color-success;
    }
  }
  .name-comments {
    min-width: 180px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    a {
      text-decoration: underline solid;
      font-size: 0.875rem;
      font-weight: 600;
      margin-bottom: 0.25rem;
      &.add-task {
        font-weight: 300;
      }
    }
    .badge {
      background: #cacacd;
      color: $color-white;
      text-transform: uppercase;
      line-height: 1rem;
      padding: 0.125rem 0.5rem;
      font-size: 0.75rem;
    }
  }
  .date-role {
    min-width: 110px;
    p {
      font-weight: 700;
      margin-bottom: 0.25rem;
    }
    a {
      color: $color-cerulean;
      &:hover {
        color: $color-cerulean;
      }
      span {
        color: $color-black;
      }
    }
  }
  .task-actions {
    margin-left: 0;
    display: flex;
    min-width: 64px;
    .btn-link {
      color: $color-black;
    }
    .btn {
      padding: 0.25rem;
      &:last-child {
        padding-right: 0;
      }
    }
    .delete-btn {
      &:hover {
        color: $color-orange;
      }
    }
    .edit-btn {
      &:hover {
        color: $color-blue;
      }
    }
  }
  @include small-desktop {
    .task {
      padding: 1.5rem 1rem;
      min-height: 100px;
    }
    .checkbox {
      margin-right: 0.75rem;
    }
    .task-actions {
      .btn {
        svg {
          max-width: 20px;
          height: auto;
        }
      }
    }
  }
  @include tablet {
    .name-comments {
      min-width: 160px;
    }
  }
  @include mobile-xs {
    .task {
      flex-direction: column;
      align-items: flex-start;
    }
    .task-left,
    .task-right {
      width: 100%;
    }
    .task-left {
      margin: 0;
    }
    .task-right {
      margin: 1rem 0 0;
      padding-top: 1rem;
      border-top: 1px dotted $tab-inactive-border;
    }
    .task-actions {
      margin-left: auto;
    }
  }
}
.user-badge {
  padding: 0.125rem 0.5rem 0.125rem 0.125rem;
  height: 24px;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  background-color: $color-background-gray;
  font-size: 0.625rem;
  img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin: 0 0.375rem 0 0;
  }
}
.project-details-body {
  display: flex;
  justify-content: stretch;
  .title-wrapper {
    padding: 1.5rem;
    h6 {
      font-weight: 700;
      margin-bottom: 0;
    }
  }
  .details-body-sidebar {
    @include force-width(290px);
    margin: 0 0 0 auto;
  }
  .details-body-main {
    width: 100%;
  }
  @include small-desktop {
    .details-body-main {
      max-width: 455px;
    }
    .details-body-sidebar {
      @include force-width(250px);
    }
  }
  @include tablet {
    .details-body-main {
      max-width: none;
    }
    .details-body-sidebar {
      @include force-width(220px);
    }
  }
  @include mobile {
    flex-direction: column;
    .details-body-sidebar {
      @include force-width(100%);
    }
  }
}
.salesforce_tasks {
  width: 300px;
}

.details-body-sidebar {
  border-left: 1px solid #e2e2e8;
  .sidebar-item {
    padding: 1.25rem;
    &:not(:last-child) {
      border-bottom: 1px solid #e2e2e8;
    }
    .expert-card {
      margin-bottom: 0 !important;
    }
    .btn-wrapper {
      justify-content: center;
      .btn {
        font-size: 0.875rem;
        margin: 0 0.25rem;
        svg {
          width: auto;
          height: 16px;
        }
      }
    }
    .card {
      box-shadow: none;
      width: 100% !important;
      .card-header {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      }
    }
    progress {
      border-radius: 1px;
      height: 30px;
      width: 100%;
      margin-top: 0.25rem;
      display: block;
    }
    progress::-webkit-progress-bar {
      background-color: transparent;
      border: 1px solid #dfdfe1;
      overflow: hidden;
      border-radius: 1px;
    }
    progress::-webkit-progress-value {
      height: 30px;
      background: $color-cerulean;
    }
    progress::-moz-progress-bar {
      height: 30px;
      background: $color-cerulean;
      border-radius: 1px;
      border: 1px solid #dfdfe1;
    }
    .badge {
      border-radius: 7px;
      padding: 0.25rem 0.375rem;
      display: block;
    }
  }
  @include small-desktop {
    .sidebar-item {
      padding: 1.25rem 1rem;
    }
  }
  @include mobile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border: none;
    border-top: 1px solid $tab-inactive-border;
    .sidebar-item {
      &:nth-child(odd) {
        border-right: 1px solid $tab-inactive-border;
      }
    }
  }
  @include mobile-xs {
    grid-template-columns: repeat(1, 1fr);
  }
}

.supporting-materials {
  .supporting-material {
    display: flex;
    justify-content: stretch;
    align-items: center;
    padding: 0.5rem 1rem;
    margin: 0 -1rem;
    @include transition(all, 230ms, ease);
    cursor: pointer;
    .icon {
      margin-right: 1.125rem;
      svg {
        margin: auto;
      }
    }
    .name {
    }
    .action {
      margin: 0 0 0 auto;
      color: $color-cerulean;
      pointer-events: none;
      @include transition(all, 230ms, ease);
    }
    &:hover {
      background-color: $color-cerulean-hover;
      .action {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}

.feedback-component {
  margin-bottom: 1.5rem;
  #card-link {
    margin-bottom: 0 !important;
  }
  label {
    &.d-block {
      + .field-wrapper {
        margin-top: 1rem;
      }
    }
  }
}
.rating-component {
  padding: 1.5rem;
  background-color: $color-white;
  .checkbox-wrapper {
    label {
      span {
        font-size: 1rem;
      }
    }
  }
  .field-wrapper {
    margin-top: 1.5rem;
    .datepicker-wrapper {
      flex: 1;
      .react-datepicker-wrapper {
        width: 100%;
      }
    }
    p {
      width: 100%;
    }
  }
  .rating-wrapper {
    &:not(:last-of-type) {
      margin-bottom: 1.25rem;
    }
    h5 {
      font-size: 1.125rem;
      margin-bottom: 0.25rem;
    }
  }
  .rating-wrapper {
    svg {
      height: 22px;
      width: 22px;
    }
  }
}

.mock-tasks {
  &.disabled,
  &.cursor-none {
    pointer-events: none;
    filter: grayscale(100);
    * {
      pointer-events: none;
      filter: grayscale(100);
    }
    p,
    svg,
    a {
      color: rgba(41, 41, 52, 0.5);
    }
  }
}

.project-details {
  @include small-desktop {
    .img-wrapper {
      margin-bottom: 0 !important;
    }
    #card-link {
      margin-bottom: 0 !important;
    }
  }
}
