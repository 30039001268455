.discussions-body {
  padding: 0 1.5rem;
  .discussion-input-wrapper {
    textarea {
      resize: none;
      background: $color-background-gray;
      max-height: 80px;
      min-height: 40px;
      padding-right: 115px;
    }
  }
  .field-wrapper {
    &.position-relative {
      .discussion-actions {
        height: 30px;
        position: absolute;
        right: 9px;
        top: 0px;
        display: flex;
        align-items: stretch;
        justify-content: flex-end;
        z-index: 3;
        .draftJsEmojiPlugin__emojiSelectButton__3sPol,
        .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
          width: auto;
          height: auto;
          padding: 0;
          border-radius: 0;
          border: none;
          display: block;
          line-height: 1;
          background: transparent;
          font-size: 1.5rem;
          color: rgba(0, 0, 0, 0.25);
        }
        label {
          margin-top: 0.5rem;
        }
        .discussion-action {
          padding: 0 0.5rem;
          border: none;
          background: transparent;
          outline: none;
          svg {
            max-width: 22px;
            max-height: 22px;
            width: 100%;
            height: 100%;
            color: rgba(0, 0, 0, 0.25);
          }
        }
        img {
          margin: auto;
        }
      }
    }
  }
}
