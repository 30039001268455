.site-sidebar {
  margin-bottom: 2.5rem;
  &.checkout-sidebar {
    margin-top: 115px !important;
  }

  @media screen and (min-width: 500px) {
    background: none !important;
  }

  @include mobile {
    margin-bottom: 0;
    .card {
      max-width: none;
    }
  }
}
.sidebar-content-box {
  &:not(.transparent-box) {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    background-color: $color-white;
  }
  &:not(:last-child) {
    margin-bottom: 1.875rem;
  }
  &.transparent-box {
    .sidebar-content {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @include mobile {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}
.sidebar-content {
  padding: 1.25rem;
  .title {
    margin-bottom: 0.25rem;
  }
  .subtitle {
    opacity: 0.5;
    margin-bottom: 0.5rem;
  }
  .price {
    margin-bottom: 1rem;
  }
  p {
    font-size: 0.875rem;
    &:not(:last-of-type) {
      margin-bottom: 0.25rem;
    }
    svg {
      margin: 0 0.5rem 0 0;
      width: 18px;
    }
  }
  .checklist {
    li {
      display: flex;
      font-size: 0.875rem;
      svg {
        margin-right: 0.625rem;
        width: 20px;
        color: $color-orange;
      }
    }
  }
  .badge {
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    font-weight: 300;
    margin: 0 0.5rem 0.5rem 0;
    float: left;
  }
  .btn-xs {
    font-size: 0.875rem;
  }
}

.social-icons {
  display: flex;
  align-items: center;
  a {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.tag-cloud {
  .sidebar-content {
    padding-bottom: 0.75rem;
    &:after {
      content: " ";
      clear: both;
      display: block;
    }
  }
}
