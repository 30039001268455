.nl-form {
  text-align: center;
  background: $color-white;
  padding: 1.25rem;
  h4 {
    font-weight: 300;
    line-height: 1.75rem;
    margin-bottom: 0.625rem;
    //display: inline;
    a,
    .dropdown-toggle {
      color: $color-cerulean;
      text-decoration: underline dotted;
    }
    .dropdown-wrapper {
      min-width: 1px;
      width: auto;
      .dropdown-menu {
        max-height: 225px;
        overflow-y: auto;
        min-width: 245px;
        outline: none;
      }
      &.select-dropdown {
        .dropdown-toggle {
          max-width: 100%;
          white-space: normal;
          display: inline-block;
        }
      }
      &.checkbox-dropdown {
        .dropdown-toggle {
          text-decoration: none;
          span {
            &:not(.d-inline) {
              color: $color-cerulean;
              text-decoration: underline dotted;
            }
          }
          span {
            &.d-inline {
              color: $color-black;
            }
          }
        }
      }
    }
    .dropdown-toggle {
      font-weight: 300;
      padding: 0;
      font-size: inherit;
    }
  }
  .btn-wrapper {
    margin: 1.125rem 0 1rem;
  }
  .show-more-filters {
    display: flex;
    align-items: center;
    flex-direction: column;
    .btn {
      justify-content: center;
      text-align: center;
      padding: 0;
    }
    span {
      font-size: 0.875rem;
    }
    .circle-arrow-down {
      width: 42px;
      height: 42px;
      border-radius: 100%;
      border: 2px solid $color-cerulean;
      color: $color-cerulean;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto -45px;
      background-color: $color-white;
      svg {
        width: 19px;
        height: 19px;
      }
    }
    &.filters-active {
      margin: 1.125rem 0;
      .circle-arrow-down {
        margin-bottom: 0;
      }
    }
  }
  + .card-list-wrapper,
  + .tab-content-wrapper {
    margin-top: 2rem;
  }
  + .inline-alert {
    &.alert {
      margin-top: 2rem;
    }
  }
  + .modal-body {
    border-top: 1px solid #dfdfe1;
  }
}

.autocomplete-dropdown-container {
  position: absolute;
  top: 100%;
  margin-top: 7px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  background-color: $color-white;
  z-index: 2;
  .suggestion-item,
  .suggestion-item--active {
    margin: 0.5rem 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    text-align: left;
  }
  .suggestion-item--active {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
  }
}

.filters-wrapper {
  + .modal-body {
    border-top: 1px solid #dfdfe1;
  }
}
