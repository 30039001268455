html {
  body {
    .app {
      &.flex-row {
        &.align-items-center {
          height: 100vh;
        }
      }
    }
  }
}
