.kickoff-scheduler {
  padding: 1.25rem;
  background-color: $color-white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
  &__communication-method {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
    p {
      margin: 0;
    }
    .dropdown-wrapper {
      justify-content: flex-start !important;
      margin-left: 1rem;
    }
    .dropdown-toggle {
      &:after {
        content: "";
        display: none;
      }
    }
    .btn {
      background-color: $color-background-gray;
    }
  }
  &__timezone {
    padding: 0.625rem 1.25rem;
    border: 1px solid $tab-inactive-border;
    margin-bottom: 0.625rem;
    p {
      margin: 0;
    }
    .btn {
      margin-left: 1rem;
      border: none;
    }
  }
  &__wrapper {
    position: relative;
    overflow: hidden;
  }
  &__timepicker {
    display: flex;
    flex-wrap: nowrap;
    transition: transform 0.4s ease;
  }
  &__scroll-btn {
    border: none;
    background-color: transparent;
    color: $color-white;
    position: absolute;
    top: 6px;
    z-index: 2;
    &--left {
      left: 0;
    }
    &--right {
      right: 0;
    }
    svg {
      width: 30px;
      height: auto;
    }
  }
  &__days {
    flex: 0 0 auto;
    width: calc(100% / 3);
    margin: 0 3px 0 0;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-transform: uppercase;
    border: 1px solid $tab-inactive-border;
    margin: 0;
    &--expert {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &__list-item {
    padding: 0.75rem 0.625rem;
    border-bottom: 1px solid $tab-inactive-border;
    margin-bottom: -1px;
    &:nth-child(odd) {
      border-right: 1px solid $tab-inactive-border;
    }
  }
  .checkbox-wrapper {
    padding: 0;
  }
  .checkbox-placeholder {
    margin-top: 0 !important;
  }
  &__checkbox {
    &--radio {
      + .radio-placeholder {
        border-radius: 100% !important;
        &:after {
          background: #1290fb !important;
          border-radius: 100%;
          border: 3px solid #fff;
        }
      }
    }
    &:checked {
      + .checkbox-placeholder {
        border-color: #1290fb;
        background: #1290fb;
        &:after {
          content: " ";
          background: url("assets/img/icons/icon-check-white.svg") no-repeat
            center center;
          background-size: 18px;
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &__label {
    margin-bottom: 0;
  }
  &__header {
    padding: 0.625rem 0;
    background-color: #87878c;
    text-align: center;
    font-weight: 700;
    color: $color-white;
  }
  &__button {
    border: none;
    background-color: #dbdbe3;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    width: 100%;
    margin: 3px 0 0;
    &:active,
    &:focus {
      outline: none;
    }
    svg {
      height: 22px;
      width: 22px;
    }
  }
  &__btn-wrapper {
    &.disabled {
      pointer-events: all;
      opacity: 1;
      .btn {
        pointer-events: none;
        opacity: 0.4;
        &.btn-outline-secondary {
          pointer-events: all;
        }
      }
    }
  }
  @include mobile {
    &__header {
      font-size: 0.875rem;
    }
    &__communication-method {
      .dropdown-wrapper {
        min-width: 1px;
      }
    }
    &__label {
      span {
        margin: auto 0;
      }
    }
    &__scroll-btn {
      svg {
        width: 20px;
      }
      &--left {
        left: -8px;
      }
      &--right {
        right: -8px;
      }
    }
    &__days {
      width: calc(100% / 2);
    }
  }
  @include mobile-xs {
    &__communication-method {
      flex-wrap: wrap;
      .dropdown-wrapper {
        margin: 1rem 0 0;
      }
    }
    &__days {
      width: calc(100%);
    }
  }
}

.react-add-to-calendar {
  padding: 0.625rem 1.25rem;
  border: 1px solid #e2e2e8;
  &__button {
    font-weight: 600;
    font-size: 1rem;
    display: block;
    span {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
  &__dropdown {
    margin: 1rem 0;
    ul {
      padding: 0;
      list-style-type: none;
      display: grid;
      grid-gap: 1.25rem;
      grid-template-columns: repeat(2, 1fr);
    }
    a {
      padding: 0.625rem 1.25rem;
      border-radius: 7px;
      display: block;
      color: $color-white;
      font-weight: 600;
      font-size: 1.25rem;
      &:hover {
        color: $color-white;
        text-decoration: none;
      }
      &.apple-link {
        background-color: $color-dark;
      }
      &.google-link {
        background-color: #4287f6;
      }
      &.outlookcom-link {
        background-color: #0078d4;
      }
      &.yahoo-link {
        background-color: #4a00a0;
      }
      .fa {
        font-size: 1.25rem;
        margin-right: 1.5rem;
        width: 24px;
      }
    }
  }
  @include small-desktop {
    &__dropdown {
      a {
        font-size: 1rem;
      }
    }
  }
  @include tablet {
    &__dropdown {
      a {
        .fa {
          margin-right: 1rem;
        }
      }
    }
  }
  @include mobile {
    &__dropdown {
      a {
        padding: 0.5rem 0.75rem;
        font-size: 0.875rem;
        .fa {
          width: 1rem;
          font-size: 1rem;
          margin-right: 0.625rem;
        }
      }
    }
  }
  @include mobile-xs {
    &__dropdown {
      ul {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

.kickoff-tips {
  &__tip {
    &:not(:last-child) {
      margin: 0 0 1.25rem;
    }
    display: flex;
    align-items: center;
  }
  &__number {
    height: 50px;
    @include force-width(50px);
    color: $color-cerulean;
    background: rgba(0, 157, 218, 0.1);
    border-radius: 100%;
    font-size: 1.25rem;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  &__description {
    margin: 0 0 0 1.25rem;
    &--main {
      margin: 0 0 1.5rem;
    }
  }
}

.expert-message {
  background-color: #f3f3f5;
  padding: 0.625rem;
  border-radius: 5px;
  line-height: 1.5rem;
  margin: 0 0 1.25rem;
  &__description {
    margin-bottom: 0;
  }
}
