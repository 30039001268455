.card-statistics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  margin-bottom: 1.5rem;

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .card-statistic {
    padding: 1.5rem 0;
    margin: 0.625rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 767px) {
      margin-left: 5px;
      margin-right: 5px;
    }
    &:nth-child(1) {
      background-color: #0098cd;
    }
    &:nth-child(2) {
      background-color: #d88219;
    }
    &:nth-child(3) {
      background-color: #4c2683;
    }
    &:nth-child(4) {
      background-color: #69be5a;
    }
    @media screen and (min-width: 768px) {
      &:not(:last-child) {
        margin-right: 16px;
        border-right: 1px solid $tab-inactive-border;
      }
    }
    h2,
    h6 {
      color: $color-white;
    }
    h6 {
      font-weight: 700;
    }
    svg {
      width: 38px;
      height: 38px;
    }
  }
}

.border-curved {
  border-radius: 0.625rem;
  h2,
  h6 {
    color: $color-black !important;
  }
  &:nth-child(1) {
    background-color: #e3f2f9 !important;
  }
  &:nth-child(2) {
    background-color: #fcf4e6 !important;
  }
  &:nth-child(3) {
    background-color: #eff4eb !important;
  }
  &:nth-child(4) {
    background-color: #ebe6f2 !important;
  }
}

.report-card-statistics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  margin-bottom: 1.5rem;
  min-height: 190px;

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(3, 1fr);
  }
  .card-statistic {
    padding: 1.5rem 0;
    margin: 0.625rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 767px) {
      margin-left: 5px;
      margin-right: 5px;
    }

    @media screen and (min-width: 768px) {
      &:not(:last-child) {
        margin-right: 16px;
        border-right: 1px solid $tab-inactive-border;
      }
    }
    h2,
    h6 {
      color: $color-blue;
    }
    h6 {
      color: $color-black;
      font-weight: 700;
    }
    svg {
      color: $color-blue;
      width: 38px;
      height: 38px;
    }
  }
}

.course-card-statistics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  margin-bottom: 1.5rem;
  min-height: 190px;

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .card-statistic {
    padding: 1.5rem 0;
    margin: 0.625rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 767px) {
      margin-left: 5px;
      margin-right: 5px;
    }

    @media screen and (min-width: 768px) {
      &:not(:last-child) {
        margin-right: 16px;
        border-right: 1px solid $tab-inactive-border;
      }
    }
    h2,
    h6 {
      color: $color-blue;
    }
    h2 {
      font-size: 38px;
      color: $color-cerulean;
    }
    h6 {
      color: $color-black;
      font-weight: 700;
      font-size: 18px;
    }
    svg {
      color: $color-blue;
      width: 38px;
      height: 38px;
    }
  }
}

.list-view-card {
  display: grid;

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
  justify-content: center;
  margin-bottom: 1.5rem;
  .card {
    margin-bottom: 0.6rem;

    @media only screen and (min-width: 768px) {
      &:nth-child(odd) {
        margin-right: 0.3rem;
      }
      &:nth-child(even) {
        margin-left: 0.3rem;
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .card-statistic {
    padding: 1.5rem 0;
    margin: 0.625rem 0;
    &:not(:last-child) {
      border-right: 1px solid $tab-inactive-border;
    }
    h2,
    h6 {
      color: $color-dark;
    }
    h6 {
      font-weight: 700;
    }
  }
}

.card-count-statistics {
  display: grid;
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 2fr) !important;
  }
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  margin-bottom: 1.5rem;
  .card-statistic {
    padding: 1.5rem 0;
    margin: 0.625rem 0;
    &:not(:last-child) {
      border-right: 1px solid $tab-inactive-border;
      @media (max-width: "630px") {
        border-right: 0px solid $tab-inactive-border;
      }
    }
    h6 {
      color: $color-dark;
    }
    h6 {
      font-weight: 700;
    }
  }
}

.app-card-count-statistics {
  display: grid;
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 2fr) !important;
  }
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  margin-bottom: 1rem;
  .card-statistic {
    padding: 1.2rem 0;
    &:not(:last-child) {
      border-right: 1px solid $tab-inactive-border;
      @media (max-width: "630px") {
        border-right: 0px solid $tab-inactive-border;
      }
    }
    h6 {
      color: $color-dark;
    }
    h6 {
      font-weight: 700;
    }
  }
}
