@import "../../scss/variables";
@import "../../scss/mixins";
#sidebar {
  border-right: 1px solid #e2e2e8;
  color: lightgray;
  min-height: calc(100vh - 61px); // minus the navbar height
  height: 100%;
  position: relative;
  padding-top: 1.5rem;

  .primary-sidebar {
    border-right: 1px solid #e2e2e8;
    color: lightgray;
    min-height: calc(100vh - 61px); // minus the navbar height
    height: 100%;
    position: relative;
    padding-top: 1.5rem;
  }

  .sidebar-menu {
    @media screen and (min-width: 991px) {
      float: left;
    }
    margin-top: 10px;
  }

  .sidebar-header {
    padding: 20px;
    background: #6d7fcc;
  }

  ul {
    // border-bottom: 1px solid $color-background-gray;

    p {
      color: #292934;
      margin-top: 10px;
      font-size: 20px;
    }

    li {
      border-left: 3px solid transparent;
      transition: all 180ms ease;
      &.active {
        background-color: #fff;
        border-left: 3px solid $color-cerulean;
        svg {
          color: $color-cerulean;
          path {
            fill: currentColor !important;
          }
        }
      }

      &:not(:first-child) {
        margin-top: 10px;
      }

      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.8125rem 0.875rem;
        font-size: 1rem;
        color: #292934;
        text-decoration: none;
      }
      svg {
        max-height: 22px;
        margin: 0 1rem 0 0;
        min-width: 24px;
        @include tablet {
          margin-right: 0;
        }
      }
      &:hover {
        background-color: #fff;
        border-left: 3px solid #3572b0;
        color: #000;
        .sidebar-menu {
          color: #2496cd;
        }
      }
    }
  }
}

a[aria-expanded="true"] {
  color: #292934;
}

@include tablet {
  .sidebar-wrapper {
    &.w-auto {
      padding-left: 0;
      max-width: 71px;
    }
  }
}
@include mobile-xs {
  .sidebar-wrapper {
    position: fixed;
    z-index: 13;
    background-color: rgba(0, 0, 0, 0.1);
    &.w-auto {
      padding-right: 0;
      border-right: 0;
      max-width: none;
      width: 100% !important;
    }
    &.menu-hidden {
      display: none;
    }
    &.menu-toggle {
      display: block;
    }
    #sidebar {
      width: calc(100vw - 80px);
      background-color: $theme-color-body-background;
      border-right: 0;
      min-height: calc(100vh - 46px);
    }
    ul {
      li {
        svg {
          margin-right: 1rem !important;
        }
      }
      .desktop-only {
        display: block;
      }
    }
    #sidebar {
      padding-top: 1rem;
      position: absolute;
      overflow-y: scroll;
    }
  }
}
