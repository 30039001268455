.form-wrapper {
  .field-wrapper-group {
    padding-bottom: 2.25rem;
    &:not(:last-child) {
      margin-bottom: 1.25rem;
      border-bottom: 1px solid #d8d8d8;
    }
    > label {
      + .inline-tooltip-wrapper {
        margin-top: 0;
        + .grouped-fields {
          margin-top: 1.25rem;
        }
      }
    }
    &.group-outlined {
      padding: 1.25rem;
      background: $color-white;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      .field-wrapper {
        &:not(:last-child) {
          margin-bottom: 1.25em;
        }
      }
    }
  }
  .grouped-fields {
    display: grid;
    grid-template-columns: 55% 45%;
    grid-column-gap: 1.25rem;
    .field-wrapper {
      &:not(:last-child) {
        margin-bottom: 1.25rem;
      }
    }
  }
  .field-wrapper {
    display: flex;
    flex-wrap: wrap;
    .inline-field-wrapper {
      width: 50%;
      min-width: 0;
      flex: 1;
      &:not(:last-child) {
        margin-right: 1rem;
      }
      .form-group {
        width: 100%;
      }
    }
    .form-group {
      width: 50%;
      flex: 1;
      min-width: 0;
      &.is-invalid {
        margin-bottom: 1.5rem;
        input {
          border: 1px solid #dc3545 !important;
        }
        ~ .invalid-feedback {
          display: block;
        }
      }
      &:not(:last-child) {
        margin-right: 1rem;
      }
      label {
        font-size: 0.875rem;
        color: rgba(41, 41, 52, 1);
      }
      .form-control {
        color: $color-dark;
        &:not(.is-invalid) {
          border-color: transparent;
        }
        &:focus,
        &:active {
          color: $color-dark;
        }
        &::placeholder {
          color: rgba(41, 41, 52, 0.25);
        }
      }
      .select__placeholder {
        color: rgba(41, 41, 52, 0.25);
      }
      .select__control {
        background-color: $color-background-gray;
        border-color: $color-background-gray;
        .select__multi-value {
          background-color: #15b2d1 !important;
          color: $color-white;
          border-radius: 5px;
          .select__multi-value__label {
            color: $color-white;
          }
        }
      }
      .input-group {
        .input-group-prepend {
          + input {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
          }
        }
      }
      .input-group-text {
        background-color: $color-background-gray;
        color: $text-inline-grayed;
        border: none;
      }
    }
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
    .file-upload {
      label {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-clip: padding-box;
        -webkit-transition: border-color 0.15s ease-in-out,
          box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        background: #f3f3f4;
        border: none;
        border-radius: 5px;
        font-size: 0.875rem;
        height: 40px;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0;
        }
      }
      input {
        display: none;
      }
    }
  }
  .expert-questions {
    .field-wrapper {
      padding: 0.5rem 1.5rem 0.5rem 0;
      &:not(:last-child) {
        margin-bottom: 1rem !important;
      }
      p,
      label {
        margin-bottom: 0;
      }
    }
  }

  // radio buttons
  .radio-button {
    display: none;
    + p {
      cursor: pointer;
      .radio-placeholder {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        border-radius: 100%;
        border: 2px solid $color-input-gray-dark;
        margin-right: 1.5rem;
      }
      .checkbox-placeholder {
        border-radius: 0;
      }
      &:hover {
        .radio-placeholder {
          border-color: $color-cerulean;
        }
      }
    }
    &:checked {
      + p {
        .radio-placeholder {
          border-color: $color-orange;
          &:before {
            content: " ";
            background-color: $color-orange;
            border: 4px solid #fff;
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }
        .checkbox-placeholder {
          border-color: $color-cerulean;
          &:before {
            background-color: $color-cerulean;
            border-radius: 0;
          }
        }
      }
    }
  }
  .radio-item {
    .info-icon {
      margin: 1px 0 0 1rem;
      svg {
        color: $color-cerulean;
        width: 16px;
        height: 16px;
      }
    }
  }
}

.upload-image {
  .avatar-placeholder {
    border: 1px solid rgba(41, 41, 52, 0.25);
  }
}

.divider {
  height: 1px;
  background-color: $color-input-gray-dark;
  width: 100%;
  + .field-wrapper {
    margin-top: 0.875rem;
  }
}

.login-form-inline-title {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin: 1.25rem 0 0.875rem;
  p {
    padding: 0 0.5rem;
    width: 100%;
    white-space: nowrap;
    margin: 0 0 2px;
    font-size: 0.875rem;
  }
  &:after,
  &:before {
    content: " ";
    height: 2px;
    background-color: $color-background-gray;
    width: 100%;
    margin: auto;
  }
}

.playbook-checkout {
  span.h5 {
    margin-bottom: 1.25rem;
  }
  .form-wrapper {
    .field-wrapper {
      margin-bottom: 0;
      label {
        font-weight: 700;
      }
    }
  }
  .definition-list {
    dd {
      font-size: 1rem;
    }
  }
  .payment-methods-wrapper {
    border-top: 1px solid $tab-inactive-border;
    padding: 2rem 0 0;
    margin: 0.875rem 0 2rem;
    .edit-btn,
    > .h5 {
      display: inline-flex !important;
    }
  }
}

.client-login-wrapper {
  width: 360px;
  margin: 0 auto;
}

.basic-login-form {
  .field-wrapper {
    .form-group {
      margin-bottom: 0.75rem;
    }
  }
}

.contrast-color-picker {
  display: flex;
  .contrast-color {
    border-radius: 5px;
    border: 1px solid $color-grey-dark;
    margin-bottom: 0;
    display: flex;
    padding: 0.5rem;
    align-items: center;
    span {
      font-weight: 600;
      font-size: 0.75rem;
    }
    .radio-button {
      + p {
        margin: 0;
        .radio-placeholder {
          margin-right: 0.5rem;
        }
      }
    }
    &:not(:last-child) {
      margin: 0 1rem 0 0;
    }
  }
}

.invalid-feedback {
  &.strong-password {
    display: flex !important;
    align-items: center;
    #password-tooltip {
      display: block;
      margin-left: 0.5rem;
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.MuiDrawer-paper {
  z-index: 1050 !important;
  width: 25% !important;
}

@media (max-width: 767px) {
  .MuiDrawer-paper {
    width: 100% !important;
  }
}
@media (min-width: 768px) and (max-width: 1300px) {
  .MuiDrawer-paper {
    width: 40% !important;
  }
}
@media (min-width: 1301px) {
  .MuiDrawer-paper {
    width: 30% !important;
  }
}
