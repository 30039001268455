.modal {
  .modal-content {
    border: none;
  }
  .modal-header {
    padding: 1rem 0;
  }
  .modal-header {
    justify-content: center;
    padding: 0 0 1rem;
    .close {
      padding: 0;
      margin: 0;
    }
  }
  .modal-body {
    padding: 1rem 0;
  }
  .modal-footer {
    padding: 1.5rem 1rem 0;
    justify-content: center;
  }
  .close {
    position: absolute;
    right: 14px;
    top: 14px;
    font-size: 30px;
    font-weight: 300;
    color: $color-black;
    opacity: 1;
    span {
      line-height: 1;
      display: block;
      width: 34px;
      height: 34px;
    }
    &:hover {
      opacity: 1;
    }
  }
  .datepicker {
    font-size: 0.875rem;
  }
  .modal-body-subtitle {
    border-bottom: 1px solid #dee2e6;
    padding: 0 0 1.5rem;
    margin-bottom: 1.5rem;
    p {
      margin-bottom: 0;
    }
  }
  .nl-form {
    position: relative;
    z-index: 2;
  }
}

.crop-modal-body,
.modal-content {
  padding: 1.75rem 1.5rem 1.5rem;
  border-radius: 0.75rem;
  background-color: $color-white;
  max-width: 510px;
  z-index: 2;
}

.crop-modal-body {
  margin: 0 1.5rem;
}

.success-modal-popup {
  &.modal-dialog {
    max-width: 570px;
    width: 100%;
  }
  .modal-content {
    max-width: none;
    min-height: 368px;
  }
}

.crop-modal {
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  .backdrop {
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.15);
  }
  .crop-modal-body {
    position: relative;
    padding: 3.5rem 6.25rem;
    .crop-modal-close {
      position: absolute;
      right: 14px;
      top: 14px;
      font-size: 25px;
      color: $color-black;
      opacity: 0.8;
      cursor: pointer;
      @include transition(all, 180ms, ease);
      &:hover {
        opacity: 1;
      }
    }
  }
}
.crop-modal,
.crop-modal .backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.help-modal {
  margin-top: 0.625rem;
  .text-link {
    svg {
      width: 18px;
      height: 18px;
      margin-right: 0.625rem;
    }
  }
}

.modal-header-wrapper {
  svg {
    width: 44px;
    height: 44px;
  }
}

//add task modal
.add-task-modal {
  .modal-content {
    min-width: 600px;
  }
}

// edit task modal
.edit-task-modal {
  min-width: 504px;
  @media (max-width: "630px") {
    min-width: 350px;
  }
  .modal-header {
    margin: -1.75rem -1.5rem 0;
    padding: 1.75rem 1.5rem 1.5rem;
    background: $color-background-gray;
    justify-content: center;
    .modal-title {
      display: flex;
      align-items: center;
      justify-content: center;
      h5 {
        margin: 0 0.75rem 0 0;
        font-weight: 700;
      }
      h4,
      h5 {
        text-align: center;
        margin: 0;
      }
      .task-actions {
        .btn {
          margin-top: -0.125rem;
          color: $color-black;
        }
      }
    }
  }
  &.login-modal {
    .modal-header {
      margin: 0;
    }
  }
  .modal-body {
    padding: 1.75rem 0.5rem 0;
    .sidebar-item {
      display: flex;
      justify-content: space-between;
      .field-wrapper {
        p {
          margin-bottom: 0.25rem;
        }
        span {
          width: auto;
        }
      }
    }
  }
  .field-wrapper {
    label {
      width: 100%;
    }
  }
  .dropdown-wrapper {
    width: 100%;
  }
  .form-wrapper {
    .grouped-fields {
      grid-template-columns: 60% 35%;
      .field-wrapper {
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  }
  .btn-gray {
    background-color: $color-background-gray;
  }
}

.kickoff-preview-modal {
  min-width: 820px;
  .modal-header {
    padding: 2.1875rem;
    background: $color-cerulean;
    color: $color-white;
    .close {
      color: $color-white;
    }
  }
  &.modal-dialog {
    max-width: 820px;
  }
  .modal-content {
    max-width: none;
  }
  .modal-body {
    padding: 0;
    margin: 0 -1.5rem -1.5rem;
  }
  .content {
    margin: 0;
  }
  .split-content-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    .split-content {
      flex-basis: 50%;
      margin: auto 0;
    }
    .content-wrapper {
      padding: 3.75rem 2.5rem 3.75rem 3.75rem;
    }
  }
}

.add-payment-options-modal {
  &.modal-dialog {
    max-width: 991px !important;
  }
}

.edit-billing-modal {
  &.modal-dialog {
    max-width: 585px;
    width: 100%;
    @media (max-width: "630px") {
      width: auto;
    }
  }
  .modal-content {
    max-width: 100%;
  }
  .modal-body {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .form-wrapper {
    .field-wrapper {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }
}

.interested-in-project-modal {
  .modal-header {
    background-color: $color-success;
    color: $color-white;
    padding: 2.75rem 1.5rem 2.5rem;
  }
  .form-wrapper {
    padding: 1rem 0 0;
    margin: 0 -0.5rem;
    border-top: 1px solid #dee2e6;
    text-align: left;
    .form-group {
      margin-bottom: 0;
    }
    textarea {
      min-height: 120px;
    }
  }
  .close {
    span {
      color: $color-white;
    }
  }
}

.edit-timeline-modal {
  .form-wrapper {
    .grouped-fields {
      grid-template-columns: 50% 50%;
    }
  }
}

// log hours

.log-hours-modal {
  min-width: 456px;
  .modal-header {
    .modal-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }
  .field-wrapper {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 300;
    margin: 0.25rem 0 0.75rem;
    span {
      margin: 0 0.5rem;
    }
    .hours-input {
      max-width: 70px;
      font-size: 0.875rem;
      background: rgb(243, 243, 244);
      border-radius: 5px;
    }
    .datepicker-wrapper {
      input {
        border: none;
      }
      span {
        margin: 0;
      }
    }
    input {
      border: 1px solid $color-cerulean;
    }
  }
  .modal-body {
    display: flex;
    justify-content: center;
  }
  .btn-wrapper {
    margin: 0.75rem 0 0;
    .btn {
      margin: 0 0.25rem;
      font-size: 0.875rem;
    }
  }
}

.modal {
  .expert-card-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.5rem;
    .expert-card {
      margin: 0 !important;
      .card {
        min-height: 370px !important;
      }
    }
  }
}

.active-alert-modal {
  .form-wrapper {
    .field-wrapper {
      justify-content: stretch;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
      .datepicker-wrapper {
        .react-datepicker-wrapper {
          width: 100%;
        }
      }
    }
  }
}

.expert-modal {
  min-width: 850px;
  @include tablet {
    min-width: 1px;
    width: 100%;
  }
  .modal-content {
    max-width: 100%;
    .modal-body {
      padding: 1.75rem 0.5rem;
      max-height: 245px;
      overflow: auto;
    }
  }
  .simple-expert-card-list {
    overflow-y: visible;
  }
}

.kickoff-modal {
  min-width: 850px;
  @include tablet {
    min-width: 1px;
    width: 100%;
  }
  .modal-content {
    max-width: 100%;
    .modal-body {
      padding: 1.75rem 0.5rem;
      max-height: 500px;
      overflow: auto;
    }
  }
  .simple-expert-card-list {
    overflow-y: visible;
  }
}

.login-modal {
  .modal-content {
    padding: 0;
  }
  .modal-header {
    background: #f3f3f5;
  }
  .okta-login-modal {
    background: #ffffff;
  }
  #okta-sign-in {
    margin: 40px auto 8px;
  }
  .modal-body {
    padding: 4.375rem;

    .subtitle {
      margin-top: -2.375rem;
      margin-bottom: 2rem;
    }
  }
  .signup-buttons {
    margin: 0;
  }
  .form-wrapper {
    .field-wrapper {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }
  .field-wrapper {
    .form-group {
      .accepted-terms {
        width: auto;
      }
    }
  }
  .accepted-terms {
    padding-bottom: 0;
  }
}

.edit-client-modal {
  .form-wrapper {
    .field-wrapper {
      .form-group {
        width: 100%;
        margin: 0;
      }
    }
  }
}

// Pay Invoice modal
.pay-invoice-modal {
  min-width: 570px;
  .modal-header {
    margin: -1.75rem -1.5rem 0;
    padding: 1.75rem 1.5rem 1.5rem;
    justify-content: center;
    text-align: center;
    border-bottom: 0px;
    .subtext {
      font-size: 1.1rem;
    }
  }
}
