.checkbox-wrapper {
  padding: 0.5rem;
  label {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0;
    font-size: 0.875rem;
    @include small-desktop {
      font-size: 0.75rem;
    }
    input {
      margin-top: 0;
      display: none;
    }
    .checkbox-placeholder {
      min-width: 20px;
      width: 20px;
      height: 20px;
      border: 2px solid rgba(0, 0, 0, 0.5);
      border-radius: 3px;
      margin: 0.25rem 0.5rem 0 0;
      &.active {
        border-color: #1290fb;
        background: #1290fb;
        &:after {
          content: " ";
          background: url("assets/img/icons/icon-check-white.svg") no-repeat
            center center;
          background-size: 18px;
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }

    .checkbox-placeholder-black {
      min-width: 20px;
      width: 20px;
      height: 20px;
      border: 2px solid rgba(0, 0, 0, 0.5);
      border-radius: 3px;
      margin: 0.25rem 0.5rem 0 0;
      &.active {
        border-color: #000000;
        background: #000000;
        &:after {
          content: " ";
          background: url("assets/img/icons/icon-check-white.svg") no-repeat
            center center;
          background-size: 18px;
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

// checkbox wrapper inside of playbook admin packages
.package-deliverables {
  .checkbox-wrapper {
    label {
      font-size: 1rem;
      font-weight: 700;
      .checkbox-placeholder {
        margin-top: 0;
        width: 24px;
        height: 24px;
        margin-right: 1.5rem;
        &.active {
          background-color: $color-cerulean;
        }
        &:after {
          background-size: 18px;
        }
      }
    }
  }
}
