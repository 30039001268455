/**
* Shows how you can use CSS to style your Element's container.
*/
.MyCardElement {
  background: rgb(243, 243, 244);
  border: none;
  border-radius: 5px;
  font-size: 14px;
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  margin-top: 15px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.MyCardElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.MyCardElement--invalid {
  border-color: #fa755a;
}

.MyCardElement--webkit-autofill {
  background-color: #fefde5 !important;
}
