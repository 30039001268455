.videocarousel-photos {
  .remove-icon {
    visibility: hidden;

    &:hover {
      color: #fff;
      background-color: red;
      border-radius: 54px;
    }
  }

  &:hover {
    .remove-icon {
      visibility: visible;
    }
  }
}
