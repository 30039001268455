.module-1 {
  font-size: 30px;
  color: gray;
}

.user-icon {
  height: 50px;
  width: 50px;
  background-color: #009dda;
}

.timing {
  font-size: 12px;
  position: relative;
  bottom: 15px;
  padding: 2px 20px !important;
  left: 8px;
  background-color: #c7c7c7 !important;
}

/* .cubesize {
  font-size: 22px;
} */
/* .blue-progress-bar {
  margin-top: 15px;
  width: 130px;
} */
.usericon {
  height: 48px;
  width: 50px;
  background-color: #009dda;
  color: white;
  border-radius: 50%;
  margin-left: 25px;
  margin-right: 10px;
  padding-left: 12px;
  padding-top: 8px;
}

.arrowicon {
  margin-left: 10px;
  color: gray;
}

.bluebar {
  height: 22px !important;
}

.pulseicon {
  border: 2px solid #87878c;
  color: #87878c;
  border-radius: 50%;
  padding: 10px;
  margin-left: 10px;
}

.table.account-mapping-custom th,
.table.account-mapping-custom td {
  border-bottom: 1px solid #dee2e6;
  background-color: none;
  vertical-align: middle;
}

.table.account-mapping-custom td {
  height: 100px;
}

.table.account-mapping-custom thead th {
  font-weight: normal;
}

.account-maaping-name {
  font-weight: bold;
}

.table-wrapper {
  position: relative;
  overflow-x: auto;
}

.account-mapping-partner-status-new {
  background-color: #dc7362;
  border-radius: 10px;
  color: white;
  font-size: 10px;
  padding: 5px 15px;
}

.account-mapping-partner-status-recent {
  background-color: #009dda;
  border-radius: 10px;
  color: white;
  font-size: 10px;
  padding: 5px 15px;
}

.container-full-width {
  position: relative;
  width: 100%;
  padding-left: 15px;
}

.container-full-width {
  position: relative;
}

/* styles.css */
.expensive-table {
  border-collapse: collapse;
  width: 100%;
  padding-left: 15px;
  border: 2px solid white;
}

.expensive-table th,
.expensive-table td {
  padding: 8px;
  text-align: left;
  background-color: white;
}

.expensive-table th {
  background-color: black;
  /* Reddish background for header */
  color: white;
  border: 2px solid white;
}

.expensive-table tr:nth-child(even) {
  background-color: #f2f2f2;
  /* Alternate row background color */
}

.expensive-table tr:hover {
  background-color: #ffcccb;
  /* Hover background color */
}
.collapsible-content {
  display: none;
  padding: 10px;
}

.sideBar-width {
  width: max-content;
}
#datePickerWidth .react-datepicker-wrapper {
  width: 100%;
}

.tabs-and-preview #userActivity .tab-content {
  max-width: 100% !important;
  width: 100%;
  margin-right: 2rem;
}
