.team-members {
  display: flex;
  flex-direction: column;
  .member {
    display: flex;
    justify-content: stretch;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  .avatar {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    overflow: hidden;
    min-width: 48px;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-cerulean;
    color: $color-white;
    path {
      fill: currentColor;
    }
  }
  p {
    margin-bottom: 0;
    white-space: normal;
    overflow: hidden;
    padding-right: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    &:hover {
      span {
        background-color: $color-white;
        position: relative;
      }
    }
    span {
      margin-left: 1rem;
      position: relative;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    .btn {
      margin-left: 0.5rem;
    }
  }
  .dropdown-toggle {
    color: rgba(41, 41, 52, 0.5);
  }
}
