.preview-wrapper {
  .card-footer {
    min-height: 40px;
  }
  .preview-full {
    svg {
      margin-right: 1.25rem;
      width: 20px;
      height: 20px;
    }
  }
}

.expert-list-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  .expert-card {
    border: 2px solid transparent !important;
    margin: 0.375rem !important;
    &.selected {
      border: 2px solid $color-success !important;
    }
  }
}
.left-align {
  margin-right: 0;
  margin-left: auto;
  min-width: 64px;
}
