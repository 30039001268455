.rating-wrapper,
.usage-wrapper {
  svg {
    margin-right: 4px;
    margin-top: 1px;
    width: 16px;
    height: 16px;
    &.filled {
      color: #df674c;
      fill: currentColor;
      path {
        fill: currentColor;
      }
    }
  }
  .h7 {
    line-height: 1;
    margin-top: 4px;
  }
}
.rating-wrapper {
  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.rating-and-usage {
  margin: 0.625rem 0 0;
}

// user-ratings
.user-ratings {
  margin: 1.5rem 0 0;
  .user-rating {
    .media-left {
      width: 2rem;
      height: 2rem;
      overflow: hidden;
      border-radius: 100%;
      margin-right: 0.5rem;
      img {
        max-width: none;
        height: 100%;
      }
    }
    .rating-wrapper {
      span {
        margin-right: 0.5rem;
      }
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
  .load-more {
    font-size: 0.875rem;
  }
}

// user discussion
.discussion-list {
  .rating-and-usage {
    margin: 0;
  }
  .media-body {
    p {
      margin-bottom: 0.5rem;
    }
  }
  .user-rating {
    margin-bottom: 0.5rem;
    .media-left {
      margin-right: 0.875em;
    }
    .rating-wrapper {
      justify-content: space-between;
      align-items: flex-end !important;
      margin-bottom: 0.5rem;
      span {
        line-height: 0.75rem;
      }
      .discussion-actions {
        a {
          font-size: 0.625rem;
          line-height: 0.75rem;
        }
      }
    }
  }
}

.user-ratings {
  .user-rating {
    .file-preview {
      align-items: stretch;
      .media-left {
        width: 54px;
        height: 56px;
        border-radius: 0;
        margin-right: 0;
      }
      p {
        margin-bottom: 0;
      }
      .media-body {
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: center !important;
      }
    }
  }
}

.file-preview {
  overflow: hidden;
  background: $color-background-gray;
  border-radius: 3px;
  margin: 1rem 4rem 1rem 0;
  .media-left {
    width: 110px;
    height: 56px;
    border-radius: 0;
    margin-right: 0;
  }
  p {
    margin-bottom: 0;
  }
  .card__corner {
    position: absolute;
    top: 0;
    right: 0;
    width: 1rem;
    height: 1rem;
    background-color: #bfbfc3;
    border-radius: 3px;
  }
  .card__corner-triangle {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 1rem 1rem 0;
    border-color: transparent #fff transparent transparent;
  }
  p,
  a {
    line-height: 1rem;
  }
}
