// images to act responsively.
img {
  display: block;
  max-width: 100%;
}

// other base stlyes
.image-center {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.desktop-only {
  @media (max-width: 992px) {
    display: none;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.p1 {
  font-size: 20px;
}

.p2 {
  font-size: 18px;
}

.font-size {
  @media (min-width: 768px) {
    font-size: 9px;
  }

  @media (min-width: 992px) {
    font-size: 10px;
  }

  @media (min-width: 1200px) {
    font-size: 10px;
  }

  @media (min-width: 1400px) {
    font-size: 12px;
  }

  @media (min-width: 1800px) {
    font-size: 15px;
  }
}

.color-white {
  color: $color-white;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

// btn
.btn {
  &.btn-link {
    &.text-underline {
      text-decoration: underline;
    }
  }

  &.btn-outline-grayed {
    border: $button-outline-grayed-border;
    color: $button-outline-grayed-color;
  }
}

.text-underline {
  text-decoration: underline;
}

.text-link {
  color: $color-cerulean;
}

.text-grayed {
  opacity: 0.1;
}

.text-inline-grayed {
  color: $text-inline-grayed;
}

.text-gray-dark {
  color: $color-grey-darker;
}

.text-orange {
  color: $color-orange;
}

.wrap-text {
  white-space: normal !important;
}

.text-truncate {
  max-width: 100%;
}

// boostrap default overwrites
.h8 {
  font-size: 0.625rem;
}

.h7 {
  font-size: 0.75rem;
}

.h7-i {
  font-size: 0.75rem !important;
}

.h6-5 {
  font-size: 0.875rem;
}

.h5-5 {
  font-size: 1.3125rem;
  font-weight: 500;

  strong {
    font-weight: 700;
  }
}

.h6-5-important {
  font-size: 0.875rem !important;
}

.h1-5 {
  font-size: 2.25rem;
}

.lh-2 {
  line-height: 2rem !important;
}

// margins extensions

.mb-4-5 {
  margin-bottom: 2.5rem !important;
}

.p-3-5 {
  padding: 1.25rem !important;
}
// badges extension

.badge-outline {
  background-color: $color-white !important;
  font-weight: 700 !important;

  &.badge-dark {
    border: $badge-dark-border;
    color: $color-dark;
  }
}

// replicating badge-group as input-group or button-group below
.badges {
  .input-group {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    > .input-group-prepend {
      > .input-group-text {
        padding: 0.25rem 0.5rem;

        svg {
          width: 1rem;
        }
      }
    }
  }
}

.input-group {
  > .badge {
    align-items: center;
    display: flex;
    flex: 1 1 0%;
    justify-content: flex-start;
    margin: 0;
    min-width: 0;
    position: relative;

    &:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  &.group-dark {
    .input-group-text {
      background-color: $color-dark;
      border-color: $input-group-text-border;
      color: $color-white;

      svg {
        color: currentColor;
      }
    }
  }
}

// background and color mixes
.blue-faded {
  background: $color-cerulean-faded;
  color: $color-cerulean;
}

.red-faded {
  background: $color-notification-orange-faded;
  color: $color-notification-orange;
}

.green-faded {
  background: $color-success-faded;
  color: $color-success;
}

.bg-white-ivory {
  background-color: $color-desert;
}

// content alignement overrides
.justify-content-stretch {
  justify-content: stretch !important;
}

// lists

.definition-list {
  dd {
    font-size: 0.875rem;
    margin: 0;
  }
}
/* Common styles for all devices */

.filter-label {
  background-color: white;
  font-size: 12px;
  padding: 6px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.filter-values {
  display: flex;
  flex-wrap: wrap;
}

.filter-value {
  margin-right: 5px;
  margin-bottom: 5px;
}

.badges {
  display: flex;
  background-color: black;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 0.25em 0.4em;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-right: 2px !important;
}

.remove-icon {
  cursor: pointer;
}

/* Media query for small devices */
@media (max-width: 767px) {
  .filter-value {
    margin-right: 0;
    margin-bottom: 5px;
  }

  .badges {
    display: block;
    text-align: center;
    margin-right: 0;
    color: white;
    font-size: 14px;
    margin-right: 2px !important;
  }
  .mobile-filter-container {
    display: flex;
  }
  .filter-label {
    display: inline-block;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-transform: capitalize;
  }
}

/* Media query for medium and large devices */
@media (min-width: 768px) {
  .mobile-filter-container {
    display: flex;
  }
  .filter-label {
    background-color: white;
    font-size: 14px;
    padding: 6px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.filter-label {
  display: inline-block;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: capitalize;
}

.dropdown-menu.show {
  display: block;
}

.bg-light-grey {
  background-color: #e7e7ea;
}
.intentTag .form-group {
  margin-bottom: 0px !important;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.custom-backdrop {
  opacity: 0.1 !important;
}
#slider-container .slick-next {
  right: 35px !important;
  z-index: 1;
}

#slider-container .slick-prev {
  left: 20px !important;
  z-index: 1;
}

#slider-container .slick-prev:before,
#slider-container .slick-next:before {
  font-size: 35px !important;
  color: darkgray !important;
}

.color-dark-grey {
  color: darkgray;
}
