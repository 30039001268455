.salesforce-playbooks {
  .section-content {
    margin: 0 -15px;
  }
  .playbook-wrapper {
    width: 100%;
    div[class*="col"],
    a[class*="col"] {
      padding: 0;
    }
  }
  .playbook-card-link {
    display: block;
  }
  .card {
    display: flex;
    flex-direction: row;
  }
  .lazyload-wrapper {
    max-width: 265px;
    width: 100%;
  }
  .card-header {
    max-width: 265px;
    width: 100%;
    height: 258px;
    background-position: center center;
  }
  .body-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    .card-body {
      padding: 1.875rem;
      p {
        margin-bottom: 0.25rem;
        &.card-title {
          line-height: 1.5rem;
        }
      }
    }
    .card-footer {
      margin-top: auto;
      padding: 0.5rem 2rem;
    }
  }
  .card-footer {
    display: flex;
    justify-content: stretch;
    p {
      margin: 0;
    }
    a {
      margin-left: auto;
      display: flex;
      text-decoration: none;
      color: $color-cerulean;
      font-weight: 600;
      svg {
        stroke: $color-black;
      }
    }
  }
  .playbook-description {
    line-height: 1.125rem;
    font-weight: 300;
  }
  @include small-desktop {
    .card-header {
      height: auto;
    }
  }
  @include tablet {
    .playbook-wrapper {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      div[class*="col"],
      a[class*="col"] {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    .card {
      flex-direction: column;
    }
    .card-header {
      min-height: 222px;
    }
    .body-wrapper {
      .card-body {
        padding: 1.25rem;
      }
      .card-footer {
        padding: 0.5rem 1.25rem;
        align-items: center;
        a,
        p {
          font-size: 0.75rem;
        }
        a {
          align-items: center;
        }
        svg {
          margin-left: 0.125rem;
          width: 1rem;
          height: 1rem;
        }
      }
    }
    .landing-page-sidebar {
      .sidebar-cta {
        padding: 1.125rem;
        .btn {
          padding: 0.5rem 0.25rem;
          font-size: 0.75rem;
        }
      }
    }
  }
  @include mobile-xs {
    .section-content {
      margin: 0;
    }
    .card-header {
      max-width: none;
    }
    .landing-page-sidebar {
      flex-wrap: wrap;
      padding: 0;
      .sidebar-cta {
        flex: 0 0 50%;
        min-height: 1px;
        width: 50%;
        margin: 0;
      }
    }
  }
  @include mobile-xxs {
    .landing-page-sidebar {
      flex-direction: row;
      .sidebar-cta {
        width: calc(100% - 30px);
        flex: 0 0 calc(100% - 30px);
        flex-wrap: wrap;
        margin: 1.5rem 15px 0;
        min-height: 330px;
        .btn {
          font-size: 1rem;
        }
      }
    }
  }
}
.landing-page-sidebar {
  display: flex;
  flex-direction: column;
  .sidebar-cta {
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    min-height: 280px;
    color: $color-white;
    align-items: center;
    margin: 0 0 1.5rem;
    background-image: url("assets/header-triangles.svg");
    .content-wrapper {
      margin: auto;
      text-align: center;
      svg {
        width: 40px;
        height: auto;
        stroke: $color-white;
        margin: 0 auto;
      }
      span {
        display: block;
        margin: 0.675rem 0 0;
      }
    }
    .btn-wrapper {
      margin-bottom: 0;
    }
    &.purple {
      background-color: #9aa4e4;
      background-position: top left;
    }
    &.green {
      background-color: $color-success;
      background-position: top right;
    }
    &.blue {
      background-color: #01a1e1;
      background-position: center center;
    }
    &.pale-blue {
      background-color: #69bada;
      background-position: bottom right;
    }
    .btn-wrapper {
      width: 100%;
    }
    .btn {
      font-size: 1.25rem;
      width: 100%;
      border-width: 2px;
    }
  }
  @include small-desktop {
    .sidebar-cta {
      min-height: 258px;
      .btn {
        font-size: 0.875rem;
      }
    }
  }
}
