.module-1 {
  font-size: 30px;
  color: gray;
}

.user-icon {
  height: 50px;
  width: 50px;
  background-color: #009dda;
}

.timing {
  font-size: 12px;
  position: relative;
  bottom: 15px;
  padding: 2px 20px !important;
  left: 8px;
  background-color: #c7c7c7 !important;
}

/* .cubesize {
  font-size: 22px;
} */
/* .blue-progress-bar {
  margin-top: 15px;
  width: 130px;
} */
.usericon {
  height: 48px;
  width: 50px;
  background-color: #009dda;
  color: white;
  border-radius: 50%;
  margin-left: 25px;
  margin-right: 10px;
  padding-left: 12px;
  padding-top: 8px;
}

.arrowicon {
  margin-left: 10px;
  color: gray;
}

.bluebar {
  height: 22px !important;
}

.pulseicon {
  border: 2px solid #87878c;
  color: #87878c;
  border-radius: 50%;
  padding: 10px;
  margin-left: 10px;
}

.table.account-mapping-custom th,
.table.account-mapping-custom td {
  border-bottom: 1px solid #dee2e6;
  background-color: none;
  vertical-align: middle;
}

.table.account-mapping-custom td {
  height: 100px;
}

.table.account-mapping-custom thead th {
  font-weight: normal;
}

.account-maaping-name {
  font-weight: bold;
}

.table-wrapper {
  position: relative;
  overflow-x: auto;
}

.account-mapping-partner-status-new {
  background-color: #dc7362;
  border-radius: 10px;
  color: white;
  font-size: 10px;
  padding: 5px 15px;
}

.account-mapping-partner-status-recent {
  background-color: #009dda;
  border-radius: 10px;
  color: white;
  font-size: 10px;
  padding: 5px 15px;
}

.container-full-width {
  position: relative;
  width: 100%;
  padding-left: 15px;
}

.container-full-width {
  position: relative;
}

/* styles.css */
.expensive-table {
  border-collapse: collapse;
  width: 100%;
  padding-left: 15px;
  border: 2px solid white;
}

.expensive-table th,
.expensive-table td {
  padding: 8px;
  text-align: left;
  background-color: white;
}

.expensive-table th {
  background-color: black;
  /* Reddish background for header */
  color: white;
  border: 2px solid white;
}

.expensive-table tr:nth-child(even) {
  background-color: #f2f2f2;
  /* Alternate row background color */
}

.expensive-table tr:hover {
  background-color: #ffcccb;
  /* Hover background color */
}
.collapsible-content {
  display: none;
  padding: 10px;
}

.sideBar-width {
  width: -webkit-max-content;
  width: max-content;
}
#datePickerWidth .react-datepicker-wrapper {
  width: 100%;
}

.tabs-and-preview #userActivity .tab-content {
  max-width: 100% !important;
  width: 100%;
  margin-right: 2rem;
}

/* If you want to override variables do it here */

body {
  font-family: "Muli", sans-serif !important;
  font-size: 16px; }

.loggedin-layout {
  background-color: #f4f4f6; }

.app-body {
  min-height: 94vh; }

@media (max-width: 991px) {
  .app-body {
    margin-top: 0; } }

.btn-primary {
  background: #bd9732;
  border: 1px solid #bd9732;
  color: #fff; }
  .btn-primary:hover {
    background: #61666c;
    border: 1px solid #61666c;
    color: #fff; }

button.btn,
a.btn {
  border-radius: 0.4375rem; }

.btn.outline-bold {
  border-width: 2px; }
  .btn.outline-bold:hover {
    background: 1px solid #61666c;
    text-decoration: none; }

.btn.btn-secondary {
  background-color: #009dda;
  border: 0;
  color: #fff; }
  .btn.btn-secondary:hover {
    background-color: #037eae;
    border: 0; }
  .btn.btn-secondary:focus {
    background-color: #009dda;
    border: 0;
    color: #fff; }
  .btn.btn-secondary.focus {
    background-color: #009dda;
    border: 0;
    color: #fff; }
  .btn.btn-secondary.disabled {
    background-color: #009dda;
    border: 0;
    color: #fff; }
  .btn.btn-secondary:disabled {
    background-color: #009dda;
    border: 0;
    color: #fff; }

.btn .plain-btn {
  border-color: #fff;
  font-size: 14px;
  padding: 5px 15px !important; }

.btn.plain-btn:hover {
  background-color: #fff;
  color: #000 !important; }

.astric {
  color: #f00; }

.header--content {
  padding-bottom: 200px;
  padding-top: 100px; }

.availability-update-form label {
  font-size: 18px; }

@media screen and (min-width: 768px) {
  .expert-availability-form-content {
    margin: auto;
    width: 55%; } }

.radio-btn-label,
.checkbox-btn-label {
  font-size: 16px;
  font-weight: 500; }

.checkbox .checkbox-inline .form-check-input {
  margin-top: 6px; }

.TextAlignment {
  margin-left: -3%; }

.gradient-blue {
  background-image: linear-gradient(-180deg, #009dda 0%, #006dda 100%); }

.find-an-expert-left-side-section-background-image {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 -15px;
  padding: 0 15px;
  position: relative; }

.main-container {
  background: #33333f;
  float: left;
  margin-top: -50px;
  width: 100%; }
  .main-container .availability-update-section {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 420px;
    position: relative; }
    @media screen and (min-width: 767px) {
      .main-container .availability-update-section {
        margin-bottom: 20px; } }
    @media screen and (min-width: 320px) and (max-width: 767px) {
      .main-container .availability-update-section {
        height: 280px; } }
  @media screen and (min-width: 767px) {
    .main-container .availability-update-header-section {
      -webkit-transform: translate(-50%, -50%);
      left: 50%;
      margin-top: 17%;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%); } }
  @media screen and (min-width: 320px) and (max-width: 400px) {
    .main-container .availability-update-header-section h1 {
      font-size: 27px; } }
  @media only screen and (min-width: 1500px) {
    .main-container .availability-update-header-section {
      margin-top: 13%; } }
  @media only screen and (min-width: 1305px) and (max-width: 1500px) {
    .main-container .availability-update-header-section {
      margin-top: 15%; } }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    .main-container .availability-update-header-section {
      margin-top: 10%; } }
  @media screen and (min-width: 991px) and (max-width: 1200px) {
    .main-container .availability-update-header-section {
      margin-top: 20%; } }
  @media screen and (min-width: 768px) and (max-width: 990px) {
    .main-container .availability-update-header-section {
      margin-top: 25%; } }
  .main-container .availability-update-header-section .header-section {
    font-size: 22px; }
    @media screen and (min-width: 320px) and (max-width: 500px) {
      .main-container .availability-update-header-section .header-section {
        font-size: 14px; } }
    @media screen and (min-width: 500px) and (max-width: 767px) {
      .main-container .availability-update-header-section .header-section {
        font-size: 16px; } }
    @media screen and (min-width: 768px) and (max-width: 990px) {
      .main-container .availability-update-header-section .header-section {
        font-size: 19px; } }

.footer {
  background-color: #000;
  bottom: 0;
  color: #fff;
  font-size: 14px;
  left: 0;
  text-align: center;
  width: 100%; }

.google-login {
  background: #298dfa !important;
  border-radius: 8px !important;
  color: #fff !important;
  font-size: 20px !important;
  justify-content: center;
  min-height: 46px;
  position: relative;
  width: 100%; }
  .google-login > div {
    background: #298dfa !important;
    position: relative; }
    .google-login > div svg g path:not(:last-child) {
      fill: #fff !important; }

.linkedin-login {
  border-radius: 8px;
  padding: 0; }

.resigter-with-my-email {
  background: none;
  border: 0;
  color: #458bd6 !important;
  display: contents;
  font-family: Muli;
  font-size: 14px;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  width: 206px; }

.resigter-with-my-email:hover {
  color: #000 !important; }

.office365-login {
  min-height: 46px;
  position: relative;
  width: 100%; }
  .office365-login:hover {
    opacity: 0.9; }
  .office365-login svg path:not(:last-child) {
    fill: #fff !important; }
  .office365-login > div {
    background: transparent;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1; }
  .office365-login > span {
    background-color: #ea6953;
    border-radius: 4px;
    bottom: 0;
    color: #fff;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    left: 0;
    line-height: 46px;
    padding-left: 50px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 0; }
    .office365-login > span > span {
      background-color: rgba(51, 51, 51, 0.16);
      bottom: 6px;
      display: inline-block;
      left: 50px;
      position: absolute;
      top: 6px;
      width: 2px; }
    .office365-login > span img {
      left: 11px;
      position: absolute;
      top: 7px;
      width: 28px; }

.separator {
  border-bottom: 1px solid #ccc;
  margin: 30px 0;
  position: relative;
  width: 100%; }
  .separator::before {
    background-color: #fff;
    content: "OR";
    left: 50%;
    margin-left: -20px;
    padding: 0 10px;
    position: absolute;
    top: -10.5px; }

.vertical-center {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%); }

.vertical-top {
  vertical-align: top !important; }

.login-container {
  color: #292934; }
  .login-container .accepted-terms span {
    color: #292934; }

.btn-login {
  background: #df674c !important;
  color: #fff !important;
  font-size: 20px !important;
  font-weight: bold !important;
  min-height: 46px; }
  .btn-login:hover {
    background: #ff876c !important; }

.dropdown-item {
  cursor: pointer; }

.dropdown-menu {
  margin-top: 7px !important;
  padding: 0 !important;
  width: 100%; }

.dropdown-toggle:hover {
  text-decoration: none; }

.dropdown-toggle::after {
  color: #fff; }

.nav-item {
  list-style: none; }

.logout-sidebar-button {
  bottom: 118px;
  display: inline-block !important;
  position: absolute;
  width: 100%; }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .logout-sidebar-button {
      bottom: 128px; } }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    .logout-sidebar-button {
      bottom: 154px; } }

.page-not-found {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%); }

@media screen and (min-width: 320px) and (max-width: 767px) {
  .page-not-found-404 {
    font-size: 3.5rem; } }

.error-message {
  color: #dc3545;
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: -20px; }

.bg-color-black {
  background-color: #33333f; }

.right-side-content {
  margin-bottom: 50px; }

.assign-partner-checkbox {
  float: left;
  padding-bottom: 15px; }
  .assign-partner-checkbox span {
    color: #23282c;
    float: left;
    margin-top: -3px;
    padding-left: 20px; }
  .assign-partner-checkbox input[type="checkbox"] {
    cursor: pointer;
    float: left;
    position: absolute; }
    .assign-partner-checkbox input[type="checkbox"]::before {
      background-color: #fff;
      border: 2px solid #23282c;
      border-radius: 3px;
      content: "";
      display: block;
      height: 16px;
      left: 0;
      position: absolute;
      top: 0;
      width: 16px; }
    .assign-partner-checkbox input[type="checkbox"]:checked::after {
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      border: solid #000;
      border-width: 0 2px 2px 0;
      content: "";
      display: block;
      height: 10px;
      left: 6px;
      position: absolute;
      top: 2px;
      transform: rotate(45deg);
      width: 5px; }
  .assign-partner-checkbox .invalid-feedback {
    display: block;
    margin-top: 20px; }

.accepted-terms {
  float: left;
  padding-bottom: 15px; }
  .accepted-terms span {
    color: #23282c;
    float: left;
    font-size: 14px;
    padding-left: 25px; }
  .accepted-terms input[type="checkbox"] {
    cursor: pointer !important;
    float: left !important;
    position: absolute !important;
    -webkit-cursor: pointer !important;
    -webkit-float: left !important;
    -webkit-position: absolute !important; }
    .accepted-terms input[type="checkbox"]::before {
      background-color: #fff;
      border: 2px solid #23282c;
      border-radius: 3px;
      content: "";
      display: block;
      height: 16px;
      left: 0;
      position: absolute;
      top: 0;
      width: 16px; }
    .accepted-terms input[type="checkbox"]:checked::after {
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      border: solid #000;
      border-width: 0 2px 2px 0;
      content: "";
      display: block;
      height: 10px;
      left: 6px;
      position: absolute;
      top: 2px;
      transform: rotate(45deg);
      width: 5px; }
  .accepted-terms .invalid-feedback {
    display: block;
    margin-top: 20px; }

.select__multi-value {
  background-color: #fff !important; }

.profile-picture-preview {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: 110px;
  object-fit: cover;
  width: 110px; }

.term-and-condition {
  float: left;
  font-size: 14px;
  padding-left: 5px;
  text-decoration: none; }

.btn.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #009dda;
  border: 0;
  color: #fff; }

.btn.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: #fff; }

.fa.fa-plus {
  font-size: 12px;
  padding-left: 0;
  padding-right: 6px; }

.table-filter a {
  color: #61666c; }
  .table-filter a:hover {
    color: #000;
    text-decoration: none; }

.table-filter .active {
  border-bottom: 2px solid #20a8d8;
  color: #000; }
  .table-filter .active a {
    color: #000; }

@media (max-width: 765px) {
  .count-card {
    margin-top: 10px; } }

.collection-playbook .playbook-field .margin-top-30 {
  margin-top: 30px; }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    .collection-playbook .playbook-field .margin-top-30 {
      margin-top: 0; } }

.project-list-card .small {
  font-size: 12px;
  opacity: 0.5; }

.project-list-card .status-header {
  font-size: 12px;
  font-weight: bold; }
  .project-list-card .status-header .notification {
    background-color: #f00; }

.approve-btn-hover:hover {
  background-color: #008000 !important;
  color: #fff !important; }

.edit-icon-hover:hover {
  background-color: #000 !important; }

.reject-btn-hover:hover {
  background-color: #f00 !important;
  color: #fff !important; }

.landing-page-status-active {
  background-color: #59c497; }

.landing-page-status-draft {
  background-color: #f8c017; }

.landing-create-popup .modal-content {
  border-radius: 15px; }

.landing-group-dropdown .more-dropdown .btn.btn-secondary svg {
  fill: #c8c8cb !important; }

.landing-group-dropdown .show {
  width: auto; }

.redux-table .table-responsive {
  overflow-x: auto; }

.redux-table-responsive .table-responsive {
  overflow-x: scroll !important; }

.landing-page-status-dropdown {
  background-color: #fff;
  border: 0;
  padding: 5px; }
  @media screen and (min-width: 767px) {
    .landing-page-status-dropdown {
      width: 150px; } }

.select-style {
  background: #e7e7ea;
  border: #e7e7ea;
  outline: none;
  width: 187px; }

.default-input {
  background: #f3f3f4 !important;
  border: 0 !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  height: 40px !important; }

.banner-image-upload-link {
  color: #009dda;
  cursor: pointer;
  text-decoration: underline; }

.Toastify__toast-container--top-right {
  top: 2.8em !important; }
  @media only screen and (min-width: 1200px) {
    .Toastify__toast-container--top-right {
      right: calc(50vw - 35rem) !important; } }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .Toastify__toast-container--top-right {
      right: calc(50vw - 29.4rem) !important; } }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .Toastify__toast-container--top-right {
      right: 2em !important; } }
  @media screen and (min-width: 481px) and (max-width: 767px) {
    .Toastify__toast-container--top-right {
      right: 2.1em !important; } }
  @media screen and (min-width: 320px) and (max-width: 480px) {
    .Toastify__toast-container--top-right {
      top: 2.8em !important; } }

.inline-edit-section {
  margin-bottom: 2.75rem; }
  .inline-edit-section .landing-page-name {
    font-size: 2.5rem;
    height: 55px;
    width: -webkit-fill-available; }
  .inline-edit-section .landing-page-edit {
    background-color: #f0f0f0;
    border: 1px solid #aaa;
    margin-top: -1px;
    padding: 5px;
    position: absolute;
    right: 15px;
    z-index: 2; }
    .inline-edit-section .landing-page-edit i {
      border: 1px solid #aaa;
      cursor: pointer;
      height: 30px;
      line-height: 28px;
      text-align: center;
      width: 30px; }
  .inline-edit-section .landing-page-vanity-url {
    width: -webkit-fill-available; }

.active-playbooks {
  background-color: #0ac297;
  border: 3px;
  border-radius: 27px; }

.inactive-playbooks {
  background-color: transparent;
  border: 3px;
  border-radius: 27px; }

.playbook-list-section {
  margin-left: -30px !important;
  margin-right: -30px !important; }

.modal {
  padding: 0 !important;
  text-align: center; }
  .modal::before {
    content: "";
    display: inline-block;
    height: 100%;
    margin-right: -4px;
    vertical-align: middle; }

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle; }

.success-modal-popup .modal-content {
  border-radius: 0;
  max-width: 100% !important; }

.multiline-ellipsis {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden; }

.tabs-and-preview .selected-experts {
  max-width: none !important; }

.remove-expert {
  color: #fff;
  font-size: 50px;
  position: absolute;
  right: 20px;
  top: 0.75rem; }
  .remove-expert:hover {
    background-color: #f00;
    border-radius: 27px; }

.remove-photo {
  color: #000;
  font-size: 32px;
  position: relative;
  left: 70px;
  bottom: 111px; }
  .remove-photo:hover {
    background-color: #f00;
    border-radius: 27px; }

.imagecarousel-photos .remove-icon {
  visibility: hidden; }
  .imagecarousel-photos .remove-icon:hover {
    color: #fff;
    background-color: red;
    border-radius: 54px; }

.imagecarousel-photos:hover .remove-icon {
  visibility: visible; }

.slick-prev:before,
.slick-next:before {
  left: 1px !important;
  color: black !important; }

.source-more {
  color: #009dda; }
  .source-more .feather-trash-2:hover {
    color: #00668e;
    -webkit-text-decoration: underline solid;
            text-decoration: underline solid; }
  .source-more:hover {
    color: #00668e;
    -webkit-text-decoration: underline solid;
            text-decoration: underline solid; }

.discussion-editor-edit {
  width: 100%; }
  .discussion-editor-edit .editor {
    background: #f3f3f4;
    border: 1px solid #ddd;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: text;
    margin-bottom: 2em;
    padding: 16px; }
  .discussion-editor-edit .editor .public-DraftEditor-content {
    max-height: 500px;
    min-height: 40px; }
  .discussion-editor-edit .rich-text-editor {
    background: #f3f3f4;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-sizing: border-box;
    cursor: text;
    margin-bottom: 2em;
    padding: 16px;
    padding-top: 31px;
    padding-bottom: 31px;
    z-index: 0;
    max-height: 544px;
    min-height: 12rem;
    position: relative; }
  .discussion-editor-edit .rich-text-editor .public-DraftEditor-content {
    max-height: 500px;
    min-height: 10rem;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: -1; }
  .discussion-editor-edit .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    height: 8rem;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    scrollbar-width: none;
    z-index: -1; }
  .discussion-editor-edit .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root::-webkit-scrollbar {
    display: none; }
  .discussion-editor-edit .options {
    margin-bottom: 20px; }

.link-hover:hover {
  text-decoration: underline !important; }

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.supporting-material-preview {
  border: 2px solid #9a9a9a;
  border-radius: 0 0 5px 5px;
  border-top: 0;
  margin-top: -4px;
  padding: 15px; }

.file-preview-button-section .delete-preview {
  color: #009dda; }
  .file-preview-button-section .delete-preview:hover {
    color: #00668e;
    text-decoration: underline; }

.credit-card-error {
  color: #df674c;
  font-size: 0.75rem;
  height: 1px; }

.invalid-feedback {
  display: block !important;
  margin-top: 20px; }

.remove-link {
  color: #f61a1e !important;
  cursor: pointer;
  font-family: Muli;
  font-size: 14px;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: 16px;
  width: 53px; }

.button-link {
  background: none;
  border: 0;
  color: #009dda;
  font-family: Muli;
  font-size: 14px;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 0;
  width: 38px; }

.button-link:focus {
  outline: none; }

/* Draft Js - Mention */
.mention-suggestions-entry-container {
  padding: 5px;
  width: 100%; }

.mention-suggestions-entry-container:hover {
  cursor: pointer; }

.mention-suggestions-entry-container-left,
.mention-suggestions-entry-container-right {
  display: table-cell;
  vertical-align: middle; }

.mention-suggestions-entry-container-right {
  padding-left: 8px;
  width: 100%; }

.mention-suggestions-entry {
  padding: 7px 10px 3px;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56); }

.mention-suggestions-entry:active {
  background-color: #cce7ff; }

.mention-suggestions-entry-focused {
  background-color: #e6f3ff; }

.mention-suggestions-entry-text,
.mention-suggestions-entry-title {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.mention-suggestions-entry-title {
  color: #a7a7a7;
  font-size: 80%; }

.mention-suggestions-entry-avatar {
  border-radius: 50%;
  display: block;
  height: 30px;
  width: 30px; }

.mention-suggestions-entry-email {
  font-size: 14px;
  font-weight: normal;
  padding-left: 10px; }

/* Draft Js - Mention END */
.disabled {
  opacity: 0.4;
  pointer-events: none; }

.Toastify__toast--success {
  background-color: #0ac297 !important; }

.Toastify__toast-container--top-right {
  top: 44px !important; }

.applied-coupon-code {
  align-items: center;
  background-color: #59c497 !important;
  border-radius: 28px;
  box-sizing: border-box;
  color: #fff;
  display: inline-flex;
  font-size: 18px;
  padding: 6px 10px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap; }

#declined-message-tooltip svg {
  height: 20px;
  width: 16px; }

hr {
  border: 0;
  border-top: 1px solid #ccc;
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0; }

.partner-profile-location p {
  font-weight: bold; }
  .partner-profile-location p svg {
    display: inline; }

.elite-partner p {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px; }
  .elite-partner p svg {
    display: inline; }

.embedded-partners-directory {
  background: #fff;
  min-height: 100vh; }

@media only screen and (min-width: 1200px) {
  .deal-status-line {
    width: 80px; } }

@media screen and (min-width: 320px) and (max-width: 767px) {
  .deal-status-line {
    width: 80px; } }

@media screen and (min-width: 991px) and (max-width: 1200px) {
  .deal-status-line {
    width: 70px; } }

@media screen and (min-width: 768px) and (max-width: 990px) {
  .deal-status-line {
    width: 60px; } }

@media screen and (max-width: 320px) {
  .deal-status-line {
    width: 60px; } }

@media only screen and (min-width: 770px) {
  .admin-setting {
    margin-left: -30px !important; } }

.admin-setting .sidemenu {
  background-color: #fff; }
  .admin-setting .sidemenu .nav-pills .nav-item {
    border-bottom: 1px solid #e2e2e7;
    cursor: pointer; }
    .admin-setting .sidemenu .nav-pills .nav-item .active {
      background-color: #fff;
      color: #009dda !important; }
    .admin-setting .sidemenu .nav-pills .nav-item .nav-link {
      border-bottom: 0;
      border-left: 0;
      border-right: 0;
      color: #212529; }

@media only screen and (min-width: 1200px) {
  .dashboard-banner-image {
    max-height: 225px; } }

@media screen and (min-width: 991px) and (max-width: 1200px) {
  .dashboard-banner-image {
    max-height: 225px; } }

@media screen and (min-width: 768px) and (max-width: 990px) {
  .dashboard-banner-image {
    max-height: 210px; } }

@media screen and (min-width: 320px) and (max-width: 767px) {
  .dashboard-banner-image {
    max-height: 170px; } }

@media screen and (max-width: 320px) {
  .dashboard-banner-image {
    max-height: 125px; } }

.text-circle {
  background: #636bf7;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px; }

.left-nav-bg {
  height: 99%;
  position: absolute;
  width: 50%;
  z-index: -1; }

.stats-and-activity .content h4 {
  padding-bottom: 20px; }

@media screen and (min-width: 767px) {
  .validation .invalid-feedback {
    position: absolute; } }

@media screen and (min-width: 767px) {
  .check .invalid-feedback {
    position: absolute; } }

.single-activity:not(:last-child) .activity-line {
  border-left: 2px solid #d3d3d3;
  height: 90px;
  left: 4.5%;
  margin: 32px 0 0;
  position: absolute; }

.alert-salesforce {
  color: #0ac297; }

.alert-referral-danger {
  color: #dc3545; }

.opportunity-list {
  background-color: #f3f3f4; }

.account-in-salesforce .popover {
  max-width: 300px !important;
  width: 300px !important; }

.account-in-salesforce-inner {
  max-width: 300px !important;
  width: 300px !important; }

.partner-directory-filter .popover {
  margin-top: 0 !important;
  max-height: 500px !important;
  max-width: 300px !important;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 300px !important; }

.partner-directory-filter-inner {
  max-width: 300px !important;
  width: 300px !important; }

.font-size-13 {
  font-size: 13px; }

.referral-modal-popup .modal-content {
  border-radius: 0;
  padding: 0 !important; }

/* background: */
progress::-webkit-progress-bar {
  background-color: #f3f3f5;
  width: 100%; }

progress {
  background-color: #f3f3f5;
  color: #5a45f8; }

/* value: */
progress::-webkit-progress-value {
  background-color: #5a45f8 !important; }

progress::-moz-progress-bar {
  background-color: #5a45f8 !important; }

.progressbar-wrapper {
  width: 100%; }
  .progressbar-wrapper:not(:first-child) {
    border-left: 1px #c1c1c1 solid; }
  .progressbar-wrapper p {
    padding-left: 7px; }

.blue-progress-bar progress::-webkit-progress-value {
  background-color: #009dda !important; }

.blue-progress-bar progress::-moz-progress-bar {
  background-color: #009dda !important; }

.blue-progress-bar progress {
  color: #009dda; }

.red-progress-bar progress::-webkit-progress-value {
  background-color: #f61a1e !important; }

.red-progress-bar progress::-moz-progress-bar {
  background-color: #f61a1e !important; }

.red-progress-bar progress {
  color: #f61a1e; }

.count {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

@media (max-width: 992px) {
  .count {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2; } }

.green-progress-bar progress::-webkit-progress-value {
  background-color: #0ac297 !important; }

.green-progress-bar progress::-moz-progress-bar {
  background-color: #0ac297 !important; }

.green-progress-bar progress {
  color: #0ac297; }

.lessonCheckbox {
  margin-right: 1rem;
  max-width: 32px;
  min-width: 32px;
  width: 100%;
  height: 32px;
  border-radius: 100%;
  border: 2px solid #cacacd;
  display: flex;
  align-items: center;
  justify-content: center; }
  .lessonCheckbox svg {
    color: #fff;
    max-height: 18px; }
  .lessonCheckbox.add-task {
    border-style: dotted;
    border-color: #009dda;
    color: #009dda; }
    .lessonCheckbox.add-task svg {
      width: 16px;
      height: 16px;
      color: #009dda; }
  .lessonCheckbox.checkbox-pending {
    background-color: #f8c017;
    border-color: #f8c017; }
  .lessonCheckbox.checkbox-completed {
    background-color: #0ac297;
    border-color: #0ac297; }

.pre-wrap {
  white-space: pre-wrap; }

.photo-gallery-corousel {
  align-items: center;
  grid-gap: 15px;
  gap: 15px; }

@media only screen and (min-width: 1200px) {
  .edit-profile-media {
    max-width: 495px; } }

@media screen and (min-width: 991px) and (max-width: 1200px) {
  .edit-profile-media {
    max-width: 350px; } }

@media screen and (min-width: 768px) and (max-width: 990px) {
  .edit-profile-media {
    max-width: 350px; } }

@media screen and (min-width: 320px) and (max-width: 767px) {
  .edit-profile-media {
    max-width: 350px; } }

@media screen and (max-width: 320px) {
  .edit-profile-media {
    max-width: 125px; } }

@media screen and (min-width: 991px) and (max-width: 1200px) {
  .sidemenu .admin-setting {
    width: 50%; } }

@media (max-width: 630px) {
  .mobile-wrapper {
    position: fixed;
    z-index: 29;
    width: 345px;
    color: black;
    background-color: white; } }

@media screen and (min-width: 991px) and (max-width: 1400px) {
  .admin-setting {
    margin-left: -30px !important; }
    .admin-setting .secondary-menu {
      background-color: #fff;
      width: 244px; }
      .admin-setting .secondary-menu .nav-pills .nav-item {
        border-bottom: 1px solid #e6f3ff;
        cursor: pointer; }
        .admin-setting .secondary-menu .nav-pills .nav-item .active {
          background-color: #fff;
          color: #009dda !important; }
        .admin-setting .secondary-menu .nav-pills .nav-item .nav-link {
          border-bottom: 0;
          border-left: 0;
          border-right: 0;
          color: #212529; } }

@media screen and (min-width: 320px) and (max-width: 767px) {
  .admin-setting {
    margin-left: -30px !important; } }

.admin-setting .secondary-menu {
  background-color: #fff;
  width: 207px; }
  .admin-setting .secondary-menu .nav-pills .nav-item {
    border-bottom: 1px solid #e2e2e7;
    cursor: pointer; }
    .admin-setting .secondary-menu .nav-pills .nav-item .active {
      background-color: #fff;
      color: #009dda !important; }
    .admin-setting .secondary-menu .nav-pills .nav-item .nav-link {
      border-bottom: 0;
      border-left: 0;
      border-right: 0;
      color: #212529; }

.side-menu {
  display: block; }
  @media (max-width: 630px) {
    .side-menu {
      display: none; } }

.mobile-menu {
  display: none; }
  @media (max-width: 630px) {
    .mobile-menu {
      display: block; } }

@media screen and (min-width: 989px) and (max-width: 1199px) {
  .main-wrapper {
    max-width: 71% !important; } }

.slick-track {
  margin-left: 0 !important; }

.app-globe-icon {
  width: 15px !important;
  height: 15px !important; }

.DraftEditor-editorContainer {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  word-break: break-word; }

.notranslate {
  height: 250px !important;
  max-width: 500px; }

.message-card {
  height: 100px !important;
  overflow-y: scroll; }

.edit-option:hover {
  background-color: #000; }

@media (max-width: 765px) {
  .btn-wrapper {
    margin-left: 24px; } }

@media (max-width: 500px) {
  .profile-picture-preview {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    object-fit: cover !important;
    width: 100% !important; }
  .avatar-picture {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    object-fit: cover !important;
    width: 100%; } }

.navbar {
  padding: 0.75rem 0;
  background: #fff; }
  .navbar .site-logo img {
    height: 40px; }
  .navbar.bg-white-ivory .nav-link {
    color: #2a2a34; }

.dropdown-popup-profile .edit-profile-name {
  margin-left: 62px;
  margin-top: -43px;
  font-size: 14px;
  font-weight: 600; }

.dropdown-popup-profile .dropdown-item {
  margin-top: 10px;
  font-size: 18px; }

.dropdown-popup-profile .dropdown-menu.show {
  width: 250px;
  height: auto;
  background-color: #fff;
  border-radius: 0.5rem;
  overflow: hidden; }
  .dropdown-popup-profile .dropdown-menu.show .dropdown-item:hover {
    background-color: transparent; }
    .dropdown-popup-profile .dropdown-menu.show .dropdown-item:hover a {
      text-decoration: none; }
  .dropdown-popup-profile .dropdown-menu.show .edit-profile-logout {
    color: red; }

.dropdown-popup-profile .dropdown-menu {
  top: 69px; }

.utility-list {
  margin-right: 0.8125rem; }
  .utility-list li a {
    padding: 0 0.8125rem; }

.sub-navigation {
  padding: 1rem 0.875rem;
  background-color: #2a2a34; }
  .sub-navigation p {
    font-size: 0.875rem;
    margin-bottom: 0;
    color: #fff; }
    .sub-navigation p svg {
      margin: 0 0.5rem 0 0; }
  .sub-navigation:hover {
    cursor: pointer;
    text-decoration: underline; }

.login-btn svg {
  height: 20px;
  width: 20px; }

.site-maincontent {
  padding-top: 1.5rem; }
  @media (max-width: 575px) {
    .site-maincontent {
      padding-top: 1rem; } }

.admin-action-navigation {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e2e2e8; }
  .admin-action-navigation a {
    font-size: 0.875rem; }
    .admin-action-navigation a svg {
      width: 1rem;
      height: 1rem; }
  .admin-action-navigation + .page-title {
    margin-top: 2rem !important; }
  .admin-action-navigation .steps-wrapper .step .step-status-text {
    font-size: 0.875rem; }
  .admin-action-navigation .project-name {
    max-width: 320px; }
  @media (max-width: 575px) {
    .admin-action-navigation {
      margin-bottom: 1rem;
      padding-bottom: 1rem; } }

@media (max-width: 575px) {
  .with-hamburger-menu {
    display: flex;
    justify-content: stretch; }
    .with-hamburger-menu .admin-action-navigation {
      flex-grow: 1; } }

.activties-page-size {
  max-width: 90px !important; }

.page-heading {
  margin-bottom: 1.5rem; }
  .page-heading .btn-wrapper .btn {
    white-space: nowrap;
    font-size: 1rem;
    padding-left: 0.5rem;
    height: 100%;
    padding-right: 1rem; }
    .page-heading .btn-wrapper .btn svg {
      margin-bottom: 0; }
    .page-heading .btn-wrapper .btn span {
      font-size: 0.875rem; }
  .page-heading .page-search {
    max-width: 100%;
    width: auto; }
    .page-heading .page-search.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px; }
  .page-heading.cover {
    background: #fff;
    padding: 1.25rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); }
    .page-heading.cover .form-control:focus {
      background-color: #e7e7ea;
      border: 1px solid #e7e7ea; }
  @media (max-width: 991px) {
    .page-heading .page-search {
      margin-bottom: 1rem; }
    .page-heading .page-search p,
    .page-heading .dropdown-wrapper p {
      min-width: 55px; } }
  @media (max-width: 767px) {
    .page-heading .dropdown-wrapper.select-dropdown {
      min-width: 100%;
      justify-content: start !important; }
      .page-heading .dropdown-wrapper.select-dropdown .btn-group {
        width: 100%; }
        .page-heading .dropdown-wrapper.select-dropdown .btn-group .dropdown-toggle {
          position: relative; }
          .page-heading .dropdown-wrapper.select-dropdown .btn-group .dropdown-toggle svg {
            position: absolute;
            right: 5px;
            top: auto; } }
  @media (max-width: 575px) {
    .page-heading .page-search p,
    .page-heading .dropdown-wrapper p {
      display: none !important; }
    .page-heading .page-search {
      width: 100%; }
      .page-heading .page-search .input-group-prepend {
        display: none; }
      .page-heading .page-search.input-group > .form-control {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px; } }

.hamburger-btn {
  position: relative;
  z-index: 10;
  display: none !important;
  margin-right: 3px; }
  @media (max-width: 575px) {
    .hamburger-btn {
      display: block !important; } }

.page-search {
  max-width: 295px; }
  .page-search .input-group-text {
    background-color: #e7e7ea; }
    .page-search .input-group-text svg {
      fill: #b8b8bd; }
  .page-search input {
    background-color: #e7e7ea;
    outline: none !important; }
    .page-search input::placeholder {
      color: #292934; }
  .page-search .input-group-text,
  .page-search .form-control {
    border-radius: 7px;
    border: none; }
    .page-search .input-group-text:focus,
    .page-search .form-control:focus {
      box-shadow: none; }

.content {
  margin-top: 2rem; }
  .content p:not(:last-child) {
    margin-bottom: 1.75rem; }
  .content p img {
    float: left;
    max-width: 270px;
    margin: 0 1.75rem 1rem 0; }
  .content p + ul {
    margin-top: -0.75rem; }
    .content p + ul + ul {
      margin-top: auto; }
  .content ul {
    padding-left: 0;
    list-style-position: inside; }

.cart-item {
  margin-bottom: 1.5rem;
  padding-bottom: 1.875rem;
  border-bottom: 1px solid #d8d8d8;
  width: 100%; }
  .cart-item p.h6 {
    font-size: 1rem; }
  .cart-item p b + form {
    margin-top: 0.5rem; }
  .cart-item form .btn-secondary {
    padding: 0.5rem 0.675rem; }

.sidebar-content .cart-item {
  padding-bottom: 0.75rem;
  margin-bottom: 0.875rem; }
  .sidebar-content .cart-item > p:last-child,
  .sidebar-content .cart-item > ul:last-child {
    margin-bottom: 0; }
  .sidebar-content .cart-item:last-of-type {
    border: none; }

.order-item .media-left {
  max-width: 270px;
  margin-right: 1.25rem; }

.custom-checkbox.form-group {
  margin-bottom: 0; }

.custom-checkbox label {
  margin-bottom: 0; }
  .custom-checkbox label:not(:last-child) {
    margin-bottom: 1.25rem; }

.custom-checkbox .input-wrapper input {
  display: none; }

.marketplace-section-content {
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  padding: 2.5rem 1.5rem;
  width: 100%;
  margin-bottom: 1.5rem;
  z-index: 1; }
  .marketplace-section-content .content {
    margin-top: 0; }
  .marketplace-section-content .cart-item p:last-child {
    margin-bottom: 0; }

.recommendations {
  margin-top: 1rem; }
  .recommendations p:last-child {
    margin-bottom: 1.75rem; }

.recommendations-editing .recommendation,
.recommendations-editing .certification-editing,
.recommendations-editing .employment,
.recommendations-editing .education,
.certifications-editing .recommendation,
.certifications-editing .certification-editing,
.certifications-editing .employment,
.certifications-editing .education,
.employment-editing .recommendation,
.employment-editing .certification-editing,
.employment-editing .employment,
.employment-editing .education,
.education-editing .recommendation,
.education-editing .certification-editing,
.education-editing .employment,
.education-editing .education {
  padding: 16px;
  border: 1px dashed #b8b8bd;
  border-radius: 3px;
  margin: 0 0 1rem; }

.certifications {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
  .certifications .certification {
    display: flex;
    padding: 1.5rem;
    border: 1px solid #e2e2e8; }
    .certifications .certification:not(:last-child) {
      margin: 0 0 1.5rem; }
    .certifications .certification .certification-image {
      max-width: 85px;
      width: 100%;
      margin-right: 1.5rem; }
    .certifications .certification .certification-content {
      margin: auto 0; }
      .certifications .certification .certification-content p {
        font-weight: 600;
        margin: 0 0 0.25rem;
        font-size: 1rem; }
      .certifications .certification .certification-content span {
        display: block;
        font-size: 0.875rem; }
  .certifications.certifications-editing {
    display: flex;
    flex-direction: column; }
  @media (max-width: 767px) {
    .certifications .certification {
      flex-direction: column;
      align-items: center; }
      .certifications .certification .certification-image {
        margin: 0 0 1.5rem; } }

.employments .employment:not(:last-child) {
  margin-bottom: 2rem; }
  .employments .employment:not(:last-child):after {
    content: " ";
    width: 140px;
    height: 1px;
    background-color: #000;
    margin: 2rem 0 0;
    display: block; }

.employments.employment-editing .employment:after {
  content: none; }

.recommendations,
.certifications,
.employments,
.educations {
  margin-top: 1rem; }
  .recommendations .content-title p,
  .certifications .content-title p,
  .employments .content-title p,
  .educations .content-title p {
    font-size: 1rem;
    margin-bottom: 1.125rem; }
  .recommendations p,
  .certifications p,
  .employments p,
  .educations p {
    font-size: 0.875rem; }

.advisor-link {
  padding-top: 53.45%;
  position: relative;
  display: block;
  color: #fff;
  overflow: hidden;
  box-shadow: inset 20px 20px 2px white; }
  .advisor-link .overlay,
  .advisor-link .background-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .advisor-link .overlay {
    z-index: 3;
    padding: 20px; }
    .advisor-link .overlay .h1 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      margin: 0;
      border: 2px solid transparent;
      letter-spacing: 1px;
      transition: all 280ms ease;
      flex-direction: column; }
      .advisor-link .overlay .h1 > p {
        font-size: 1rem; }
  .advisor-link .background-holder {
    z-index: 2;
    background-size: cover;
    transition: all 280ms ease; }
  .advisor-link:hover {
    color: #fff; }
    .advisor-link:hover .background-holder {
      transform: scale(1.1); }
    .advisor-link:hover .overlay .h1 {
      border-color: #fff; }

.main-wrapper {
  background-color: #f4f4f6; }

@media (max-width: 991px) {
  .main-wrapper.pr-md-0 {
    padding-right: 15px !important; } }

@media (max-width: 767px) {
  .main-wrapper {
    padding-left: 0 !important; } }

.card-list-wrapper > span {
  margin-bottom: 0.625rem; }

.card {
  border: none !important;
  border-radius: 0 !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); }
  .card .card-button {
    background-color: #52c297;
    color: white;
    border-radius: 8px; }
    .card .card-button:hover {
      background-color: white;
      color: #52c297;
      border: 1px solid; }
  .card .card-header {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 !important;
    position: relative; }
    .card .card-header .more-dropdown {
      position: absolute;
      top: 4px;
      right: 0; }
    @media (max-width: 420px) {
      .card .card-header .card-header-icon {
        position: absolute;
        top: 125px; } }
    @media (min-width: 425px) and (max-width: 650px) {
      .card .card-header .card-header-icon {
        position: absolute;
        top: 215px; } }
    .card .card-header .card-header-icon svg {
      fill: #fff;
      color: #fff;
      width: 48px;
      height: 48px;
      display: block; }
      .card .card-header .card-header-icon svg path {
        fill: currentColor !important; }
    .card .card-header .card-header-icon .icon-picker {
      display: block; }
      .card .card-header .card-header-icon .icon-picker span {
        padding: 0; }
        .card .card-header .card-header-icon .icon-picker span.PaintIcon svg path, .card .card-header .card-header-icon .icon-picker span.CogIcon svg path, .card .card-header .card-header-icon .icon-picker span.DripIcon svg path, .card .card-header .card-header-icon .icon-picker span.NestIcon svg path {
          fill: none !important;
          stroke: currentColor; }
  .card .card-body {
    min-height: 112px;
    padding: 1.25rem; }
    .card .card-body .card-title {
      line-height: 1.5rem; }
      .card .card-body .card-title:last-child {
        margin-bottom: 0; }
  .card p:last-child {
    margin-bottom: 0; }
  .card .user-and-title {
    margin: 1.5rem 0 0; }
  .card .location {
    margin: 0.75rem 0 0; }
    .card .location i {
      max-width: 0.75rem;
      margin-right: 0.3125rem;
      display: block; }
    .card .location small {
      display: block; }
  .card .rate {
    margin: 0.75rem 0 0; }
  .card .skills {
    margin: 1.75rem 0 1.5rem; }
  .card .badge {
    padding: 0.125rem 0.5rem;
    line-height: 1rem;
    font-size: 0.75rem;
    font-weight: 700;
    color: #fff; }
    .card .badge.badge-orange {
      background-color: #df674c; }
    .card .badge:not(:last-child) {
      margin-right: 0.25rem; }
  .card .certification {
    width: 100%;
    height: -1px;
    margin: 0.25rem 0 0 -8.9rem; }
    .card .certification .badge.badge-primary {
      background-color: #009dda;
      color: #fff;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .card .card-footer {
    padding: 0.5rem 1.25rem;
    border-top: 0; }
    .card .card-footer .price s {
      opacity: 0.5;
      font-weight: 700; }
  @media (max-width: 767px) {
    .card {
      margin: auto; } }

.expert-card .card .card-header {
  padding: 100% 0 0; }
  .expert-card .card .card-header .view-full-profile {
    color: #fff;
    padding: 0.5rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex; }
    .expert-card .card .card-header .view-full-profile span {
      display: block;
      margin: 0 auto; }
    .expert-card .card .card-header .view-full-profile svg {
      margin-right: 0; }

.expert-card .card .certification {
  position: absolute;
  top: 0.25rem;
  left: 0;
  margin: 0; }

.expert-select {
  width: 50px;
  height: 50px;
  top: 10px;
  right: 10px;
  position: absolute;
  border-radius: 100%;
  border: 2px solid #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }
  .expert-select.selected {
    border-color: #0ac297;
    background-color: #0ac297;
    display: flex;
    align-items: center;
    justify-content: center; }
    .expert-select.selected svg {
      color: #fff; }

.simple-expert-card .card-header {
  border: none;
  padding: 0; }

.simple-expert-card .user-and-title span,
.simple-expert-card .user-and-title p {
  line-height: 1rem; }

.simple-expert-card .user-title {
  max-width: 150px; }
  .simple-expert-card .user-title p,
  .simple-expert-card .user-title span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }

.simple-expert-card a {
  color: #000; }
  .simple-expert-card a:hover {
    color: #009dda; }

.simple-expert-card-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem; }

.playbook-card-link .card {
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
  .playbook-card-link .card:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); }

.active-projects-icon svg {
  width: 44px;
  height: 40px; }
  .active-projects-icon svg path {
    color: #99a9b1;
    fill: currentColor !important; }

.no-records-icon svg {
  width: 44px;
  height: 42px; }

.card-feedback-icon svg {
  fill: #f3c701; }

.simple-user-card {
  max-width: 150px;
  max-height: 50px; }
  .simple-user-card p,
  .simple-user-card span {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 1px; }

.accordion-card-header {
  height: 50px;
  display: flex;
  justify-content: start;
  align-items: center;
  border-radius: 0 !important;
  position: relative;
  padding-left: 10px;
  background-color: #f4f4f6; }

.dropdown-wrapper .dropdown-menu {
  border-radius: 0;
  border: none;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 100; }

.dropdown-wrapper .dropdown-item {
  margin: 0.5rem 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .dropdown-wrapper .dropdown-item:focus {
    outline: none; }

.dropdown-wrapper .dropdown-item,
.dropdown-wrapper .dropdown-item:active,
.dropdown-wrapper .dropdown-item.active {
  background-color: transparent;
  box-shadow: none;
  color: inherit; }

.dropdown-wrapper .btn-group #caret + .dropdown-toggle:after {
  content: none; }

.dropdown-wrapper .btn-group .dropdown-toggle {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.dropdown-wrapper .btn-gray {
  background-color: #e7e7ea; }

.dropdown-wrapper.select-dropdown {
  display: inline-flex; }
  .dropdown-wrapper.select-dropdown .dropdown-toggle {
    display: flex;
    align-items: center; }
    .dropdown-wrapper.select-dropdown .dropdown-toggle svg {
      margin: 0 0 0 0.5rem;
      max-width: 15px; }

.dropdown-wrapper .dropdown-menu {
  width: auto; }

.dropdown-wrapper .hide-caret:after {
  content: none; }

.more-dropdown .btn.btn-secondary {
  background-color: transparent;
  padding: 0.375rem;
  box-shadow: none !important; }
  .more-dropdown .btn.btn-secondary svg {
    color: #fff;
    fill: #fff; }
  .more-dropdown .btn.btn-secondary:not(:disabled):focus, .more-dropdown .btn.btn-secondary:not(:disabled):active, .more-dropdown .btn.btn-secondary:not(:disabled).focus, .more-dropdown .btn.btn-secondary:not(:disabled).active {
    background-color: transparent; }

.more-dropdown .fill-black .btn.btn-secondary svg {
  fill: #000000 !important; }

.navbar .dropdown-wrapper .edit-profile-item {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: flex-start; }

.navbar .dropdown-wrapper .dropdown-menu {
  min-width: 265px; }

.navbar .dropdown-wrapper .dropdown .edit-profile-actions {
  margin-left: 1.25rem; }

.dropdown .dropdown-inline-item {
  padding: 0.5rem 1.5rem; }

#partner-manager .dropdown-toggle {
  width: 85%; }

#partner-manager .dropdown-menu {
  width: 100%; }

.user-dropdown .dropdown-toggle {
  justify-content: space-between;
  align-items: center;
  min-height: 2.5rem;
  width: 100%;
  font-size: 0.875rem; }
  .user-dropdown .dropdown-toggle svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem; }

.user-dropdown .user-badge {
  padding: 0;
  background-color: transparent;
  height: auto;
  margin-right: 1rem;
  font-size: 0.875rem; }
  .user-dropdown .user-badge img {
    width: 30px;
    height: 30px;
    margin-right: 0.625rem; }

.user-dropdown .no-assignee {
  padding-left: 0.5rem; }

.user-dropdown .dropdown-menu {
  width: 100%; }

.user-dropdown .dropdown-item,
.user-dropdown .dropdown-toggle {
  padding: 0.25rem;
  display: flex; }

.user-dropdown .dropdown-menu .dropdown-item.active-selection {
  background-color: #d9f1fa; }

.section {
  padding: 60px 0; }
  .section:not(.banner) + .section:not(.banner-small) {
    padding-top: 0; }
    .section:not(.banner) + .section:not(.banner-small):not(:last-child) {
      margin-bottom: -1.5rem; }
  @media (max-width: 767px) {
    .section {
      padding: 40px 0; } }
  .section.section-xsmall {
    padding: 20px 0 40px; }
  .section.salesforce-playbooks {
    padding: 40px 0 60px; }

.section-title .h1,
.section-title .h2,
.section-title .h3,
.section-title .h4,
.section-title .h5,
.section-title .h6 {
  margin-bottom: 2.5rem; }
  .section-title .h1:after,
  .section-title .h2:after,
  .section-title .h3:after,
  .section-title .h4:after,
  .section-title .h5:after,
  .section-title .h6:after {
    content: " ";
    height: 1px;
    width: 140px;
    margin: 0.625rem 0 0;
    background-color: #df674c;
    display: block; }
  .section-title .h1 + p,
  .section-title .h2 + p,
  .section-title .h3 + p,
  .section-title .h4 + p,
  .section-title .h5 + p,
  .section-title .h6 + p {
    margin-top: -0.5rem; }

.section-title p:last-child {
  margin-bottom: 2.5rem; }

.section-title .author {
  display: block;
  margin-top: -1rem;
  opacity: 0.5; }

.section-title-mobile .h1,
.section-title-mobile .h2,
.section-title-mobile .h3,
.section-title-mobile .h4,
.section-title-mobile .h5,
.section-title-mobile .h6 {
  margin-bottom: 1.5rem; }
  .section-title-mobile .h1:after,
  .section-title-mobile .h2:after,
  .section-title-mobile .h3:after,
  .section-title-mobile .h4:after,
  .section-title-mobile .h5:after,
  .section-title-mobile .h6:after {
    content: " ";
    height: 1px;
    width: 140px;
    margin: 0.625rem 0 0;
    background-color: #df674c;
    display: block; }
  .section-title-mobile .h1 + p,
  .section-title-mobile .h2 + p,
  .section-title-mobile .h3 + p,
  .section-title-mobile .h4 + p,
  .section-title-mobile .h5 + p,
  .section-title-mobile .h6 + p {
    margin-top: -0.5rem; }

.section-title-mobile p:last-child {
  margin-bottom: 2.5rem; }

.section-title-mobile .author {
  display: block;
  margin-top: -1rem;
  opacity: 0.5; }

.site-maincontent .section {
  padding: 40px 0; }
  .site-maincontent .section:first-of-type:not(.banner) {
    padding-top: 0; }
  .site-maincontent .section.banner .container {
    padding: 0; }
  .site-maincontent .section .container {
    max-width: none;
    padding: 0; }
  .site-maincontent .section .content-wrapper > .row {
    margin: 0; }
  .site-maincontent .section .row {
    width: 100%; }

@media (max-width: 767px) {
  .site-maincontent .section {
    padding: 30px 0; } }

.content-cards .content-wrapper p + .row {
  margin-top: 0.5rem; }

.cart-tabs .nav-tabs {
  background-color: #f8f6ed; }
  .cart-tabs .nav-tabs .nav-link.active,
  .cart-tabs .nav-tabs .nav-item.show .nav-link {
    color: #df674c;
    background-color: #fff;
    border-color: #df674c transparent transparent; }
  .cart-tabs .nav-tabs .nav-item {
    flex: 1 1; }
  .cart-tabs .nav-tabs .nav-link {
    border-radius: 0;
    border-style: solid;
    border-width: 3px 1px 1px 0;
    border-color: transparent #f5eee5 #f5eee5;
    cursor: pointer;
    padding: 0.625rem 0;
    text-align: center;
    font-size: 0.875rem; }
    .cart-tabs .nav-tabs .nav-link:hover:not(.active), .cart-tabs .nav-tabs .nav-link:focus:not(.active) {
      border-color: rgba(223, 103, 76, 0.5) #f5eee5 #f5eee5; }

.admin-tabs.nav-tabs {
  border-bottom: 2px solid #e2e2e8; }
  .admin-tabs.nav-tabs .nav-item {
    margin-bottom: -2px; }
    .admin-tabs.nav-tabs .nav-item .nav-link {
      border-width: 0 0 2px;
      border-color: transparent;
      border-style: solid;
      border-radius: 0;
      padding: 0.875rem 1.25rem;
      cursor: pointer;
      color: rgba(41, 41, 52, 0.5); }
      .admin-tabs.nav-tabs .nav-item .nav-link.active {
        border-bottom-color: #009dda;
        background-color: transparent;
        color: #292934; }

.tabs-and-preview:last-child {
  margin-bottom: 2.25rem; }

.tabs-and-preview.full-width .tab-content {
  max-width: none;
  margin-right: 0; }
  .tabs-and-preview.full-width .tab-content .form-wrapper:not(.w-360):not(.w-300) {
    max-width: calc(100% - 300px); }

.tabs-and-preview .tab-content-wrapper {
  padding: 1.25rem;
  background-color: #fff;
  width: 100%; }
  .tabs-and-preview .tab-content-wrapper:not(:last-child) {
    margin-bottom: 1.25rem; }

.tabs-and-preview .tab-content {
  max-width: 390px;
  width: 100%;
  margin-right: 2rem; }
  .tabs-and-preview .tab-content .price {
    width: 160px; }
  @media (max-width: 630px) {
    .tabs-and-preview .tab-content {
      max-width: 100%;
      width: 100%;
      margin-right: 0rem; } }

.tabs-and-preview .preview-wrapper {
  max-width: 321px;
  width: 100%; }
  @media (max-width: 630px) {
    .tabs-and-preview .preview-wrapper {
      display: none; } }

.tabs-and-preview.admin-settings .form-wrapper .field-wrapper:not(:last-child) {
  margin-bottom: 2.5rem; }

.tabs-and-preview .form-wrapper .field-wrapper.flex-column .form-group {
  width: 100%;
  margin-right: 0; }

.tabs-and-preview .form-wrapper .field-wrapper .form-group {
  margin-bottom: 0; }
  .tabs-and-preview .form-wrapper .field-wrapper .form-group label {
    color: #292934;
    font-size: 1rem; }

.tabs-and-preview .form-wrapper + .btn-wrapper:last-child {
  margin-top: 2.25rem; }

.tabs-and-preview form + .admin-action-navigation {
  background-color: #f3f3f4;
  margin: 1.5rem -1.25rem -1.25rem;
  padding: 1.5rem 0 0; }

.tabs-and-preview .w-360,
.tabs-and-preview .w-300 {
  width: 100%;
  margin-right: 2rem; }

.tabs-and-preview .w-360 {
  max-width: 390px; }

.tabs-and-preview .w-300 {
  max-width: 300px; }

.pane-heading a {
  color: #009dda;
  -webkit-text-decoration: underline solid;
          text-decoration: underline solid; }

.setting-tabs.nav-tabs {
  border-bottom: 2px solid #e2e2e8; }
  .setting-tabs.nav-tabs .nav-item {
    margin-bottom: -2px; }
    .setting-tabs.nav-tabs .nav-item .nav-link {
      border-width: 0 0 3px;
      border-color: transparent;
      border-style: solid;
      border-radius: 0;
      padding: 0.875rem 1.25rem;
      cursor: pointer;
      color: rgba(41, 41, 52, 0.5); }
      .setting-tabs.nav-tabs .nav-item .nav-link.active {
        border-bottom-color: #009dda;
        background-color: transparent;
        color: #292934; }

.section.banner {
  padding: 78px 0 78px;
  background-position: center; }
  .section.banner.banner-big {
    padding: 107px 0;
    background-size: cover;
    background-repeat: no-repeat; }
    .section.banner.banner-big .container {
      padding-left: 15px;
      padding-right: 15px; }
  .section.banner .container {
    padding: 0 56px; }

.section.banner-xsmall {
  padding: 0.8125rem 2.5rem 1.25rem 4.125rem; }
  .section.banner-xsmall .h1 {
    font-size: 1.5rem !important;
    line-height: 2.25rem;
    margin-bottom: 0.125rem; }

.section.banner-box {
  padding: 2rem 1.5rem;
  text-align: center; }
  .section.banner-box .h1 {
    font-size: 1.75rem !important;
    line-height: 2rem;
    font-weight: 300; }
  .section.banner-box p {
    margin: 0.875rem 0 0;
    font-size: 0.875rem;
    font-weight: 300;
    padding: 0 0.125rem; }
  .section.banner-box .btn {
    margin: 1rem 0 0;
    width: 100%;
    font-size: 0.875rem; }
    .section.banner-box .btn.outline-bold {
      border-width: 1px; }
  .section.banner-box .container {
    padding: 0; }
  .section.banner-box .section-content {
    flex-direction: column; }

@media (max-width: 767px) {
  .section.banner {
    padding: 50px 0 40px; }
    .section.banner.banner-big {
      padding: 40px 0; }
    .section.banner .container {
      padding: 0 1.25rem; }
  .section.banner-box {
    padding: 2rem 1.5rem;
    text-align: center; } }

.banner {
  display: flex;
  align-items: center; }
  .banner .section-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-around; }
  .banner .logo-wrapper {
    margin-right: 2.5rem;
    min-width: 100px;
    max-height: 71px; }
    .banner .logo-wrapper img {
      max-height: 71px; }
  .banner .content-wrapper {
    max-width: 580px;
    color: #fff;
    margin: 0.5rem 0 0; }
    .banner .content-wrapper h1,
    .banner .content-wrapper h2,
    .banner .content-wrapper h3,
    .banner .content-wrapper h4,
    .banner .content-wrapper h5,
    .banner .content-wrapper h6 {
      font-weight: 900; }
      .banner .content-wrapper h1:not(:last-child),
      .banner .content-wrapper h2:not(:last-child),
      .banner .content-wrapper h3:not(:last-child),
      .banner .content-wrapper h4:not(:last-child),
      .banner .content-wrapper h5:not(:last-child),
      .banner .content-wrapper h6:not(:last-child) {
        margin-bottom: 1.5rem; }
    .banner .content-wrapper h1 {
      font-size: 2.375rem;
      line-height: 2.875rem;
      margin-bottom: 0; }
  .banner p:last-child {
    margin-bottom: 0; }
  .banner .btn {
    white-space: nowrap;
    margin-left: 2rem; }
  @media (max-width: 991px) {
    .banner .logo-wrapper {
      min-width: 1px;
      max-width: 250px; } }
  @media (max-width: 767px) {
    .banner .section-content {
      flex-direction: column; }
      .banner .section-content .logo-wrapper {
        margin: 0 0 2rem; }
    .banner .btn {
      margin: 2rem 0 0; } }

.site-sidebar {
  margin-bottom: 2.5rem; }
  .site-sidebar.checkout-sidebar {
    margin-top: 115px !important; }
  @media screen and (min-width: 500px) {
    .site-sidebar {
      background: none !important; } }
  @media (max-width: 767px) {
    .site-sidebar {
      margin-bottom: 0; }
      .site-sidebar .card {
        max-width: none; } }

.sidebar-content-box:not(.transparent-box) {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff; }

.sidebar-content-box:not(:last-child) {
  margin-bottom: 1.875rem; }

.sidebar-content-box.transparent-box .sidebar-content {
  padding-left: 0;
  padding-right: 0; }

@media (max-width: 767px) {
  .sidebar-content-box:not(:last-child) {
    margin-bottom: 1.5rem; } }

.sidebar-content {
  padding: 1.25rem; }
  .sidebar-content .title {
    margin-bottom: 0.25rem; }
  .sidebar-content .subtitle {
    opacity: 0.5;
    margin-bottom: 0.5rem; }
  .sidebar-content .price {
    margin-bottom: 1rem; }
  .sidebar-content p {
    font-size: 0.875rem; }
    .sidebar-content p:not(:last-of-type) {
      margin-bottom: 0.25rem; }
    .sidebar-content p svg {
      margin: 0 0.5rem 0 0;
      width: 18px; }
  .sidebar-content .checklist li {
    display: flex;
    font-size: 0.875rem; }
    .sidebar-content .checklist li svg {
      margin-right: 0.625rem;
      width: 20px;
      color: #df674c; }
  .sidebar-content .badge {
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    font-weight: 300;
    margin: 0 0.5rem 0.5rem 0;
    float: left; }
  .sidebar-content .btn-xs {
    font-size: 0.875rem; }

.social-icons {
  display: flex;
  align-items: center; }
  .social-icons a svg {
    width: 24px;
    height: 24px; }

.tag-cloud .sidebar-content {
  padding-bottom: 0.75rem; }
  .tag-cloud .sidebar-content:after {
    content: " ";
    clear: both;
    display: block; }

.rating-wrapper svg,
.usage-wrapper svg {
  margin-right: 4px;
  margin-top: 1px;
  width: 16px;
  height: 16px; }
  .rating-wrapper svg.filled,
  .usage-wrapper svg.filled {
    color: #df674c;
    fill: currentColor; }
    .rating-wrapper svg.filled path,
    .usage-wrapper svg.filled path {
      fill: currentColor; }

.rating-wrapper .h7,
.usage-wrapper .h7 {
  line-height: 1;
  margin-top: 4px; }

.rating-wrapper:not(:last-child) {
  margin-right: 1rem; }

.rating-and-usage {
  margin: 0.625rem 0 0; }

.user-ratings {
  margin: 1.5rem 0 0; }
  .user-ratings .user-rating .media-left {
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    border-radius: 100%;
    margin-right: 0.5rem; }
    .user-ratings .user-rating .media-left img {
      max-width: none;
      height: 100%; }
  .user-ratings .user-rating .rating-wrapper span {
    margin-right: 0.5rem; }
  .user-ratings .user-rating .rating-wrapper svg {
    width: 12px;
    height: 12px; }
  .user-ratings .load-more {
    font-size: 0.875rem; }

.discussion-list .rating-and-usage {
  margin: 0; }

.discussion-list .media-body p {
  margin-bottom: 0.5rem; }

.discussion-list .user-rating {
  margin-bottom: 0.5rem; }
  .discussion-list .user-rating .media-left {
    margin-right: 0.875em; }
  .discussion-list .user-rating .rating-wrapper {
    justify-content: space-between;
    align-items: flex-end !important;
    margin-bottom: 0.5rem; }
    .discussion-list .user-rating .rating-wrapper span {
      line-height: 0.75rem; }
    .discussion-list .user-rating .rating-wrapper .discussion-actions a {
      font-size: 0.625rem;
      line-height: 0.75rem; }

.user-ratings .user-rating .file-preview {
  align-items: stretch; }
  .user-ratings .user-rating .file-preview .media-left {
    width: 54px;
    height: 56px;
    border-radius: 0;
    margin-right: 0; }
  .user-ratings .user-rating .file-preview p {
    margin-bottom: 0; }
  .user-ratings .user-rating .file-preview .media-body {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: center !important; }

.file-preview {
  overflow: hidden;
  background: #f3f3f4;
  border-radius: 3px;
  margin: 1rem 4rem 1rem 0; }
  .file-preview .media-left {
    width: 110px;
    height: 56px;
    border-radius: 0;
    margin-right: 0; }
  .file-preview p {
    margin-bottom: 0; }
  .file-preview .card__corner {
    position: absolute;
    top: 0;
    right: 0;
    width: 1rem;
    height: 1rem;
    background-color: #bfbfc3;
    border-radius: 3px; }
  .file-preview .card__corner-triangle {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 1rem 1rem 0;
    border-color: transparent #fff transparent transparent; }
  .file-preview p,
  .file-preview a {
    line-height: 1rem; }

.circle-colour-picker {
  display: grid;
  grid-template-columns: repeat(11, 22px);
  grid-row-gap: 0.75rem;
  grid-column-gap: 1rem; }
  @media (max-width: 620px) {
    .circle-colour-picker {
      grid-template-columns: repeat(8, 22px); } }
  .circle-colour-picker .circle-colour-wrapper {
    padding: 2px;
    background-color: #fff;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 100%; }
  .circle-colour-picker .circle-colour {
    width: 0.875rem;
    height: 0.875rem;
    display: block;
    border-radius: 100%;
    pointer-events: none; }

.icon-picker {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-column-gap: 1rem;
  grid-row-gap: 0.75rem; }
  @media (max-width: 620px) {
    .icon-picker {
      grid-template-columns: repeat(6, auto); } }
  .icon-picker span {
    width: 36px;
    height: 36px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px; }
    .icon-picker span svg {
      pointer-events: none;
      width: 100%;
      height: 100%; }

.inline-tooltip-wrapper {
  margin: 0.625rem 0 0; }
  .inline-tooltip-wrapper span {
    display: flex; }
    .inline-tooltip-wrapper span svg {
      width: 16px;
      height: 16px;
      margin-right: 0.5rem; }

.sample-project-tooltip.tooltip.show,
.recruitment-tooltip.tooltip.show {
  opacity: 1; }

.sample-project-tooltip.tooltip .tooltip-inner,
.recruitment-tooltip.tooltip .tooltip-inner {
  padding: 1.125rem;
  background-color: #292934;
  color: #fff;
  text-align: left;
  max-width: 370px;
  border-radius: 0; }
  .sample-project-tooltip.tooltip .tooltip-inner p,
  .recruitment-tooltip.tooltip .tooltip-inner p {
    margin-bottom: 0.375rem; }

.sample-project-tooltip .steps-placeholder .steps-wrapper,
.recruitment-tooltip .steps-placeholder .steps-wrapper {
  margin-top: 1.125rem; }
  .sample-project-tooltip .steps-placeholder .steps-wrapper .step .bg-white,
  .recruitment-tooltip .steps-placeholder .steps-wrapper .step .bg-white {
    border-color: transparent; }
    .sample-project-tooltip .steps-placeholder .steps-wrapper .step .bg-white .feather,
    .recruitment-tooltip .steps-placeholder .steps-wrapper .step .bg-white .feather {
      color: #009dda; }

.sample-project-tooltip.bs-tooltip-top .arrow::before, .sample-project-tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.recruitment-tooltip.bs-tooltip-top .arrow::before,
.recruitment-tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #292934; }

.sample-project-tooltip.bs-tooltip-bottom .arrow::before, .sample-project-tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.recruitment-tooltip.bs-tooltip-bottom .arrow::before,
.recruitment-tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #292934; }

.sample-project-tooltip.bs-tooltip-right .arrow::before, .sample-project-tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.recruitment-tooltip.bs-tooltip-right .arrow::before,
.recruitment-tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #292934; }

.sample-project-tooltip.bs-tooltip-left .arrow::before, .sample-project-tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.recruitment-tooltip.bs-tooltip-left .arrow::before,
.recruitment-tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #292934; }

.dropzone-wrapper {
  background-color: #f3f3f4;
  border: 2px dashed #9a9a9a;
  border-radius: 5px;
  display: flex;
  max-height: 250px;
  min-height: 250px; }
  .dropzone-wrapper .dropzone {
    flex: 1 1;
    outline: none; }

.assets-uploader {
  padding: 12px;
  background-color: #dfdfe1; }
  .assets-uploader .dropzone-wrapper {
    min-height: 56px;
    background-color: #eee;
    border-color: #9a9a9a; }
  .assets-uploader .file-preview {
    width: 100%;
    align-items: stretch; }
    .assets-uploader .file-preview .card__corner-triangle {
      border-color: transparent #dfdfe1 transparent transparent; }
  .assets-uploader progress {
    border-radius: 200px; }
  .assets-uploader progress::-webkit-progress-bar {
    background-color: transparent;
    border: 2px solid #dfdfe1;
    border-radius: 200px;
    overflow: hidden; }
  .assets-uploader progress::-webkit-progress-value {
    height: 16px;
    background: #0ac297; }
  .assets-uploader progress::-moz-progress-bar {
    height: 16px;
    border-radius: 200px;
    background: #0ac297;
    border: 2px solid #dfdfe1; }

.project-list {
  margin: 1.35rem 0 0; }
  .project-list .project {
    background-color: #fff;
    color: #212529;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.35rem; }
    .project-list .project .arrow-hover .feather-arrow-right {
      opacity: 0; }
    .project-list .project:hover {
      color: #212529;
      transition: box-shadow 0.3s ease-in-out; }
      .project-list .project:hover .arrow-hover .feather-arrow-right {
        color: #009dda;
        opacity: 1;
        transition: opacity 0.3s ease-in-out; }
      .project-list .project:hover .project-playbook-card .card .card-body .card-title {
        color: #009dda;
        transition: color 0.3s ease-in; }
    .project-list .project .project-maininfo {
      padding: 1.35rem 1.35rem 0.75rem;
      min-height: 224px;
      max-height: 224px;
      border-bottom: 1px solid #f3f3f4; }
    .project-list .project .partner-program {
      padding: 1.35rem 1.35rem 0.75rem;
      min-height: 180px;
      max-height: 180px; }
    .project-list .project .project-playbook-card {
      min-width: 265px;
      margin-bottom: 0 !important; }
      .project-list .project .project-playbook-card .card {
        box-shadow: none; }
        .project-list .project .project-playbook-card .card .card-header {
          height: 224px;
          position: relative; }
        .project-list .project .project-playbook-card .card .card-body {
          height: 148px; }
        .project-list .project .project-playbook-card .card .new-message,
        .project-list .project .project-playbook-card .card .new-alert-message {
          position: absolute;
          background-color: #e86c61;
          color: white;
          font-weight: 300; }
        .project-list .project .project-playbook-card .card .new-message {
          top: 10px;
          left: 10px;
          display: flex;
          padding: 0.5625rem 0.75rem;
          border-radius: 19px;
          line-height: 1.25rem; }
          .project-list .project .project-playbook-card .card .new-message svg {
            height: 20px;
            width: 20px;
            margin-right: 0.5rem; }
        .project-list .project .project-playbook-card .card .new-alert-message {
          bottom: -1px;
          left: 0;
          right: 0;
          padding: 0.875rem 1.25rem;
          font-size: 0.75rem; }
    .project-list .project .project-finances {
      margin-right: 1.125rem;
      max-width: 250px;
      min-width: 250px;
      width: 100%; }
    .project-list .project .project-progress {
      width: 100%;
      min-width: 248px;
      max-width: 248px; }
    .project-list .project .project-timeline {
      border-left: 1px solid #f3f3f4;
      height: 100%;
      display: inline-flex;
      align-items: center;
      padding-left: 1.875rem; }
  .project-list .supporting-materials .supporting-material {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem; }
  @media (max-width: 1200px) {
    .project-list .project .project-playbook-card {
      min-width: 235px; }
    .project-list .project .project-maininfo {
      padding: 1rem 0.25rem 0.75rem 1rem; }
    .project-list .project .project-timeline {
      padding-left: 1rem; }
    .project-list .project .project-finances {
      min-width: 210px;
      max-width: 210px;
      margin-right: 1rem; }
    .project-list .project .project-progress {
      max-width: 205px;
      min-width: 205px; } }
  @media (max-width: 991px) {
    .project-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 2rem; }
      .project-list .project {
        flex-direction: column; }
        .project-list .project .project-playbook-card {
          min-width: 1px; }
          .project-list .project .project-playbook-card .card .card-body {
            height: auto;
            min-height: 1px;
            border-bottom: 1px solid #f3f3f4; }
        .project-list .project .project-maininfo {
          flex-direction: column;
          max-height: none;
          min-height: 1px;
          padding: 1.25rem;
          border: none; }
        .project-list .project .project-finances,
        .project-list .project .project-progress {
          max-width: none;
          width: 100%;
          min-width: 1px;
          margin: 0; }
        .project-list .project .project-finances .field-wrapper:not(:first-child),
        .project-list .project .project-progress .field-wrapper:not(:first-child) {
          display: none; }
        .project-list .project .project-progress .field-wrapper {
          margin-bottom: 0; }
        .project-list .project .project-timeline {
          padding: 0 1.25rem 1.25rem;
          border: none; } }
  @media (max-width: 767px) {
    .project-list {
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 0; }
      .project-list .project {
        margin: 0 auto 1.25rem;
        min-width: 285px;
        max-width: 300px; } }
  @media (max-width: 575px) {
    .project-list .project {
      min-width: 1px;
      width: 100%; } }

.steps-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .steps-wrapper .step {
    display: inline-flex;
    align-items: center; }
    .steps-wrapper .step .step-icon {
      min-width: 50px;
      min-height: 50px;
      max-width: 50px;
      max-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      border: 2px solid #9a9a9a;
      margin: 0 1.25rem 0 0; }
      .steps-wrapper .step .step-icon svg {
        max-height: 22px;
        color: #9a9a9a; }
    .steps-wrapper .step .step-status-text {
      display: none; }
    .steps-wrapper .step .feather-arrow-right {
      color: #e7e7ea; }
    .steps-wrapper .step .step-status-text,
    .steps-wrapper .step .feather-arrow-right,
    .steps-wrapper .step .step-icon {
      margin: 0 0.8rem 0 0; }
    .steps-wrapper .step.current-step .step-icon {
      background-color: #009dda;
      border-color: #009dda; }
    .steps-wrapper .step.current-step .step-status-text {
      display: block; }
    .steps-wrapper .step.completed-step .step-icon {
      background-color: #0ac297;
      border-color: #0ac297; }
    .steps-wrapper .step.completed-step .step-status-text {
      display: none; }
    .steps-wrapper .step.completed-step .step-icon svg, .steps-wrapper .step.current-step .step-icon svg {
      color: #fff; }
    .steps-wrapper .step:last-child .step-status-text {
      margin-right: 0; }
    .steps-wrapper .step:last-child:not(.current-step) .step-icon {
      margin-right: 0; }
  @media (max-width: 1200px) {
    .steps-wrapper .step .feather-arrow-right,
    .steps-wrapper .step .step-status-text,
    .steps-wrapper .step .step-icon {
      margin-right: 0.5rem; } }
  @media (max-width: 991px) {
    .steps-wrapper .step .step-icon {
      min-width: 2rem;
      min-height: 2rem;
      max-width: 2rem;
      max-height: 2rem; }
      .steps-wrapper .step .step-icon svg {
        max-height: 15px; }
    .steps-wrapper .step .step-status-text {
      display: none !important; }
    .steps-wrapper .step .feather-arrow-right {
      height: 1rem;
      width: 1rem; } }

.project-list .project .field-wrapper p,
.project-list .project .field-wrapper span,
.sidebar-item .field-wrapper p,
.sidebar-item .field-wrapper span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%; }

.project-list .project .field-wrapper:not(:last-child),
.sidebar-item .field-wrapper:not(:last-child) {
  margin: 0 0 1.25rem; }

.project-list .project .field-wrapper p,
.sidebar-item .field-wrapper p {
  margin-bottom: 0;
  line-height: 1.125rem; }
  .project-list .project .field-wrapper p span:before,
  .sidebar-item .field-wrapper p span:before {
    content: " "; }

.project-list .project .supporting-materials .field-wrapper:not(:last-child),
.sidebar-item .supporting-materials .field-wrapper:not(:last-child) {
  margin-bottom: 0; }

.project-list .project .supporting-materials + .field-wrapper,
.project-list .project .supporting-materials + .supporting-materials,
.sidebar-item .supporting-materials + .field-wrapper,
.sidebar-item .supporting-materials + .supporting-materials {
  margin-top: 1.25rem; }

.project-list .project .user-wrapper,
.details-body-sidebar .user-wrapper {
  display: flex;
  justify-content: start;
  align-items: center; }
  .project-list .project .user-wrapper .field-icon,
  .details-body-sidebar .user-wrapper .field-icon {
    border-radius: 100%;
    background-color: #f3f3f4;
    overflow: hidden; }

.project-list .project .field-icon,
.details-body-sidebar .field-icon {
  min-width: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center; }
  .project-list .project .field-icon + p,
  .project-list .project .field-icon + .flex-column,
  .details-body-sidebar .field-icon + p,
  .details-body-sidebar .field-icon + .flex-column {
    margin: 0 0 0 1rem; }

.user-wrapper .expert-info {
  min-width: 0; }

.user-wrapper .user-actions {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .user-wrapper .user-actions a svg {
    max-height: 16px;
    max-width: 16px; }
  .user-wrapper .user-actions label {
    display: block;
    margin-bottom: 0; }

.user-wrapper .form-wrapper .radio-button + p .radio-placeholder {
  margin-right: 0;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px; }

.alerts-wrapper + .project-details-wrapper,
.feedback-component + .project-details-wrapper {
  margin: 0;
  padding-top: 1.5rem; }

.project-details {
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin: 0 0 2rem; }
  .project-details .project-details-header {
    display: flex;
    border-bottom: 1px solid #e2e2e8; }
  .project-details .img-wrapper {
    max-width: 265px;
    min-width: 265px;
    width: 100%; }
  .project-details .description {
    padding: 1.25rem; }
  .project-details .show-more {
    align-items: center;
    font-weight: 700; }
    .project-details .show-more svg {
      margin: 0.1875rem 0 0 0.25rem;
      width: 1rem;
      height: 1rem; }
  @media (max-width: 991px) {
    .project-details .show-more {
      font-size: 0.875rem; } }
  @media (max-width: 767px) {
    .project-details .project-details-header {
      flex-direction: column; }
      .project-details .project-details-header .img-wrapper {
        max-width: 100%;
        min-width: 100%;
        width: 100%; }
      .project-details .project-details-header .card {
        max-width: none; }
      .project-details .project-details-header .card-header {
        height: auto;
        padding: 80% 0 0; } }

.thead-light {
  background-color: #292934;
  color: #fff;
  vertical-align: top; }

.task-list .task,
.sidebar-task .task {
  border-top: 1px solid #e2e2e8;
  padding: 2rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 120px; }

.task-list .task-left,
.task-list .task-right,
.sidebar-task .task-left,
.sidebar-task .task-right {
  display: flex;
  align-items: center;
  justify-content: stretch; }

.task-list .task-left,
.sidebar-task .task-left {
  margin-right: 0.5rem; }

.task-list .task-left-1,
.task-list .task-right,
.sidebar-task .task-left-1,
.sidebar-task .task-right {
  display: flex;
  align-items: center;
  justify-content: stretch; }

.task-list .task-right,
.sidebar-task .task-right {
  margin-left: auto; }

.task-list .checkbox,
.sidebar-task .checkbox {
  margin-right: 1rem;
  max-width: 32px;
  min-width: 32px;
  width: 100%;
  height: 32px;
  border-radius: 100%;
  border: 2px solid #cacacd;
  display: flex;
  align-items: center;
  justify-content: center; }
  .task-list .checkbox svg,
  .sidebar-task .checkbox svg {
    color: #fff;
    max-height: 18px; }
  .task-list .checkbox.add-task,
  .sidebar-task .checkbox.add-task {
    border-style: dotted;
    border-color: #009dda;
    color: #009dda; }
    .task-list .checkbox.add-task svg,
    .sidebar-task .checkbox.add-task svg {
      width: 16px;
      height: 16px;
      color: #009dda; }
  .task-list .checkbox.checkbox-pending,
  .sidebar-task .checkbox.checkbox-pending {
    background-color: #f8c017;
    border-color: #f8c017; }
  .task-list .checkbox.checkbox-completed,
  .sidebar-task .checkbox.checkbox-completed {
    background-color: #0ac297;
    border-color: #0ac297; }

.task-list .name-comments,
.sidebar-task .name-comments {
  min-width: 180px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .task-list .name-comments a,
  .sidebar-task .name-comments a {
    -webkit-text-decoration: underline solid;
            text-decoration: underline solid;
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 0.25rem; }
    .task-list .name-comments a.add-task,
    .sidebar-task .name-comments a.add-task {
      font-weight: 300; }
  .task-list .name-comments .badge,
  .sidebar-task .name-comments .badge {
    background: #cacacd;
    color: #fff;
    text-transform: uppercase;
    line-height: 1rem;
    padding: 0.125rem 0.5rem;
    font-size: 0.75rem; }

.task-list .date-role,
.sidebar-task .date-role {
  min-width: 110px; }
  .task-list .date-role p,
  .sidebar-task .date-role p {
    font-weight: 700;
    margin-bottom: 0.25rem; }
  .task-list .date-role a,
  .sidebar-task .date-role a {
    color: #009dda; }
    .task-list .date-role a:hover,
    .sidebar-task .date-role a:hover {
      color: #009dda; }
    .task-list .date-role a span,
    .sidebar-task .date-role a span {
      color: #000; }

.task-list .task-actions,
.sidebar-task .task-actions {
  margin-left: 0;
  display: flex;
  min-width: 64px; }
  .task-list .task-actions .btn-link,
  .sidebar-task .task-actions .btn-link {
    color: #000; }
  .task-list .task-actions .btn,
  .sidebar-task .task-actions .btn {
    padding: 0.25rem; }
    .task-list .task-actions .btn:last-child,
    .sidebar-task .task-actions .btn:last-child {
      padding-right: 0; }
  .task-list .task-actions .delete-btn:hover,
  .sidebar-task .task-actions .delete-btn:hover {
    color: #df674c; }
  .task-list .task-actions .edit-btn:hover,
  .sidebar-task .task-actions .edit-btn:hover {
    color: #458bd6; }

@media (max-width: 1200px) {
  .task-list .task,
  .sidebar-task .task {
    padding: 1.5rem 1rem;
    min-height: 100px; }
  .task-list .checkbox,
  .sidebar-task .checkbox {
    margin-right: 0.75rem; }
  .task-list .task-actions .btn svg,
  .sidebar-task .task-actions .btn svg {
    max-width: 20px;
    height: auto; } }

@media (max-width: 991px) {
  .task-list .name-comments,
  .sidebar-task .name-comments {
    min-width: 160px; } }

@media (max-width: 575px) {
  .task-list .task,
  .sidebar-task .task {
    flex-direction: column;
    align-items: flex-start; }
  .task-list .task-left,
  .task-list .task-right,
  .sidebar-task .task-left,
  .sidebar-task .task-right {
    width: 100%; }
  .task-list .task-left,
  .sidebar-task .task-left {
    margin: 0; }
  .task-list .task-right,
  .sidebar-task .task-right {
    margin: 1rem 0 0;
    padding-top: 1rem;
    border-top: 1px dotted #e2e2e8; }
  .task-list .task-actions,
  .sidebar-task .task-actions {
    margin-left: auto; } }

.user-badge {
  padding: 0.125rem 0.5rem 0.125rem 0.125rem;
  height: 24px;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  background-color: #f3f3f4;
  font-size: 0.625rem; }
  .user-badge img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin: 0 0.375rem 0 0; }

.project-details-body {
  display: flex;
  justify-content: stretch; }
  .project-details-body .title-wrapper {
    padding: 1.5rem; }
    .project-details-body .title-wrapper h6 {
      font-weight: 700;
      margin-bottom: 0; }
  .project-details-body .details-body-sidebar {
    max-width: 290px;
    min-width: 290px;
    width: 100%;
    margin: 0 0 0 auto; }
  .project-details-body .details-body-main {
    width: 100%; }
  @media (max-width: 1200px) {
    .project-details-body .details-body-main {
      max-width: 455px; }
    .project-details-body .details-body-sidebar {
      max-width: 250px;
      min-width: 250px;
      width: 100%; } }
  @media (max-width: 991px) {
    .project-details-body .details-body-main {
      max-width: none; }
    .project-details-body .details-body-sidebar {
      max-width: 220px;
      min-width: 220px;
      width: 100%; } }
  @media (max-width: 767px) {
    .project-details-body {
      flex-direction: column; }
      .project-details-body .details-body-sidebar {
        max-width: 100%;
        min-width: 100%;
        width: 100%; } }

.salesforce_tasks {
  width: 300px; }

.details-body-sidebar {
  border-left: 1px solid #e2e2e8; }
  .details-body-sidebar .sidebar-item {
    padding: 1.25rem; }
    .details-body-sidebar .sidebar-item:not(:last-child) {
      border-bottom: 1px solid #e2e2e8; }
    .details-body-sidebar .sidebar-item .expert-card {
      margin-bottom: 0 !important; }
    .details-body-sidebar .sidebar-item .btn-wrapper {
      justify-content: center; }
      .details-body-sidebar .sidebar-item .btn-wrapper .btn {
        font-size: 0.875rem;
        margin: 0 0.25rem; }
        .details-body-sidebar .sidebar-item .btn-wrapper .btn svg {
          width: auto;
          height: 16px; }
    .details-body-sidebar .sidebar-item .card {
      box-shadow: none;
      width: 100% !important; }
      .details-body-sidebar .sidebar-item .card .card-header {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); }
    .details-body-sidebar .sidebar-item progress {
      border-radius: 1px;
      height: 30px;
      width: 100%;
      margin-top: 0.25rem;
      display: block; }
    .details-body-sidebar .sidebar-item progress::-webkit-progress-bar {
      background-color: transparent;
      border: 1px solid #dfdfe1;
      overflow: hidden;
      border-radius: 1px; }
    .details-body-sidebar .sidebar-item progress::-webkit-progress-value {
      height: 30px;
      background: #009dda; }
    .details-body-sidebar .sidebar-item progress::-moz-progress-bar {
      height: 30px;
      background: #009dda;
      border-radius: 1px;
      border: 1px solid #dfdfe1; }
    .details-body-sidebar .sidebar-item .badge {
      border-radius: 7px;
      padding: 0.25rem 0.375rem;
      display: block; }
  @media (max-width: 1200px) {
    .details-body-sidebar .sidebar-item {
      padding: 1.25rem 1rem; } }
  @media (max-width: 767px) {
    .details-body-sidebar {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      border: none;
      border-top: 1px solid #e2e2e8; }
      .details-body-sidebar .sidebar-item:nth-child(odd) {
        border-right: 1px solid #e2e2e8; } }
  @media (max-width: 575px) {
    .details-body-sidebar {
      grid-template-columns: repeat(1, 1fr); } }

.supporting-materials .supporting-material {
  display: flex;
  justify-content: stretch;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 0 -1rem;
  transition: all 230ms ease;
  cursor: pointer; }
  .supporting-materials .supporting-material .icon {
    margin-right: 1.125rem; }
    .supporting-materials .supporting-material .icon svg {
      margin: auto; }
  .supporting-materials .supporting-material .action {
    margin: 0 0 0 auto;
    color: #009dda;
    pointer-events: none;
    transition: all 230ms ease; }
  .supporting-materials .supporting-material:hover {
    background-color: #d9f1fa; }
    .supporting-materials .supporting-material:hover .action {
      visibility: visible;
      opacity: 1;
      pointer-events: all; }

.feedback-component {
  margin-bottom: 1.5rem; }
  .feedback-component #card-link {
    margin-bottom: 0 !important; }
  .feedback-component label.d-block + .field-wrapper {
    margin-top: 1rem; }

.rating-component {
  padding: 1.5rem;
  background-color: #fff; }
  .rating-component .checkbox-wrapper label span {
    font-size: 1rem; }
  .rating-component .field-wrapper {
    margin-top: 1.5rem; }
    .rating-component .field-wrapper .datepicker-wrapper {
      flex: 1 1; }
      .rating-component .field-wrapper .datepicker-wrapper .react-datepicker-wrapper {
        width: 100%; }
    .rating-component .field-wrapper p {
      width: 100%; }
  .rating-component .rating-wrapper:not(:last-of-type) {
    margin-bottom: 1.25rem; }
  .rating-component .rating-wrapper h5 {
    font-size: 1.125rem;
    margin-bottom: 0.25rem; }
  .rating-component .rating-wrapper svg {
    height: 22px;
    width: 22px; }

.mock-tasks.disabled, .mock-tasks.cursor-none {
  pointer-events: none;
  filter: grayscale(100); }
  .mock-tasks.disabled *, .mock-tasks.cursor-none * {
    pointer-events: none;
    filter: grayscale(100); }
  .mock-tasks.disabled p,
  .mock-tasks.disabled svg,
  .mock-tasks.disabled a, .mock-tasks.cursor-none p,
  .mock-tasks.cursor-none svg,
  .mock-tasks.cursor-none a {
    color: rgba(41, 41, 52, 0.5); }

@media (max-width: 1200px) {
  .project-details .img-wrapper {
    margin-bottom: 0 !important; }
  .project-details #card-link {
    margin-bottom: 0 !important; } }

.nl-form {
  text-align: center;
  background: #fff;
  padding: 1.25rem; }
  .nl-form h4 {
    font-weight: 300;
    line-height: 1.75rem;
    margin-bottom: 0.625rem; }
    .nl-form h4 a,
    .nl-form h4 .dropdown-toggle {
      color: #009dda;
      -webkit-text-decoration: underline dotted;
              text-decoration: underline dotted; }
    .nl-form h4 .dropdown-wrapper {
      min-width: 1px;
      width: auto; }
      .nl-form h4 .dropdown-wrapper .dropdown-menu {
        max-height: 225px;
        overflow-y: auto;
        min-width: 245px;
        outline: none; }
      .nl-form h4 .dropdown-wrapper.select-dropdown .dropdown-toggle {
        max-width: 100%;
        white-space: normal;
        display: inline-block; }
      .nl-form h4 .dropdown-wrapper.checkbox-dropdown .dropdown-toggle {
        text-decoration: none; }
        .nl-form h4 .dropdown-wrapper.checkbox-dropdown .dropdown-toggle span:not(.d-inline) {
          color: #009dda;
          -webkit-text-decoration: underline dotted;
                  text-decoration: underline dotted; }
        .nl-form h4 .dropdown-wrapper.checkbox-dropdown .dropdown-toggle span.d-inline {
          color: #000; }
    .nl-form h4 .dropdown-toggle {
      font-weight: 300;
      padding: 0;
      font-size: inherit; }
  .nl-form .btn-wrapper {
    margin: 1.125rem 0 1rem; }
  .nl-form .show-more-filters {
    display: flex;
    align-items: center;
    flex-direction: column; }
    .nl-form .show-more-filters .btn {
      justify-content: center;
      text-align: center;
      padding: 0; }
    .nl-form .show-more-filters span {
      font-size: 0.875rem; }
    .nl-form .show-more-filters .circle-arrow-down {
      width: 42px;
      height: 42px;
      border-radius: 100%;
      border: 2px solid #009dda;
      color: #009dda;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto -45px;
      background-color: #fff; }
      .nl-form .show-more-filters .circle-arrow-down svg {
        width: 19px;
        height: 19px; }
    .nl-form .show-more-filters.filters-active {
      margin: 1.125rem 0; }
      .nl-form .show-more-filters.filters-active .circle-arrow-down {
        margin-bottom: 0; }
  .nl-form + .card-list-wrapper,
  .nl-form + .tab-content-wrapper {
    margin-top: 2rem; }
  .nl-form + .inline-alert.alert {
    margin-top: 2rem; }
  .nl-form + .modal-body {
    border-top: 1px solid #dfdfe1; }

.autocomplete-dropdown-container {
  position: absolute;
  top: 100%;
  margin-top: 7px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 2; }
  .autocomplete-dropdown-container .suggestion-item,
  .autocomplete-dropdown-container .suggestion-item--active {
    margin: 0.5rem 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    text-align: left; }
  .autocomplete-dropdown-container .suggestion-item--active {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }

.filters-wrapper + .modal-body {
  border-top: 1px solid #dfdfe1; }

.filters-wrapper {
  margin: 2rem 0 1.5rem 0;
  display: flex;
  flex-wrap: wrap; }
  .filters-wrapper .badge {
    margin: 0 0.5rem 0.5rem 0;
    display: inline-flex;
    align-items: center;
    border: none;
    box-shadow: none;
    background-color: #00b3d3;
    border-radius: 7px;
    padding: 0.25rem 0.5rem;
    outline: none;
    font-weight: 700; }
    .filters-wrapper .badge svg {
      margin-left: 0.5rem;
      max-width: 12px;
      max-height: 16px;
      pointer-events: none; }

.inline-alert {
  display: inline-flex;
  width: 100%; }
  .inline-alert.alert {
    margin: 0 0 2rem; }
  .inline-alert.alert-light {
    border-radius: 0; }
    .inline-alert.alert-light svg {
      color: #009dda; }
    .inline-alert.alert-light span {
      margin: 0 0 0 0.75rem;
      color: #009dda; }

.alerts-wrapper {
  margin-bottom: 1.5rem; }
  .alerts-wrapper .alert {
    margin-bottom: 0;
    border-radius: 0; }
    .alerts-wrapper .alert.alert-danger {
      background-color: #e96d61; }
    .alerts-wrapper .alert.alert-success {
      background-color: #0ac297; }
    .alerts-wrapper .alert.alert-info {
      background-color: #009dda; }
    .alerts-wrapper .alert .btn {
      white-space: nowrap; }

.alert.alert-dark-info {
  background-color: #335262;
  padding: 2rem 2rem 2rem 2.5rem; }
  .alert.alert-dark-info .btn {
    font-size: 0.875rem;
    min-width: 160px; }

.alert.alert-light-info {
  background-color: #fff;
  color: #292934; }

.alert.alert-dark-success {
  background-color: #0ac297;
  border-color: #0ac297; }

.modal .modal-content {
  border: none; }

.modal .modal-header {
  padding: 1rem 0; }

.modal .modal-header {
  justify-content: center;
  padding: 0 0 1rem; }
  .modal .modal-header .close {
    padding: 0;
    margin: 0; }

.modal .modal-body {
  padding: 1rem 0; }

.modal .modal-footer {
  padding: 1.5rem 1rem 0;
  justify-content: center; }

.modal .close {
  position: absolute;
  right: 14px;
  top: 14px;
  font-size: 30px;
  font-weight: 300;
  color: #000;
  opacity: 1; }
  .modal .close span {
    line-height: 1;
    display: block;
    width: 34px;
    height: 34px; }
  .modal .close:hover {
    opacity: 1; }

.modal .datepicker {
  font-size: 0.875rem; }

.modal .modal-body-subtitle {
  border-bottom: 1px solid #dee2e6;
  padding: 0 0 1.5rem;
  margin-bottom: 1.5rem; }
  .modal .modal-body-subtitle p {
    margin-bottom: 0; }

.modal .nl-form {
  position: relative;
  z-index: 2; }

.crop-modal-body,
.modal-content {
  padding: 1.75rem 1.5rem 1.5rem;
  border-radius: 0.75rem;
  background-color: #fff;
  max-width: 510px;
  z-index: 2; }

.crop-modal-body {
  margin: 0 1.5rem; }

.success-modal-popup.modal-dialog {
  max-width: 570px;
  width: 100%; }

.success-modal-popup .modal-content {
  max-width: none;
  min-height: 368px; }

.crop-modal {
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center; }
  .crop-modal .backdrop {
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.15); }
  .crop-modal .crop-modal-body {
    position: relative;
    padding: 3.5rem 6.25rem; }
    .crop-modal .crop-modal-body .crop-modal-close {
      position: absolute;
      right: 14px;
      top: 14px;
      font-size: 25px;
      color: #000;
      opacity: 0.8;
      cursor: pointer;
      transition: all 180ms ease; }
      .crop-modal .crop-modal-body .crop-modal-close:hover {
        opacity: 1; }

.crop-modal,
.crop-modal .backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.help-modal {
  margin-top: 0.625rem; }
  .help-modal .text-link svg {
    width: 18px;
    height: 18px;
    margin-right: 0.625rem; }

.modal-header-wrapper svg {
  width: 44px;
  height: 44px; }

.add-task-modal .modal-content {
  min-width: 600px; }

.edit-task-modal {
  min-width: 504px; }
  @media (max-width: 630px) {
    .edit-task-modal {
      min-width: 350px; } }
  .edit-task-modal .modal-header {
    margin: -1.75rem -1.5rem 0;
    padding: 1.75rem 1.5rem 1.5rem;
    background: #f3f3f4;
    justify-content: center; }
    .edit-task-modal .modal-header .modal-title {
      display: flex;
      align-items: center;
      justify-content: center; }
      .edit-task-modal .modal-header .modal-title h5 {
        margin: 0 0.75rem 0 0;
        font-weight: 700; }
      .edit-task-modal .modal-header .modal-title h4,
      .edit-task-modal .modal-header .modal-title h5 {
        text-align: center;
        margin: 0; }
      .edit-task-modal .modal-header .modal-title .task-actions .btn {
        margin-top: -0.125rem;
        color: #000; }
  .edit-task-modal.login-modal .modal-header {
    margin: 0; }
  .edit-task-modal .modal-body {
    padding: 1.75rem 0.5rem 0; }
    .edit-task-modal .modal-body .sidebar-item {
      display: flex;
      justify-content: space-between; }
      .edit-task-modal .modal-body .sidebar-item .field-wrapper p {
        margin-bottom: 0.25rem; }
      .edit-task-modal .modal-body .sidebar-item .field-wrapper span {
        width: auto; }
  .edit-task-modal .field-wrapper label {
    width: 100%; }
  .edit-task-modal .dropdown-wrapper {
    width: 100%; }
  .edit-task-modal .form-wrapper .grouped-fields {
    grid-template-columns: 60% 35%; }
    .edit-task-modal .form-wrapper .grouped-fields .field-wrapper:not(:last-child) {
      margin-bottom: 0; }
  .edit-task-modal .btn-gray {
    background-color: #f3f3f4; }

.kickoff-preview-modal {
  min-width: 820px; }
  .kickoff-preview-modal .modal-header {
    padding: 2.1875rem;
    background: #009dda;
    color: #fff; }
    .kickoff-preview-modal .modal-header .close {
      color: #fff; }
  .kickoff-preview-modal.modal-dialog {
    max-width: 820px; }
  .kickoff-preview-modal .modal-content {
    max-width: none; }
  .kickoff-preview-modal .modal-body {
    padding: 0;
    margin: 0 -1.5rem -1.5rem; }
  .kickoff-preview-modal .content {
    margin: 0; }
  .kickoff-preview-modal .split-content-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: flex-start; }
    .kickoff-preview-modal .split-content-wrapper .split-content {
      flex-basis: 50%;
      margin: auto 0; }
    .kickoff-preview-modal .split-content-wrapper .content-wrapper {
      padding: 3.75rem 2.5rem 3.75rem 3.75rem; }

.add-payment-options-modal.modal-dialog {
  max-width: 991px !important; }

.edit-billing-modal.modal-dialog {
  max-width: 585px;
  width: 100%; }
  @media (max-width: 630px) {
    .edit-billing-modal.modal-dialog {
      width: auto; } }

.edit-billing-modal .modal-content {
  max-width: 100%; }

.edit-billing-modal .modal-body {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.edit-billing-modal .form-wrapper .field-wrapper:not(:last-child) {
  margin-bottom: 0; }

.interested-in-project-modal .modal-header {
  background-color: #0ac297;
  color: #fff;
  padding: 2.75rem 1.5rem 2.5rem; }

.interested-in-project-modal .form-wrapper {
  padding: 1rem 0 0;
  margin: 0 -0.5rem;
  border-top: 1px solid #dee2e6;
  text-align: left; }
  .interested-in-project-modal .form-wrapper .form-group {
    margin-bottom: 0; }
  .interested-in-project-modal .form-wrapper textarea {
    min-height: 120px; }

.interested-in-project-modal .close span {
  color: #fff; }

.edit-timeline-modal .form-wrapper .grouped-fields {
  grid-template-columns: 50% 50%; }

.log-hours-modal {
  min-width: 456px; }
  .log-hours-modal .modal-header .modal-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }
  .log-hours-modal .field-wrapper {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 300;
    margin: 0.25rem 0 0.75rem; }
    .log-hours-modal .field-wrapper span {
      margin: 0 0.5rem; }
    .log-hours-modal .field-wrapper .hours-input {
      max-width: 70px;
      font-size: 0.875rem;
      background: #f3f3f4;
      border-radius: 5px; }
    .log-hours-modal .field-wrapper .datepicker-wrapper input {
      border: none; }
    .log-hours-modal .field-wrapper .datepicker-wrapper span {
      margin: 0; }
    .log-hours-modal .field-wrapper input {
      border: 1px solid #009dda; }
  .log-hours-modal .modal-body {
    display: flex;
    justify-content: center; }
  .log-hours-modal .btn-wrapper {
    margin: 0.75rem 0 0; }
    .log-hours-modal .btn-wrapper .btn {
      margin: 0 0.25rem;
      font-size: 0.875rem; }

.modal .expert-card-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem; }
  .modal .expert-card-list .expert-card {
    margin: 0 !important; }
    .modal .expert-card-list .expert-card .card {
      min-height: 370px !important; }

.active-alert-modal .form-wrapper .field-wrapper {
  justify-content: stretch;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem; }
  .active-alert-modal .form-wrapper .field-wrapper .datepicker-wrapper .react-datepicker-wrapper {
    width: 100%; }

.expert-modal {
  min-width: 850px; }
  @media (max-width: 991px) {
    .expert-modal {
      min-width: 1px;
      width: 100%; } }
  .expert-modal .modal-content {
    max-width: 100%; }
    .expert-modal .modal-content .modal-body {
      padding: 1.75rem 0.5rem;
      max-height: 245px;
      overflow: auto; }
  .expert-modal .simple-expert-card-list {
    overflow-y: visible; }

.kickoff-modal {
  min-width: 850px; }
  @media (max-width: 991px) {
    .kickoff-modal {
      min-width: 1px;
      width: 100%; } }
  .kickoff-modal .modal-content {
    max-width: 100%; }
    .kickoff-modal .modal-content .modal-body {
      padding: 1.75rem 0.5rem;
      max-height: 500px;
      overflow: auto; }
  .kickoff-modal .simple-expert-card-list {
    overflow-y: visible; }

.login-modal .modal-content {
  padding: 0; }

.login-modal .modal-header {
  background: #f3f3f5; }

.login-modal .okta-login-modal {
  background: #ffffff; }

.login-modal #okta-sign-in {
  margin: 40px auto 8px; }

.login-modal .modal-body {
  padding: 4.375rem; }
  .login-modal .modal-body .subtitle {
    margin-top: -2.375rem;
    margin-bottom: 2rem; }

.login-modal .signup-buttons {
  margin: 0; }

.login-modal .form-wrapper .field-wrapper:not(:last-child) {
  margin-bottom: 0; }

.login-modal .field-wrapper .form-group .accepted-terms {
  width: auto; }

.login-modal .accepted-terms {
  padding-bottom: 0; }

.edit-client-modal .form-wrapper .field-wrapper .form-group {
  width: 100%;
  margin: 0; }

.pay-invoice-modal {
  min-width: 570px; }
  .pay-invoice-modal .modal-header {
    margin: -1.75rem -1.5rem 0;
    padding: 1.75rem 1.5rem 1.5rem;
    justify-content: center;
    text-align: center;
    border-bottom: 0px; }
    .pay-invoice-modal .modal-header .subtext {
      font-size: 1.1rem; }

.team-members {
  display: flex;
  flex-direction: column; }
  .team-members .member {
    display: flex;
    justify-content: stretch;
    align-items: center; }
    .team-members .member:not(:last-child) {
      margin-bottom: 1rem; }
  .team-members .avatar {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    overflow: hidden;
    min-width: 48px;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #009dda;
    color: #fff; }
    .team-members .avatar path {
      fill: currentColor; }
  .team-members p {
    margin-bottom: 0;
    white-space: normal;
    overflow: hidden;
    padding-right: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
    .team-members p:hover span {
      background-color: #fff;
      position: relative; }
    .team-members p span {
      margin-left: 1rem;
      position: relative; }
  .team-members .actions {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto; }
    .team-members .actions .btn {
      margin-left: 0.5rem; }
  .team-members .dropdown-toggle {
    color: rgba(41, 41, 52, 0.5); }

.discussions-body {
  padding: 0 1.5rem; }
  .discussions-body .discussion-input-wrapper textarea {
    resize: none;
    background: #f3f3f4;
    max-height: 80px;
    min-height: 40px;
    padding-right: 115px; }
  .discussions-body .field-wrapper.position-relative .discussion-actions {
    height: 30px;
    position: absolute;
    right: 9px;
    top: 0px;
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    z-index: 3; }
    .discussions-body .field-wrapper.position-relative .discussion-actions .draftJsEmojiPlugin__emojiSelectButton__3sPol,
    .discussions-body .field-wrapper.position-relative .discussion-actions .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
      width: auto;
      height: auto;
      padding: 0;
      border-radius: 0;
      border: none;
      display: block;
      line-height: 1;
      background: transparent;
      font-size: 1.5rem;
      color: rgba(0, 0, 0, 0.25); }
    .discussions-body .field-wrapper.position-relative .discussion-actions label {
      margin-top: 0.5rem; }
    .discussions-body .field-wrapper.position-relative .discussion-actions .discussion-action {
      padding: 0 0.5rem;
      border: none;
      background: transparent;
      outline: none; }
      .discussions-body .field-wrapper.position-relative .discussion-actions .discussion-action svg {
        max-width: 22px;
        max-height: 22px;
        width: 100%;
        height: 100%;
        color: rgba(0, 0, 0, 0.25); }
    .discussions-body .field-wrapper.position-relative .discussion-actions img {
      margin: auto; }

.datepicker {
  background: #f3f3f4;
  border: none;
  height: auto;
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  outline: none;
  cursor: default; }

.react-date-picker .react-date-picker__wrapper {
  border: none !important; }
  .react-date-picker .react-date-picker__wrapper .react-date-picker__inputGroup input {
    pointer-events: none; }
  .react-date-picker .react-date-picker__wrapper .react-date-picker__inputGroup .react-date-picker__inputGroup__input:invalid {
    background: none; }
  .react-date-picker .react-date-picker__wrapper input {
    pointer-events: none; }
  .react-date-picker .react-date-picker__wrapper input:focus,
  .react-date-picker .react-date-picker__wrapper button:focus {
    outline: none; }

.react-calendar {
  width: 386px; }
  .react-calendar .react-calendar__navigation {
    background-color: #df674c;
    color: #fff;
    font-size: 1.25rem;
    font-family: "Muli", sans-serif;
    padding: 30px 30px 30px 30px !important;
    height: 80px;
    margin-bottom: 10px; }
    .react-calendar .react-calendar__navigation .react-calendar__navigation__prev2-button,
    .react-calendar .react-calendar__navigation .react-calendar__navigation__next2-button {
      display: none; }
    .react-calendar .react-calendar__navigation .react-calendar__navigation__prev-button {
      color: #fff;
      margin-left: 10px; }
      .react-calendar .react-calendar__navigation .react-calendar__navigation__prev-button:hover, .react-calendar .react-calendar__navigation .react-calendar__navigation__prev-button:focus {
        background-color: #df674c; }
    .react-calendar .react-calendar__navigation .react-calendar__navigation__next-button {
      margin-right: 10px;
      color: #fff; }
      .react-calendar .react-calendar__navigation .react-calendar__navigation__next-button:hover, .react-calendar .react-calendar__navigation .react-calendar__navigation__next-button:focus {
        background-color: #df674c; }
    .react-calendar .react-calendar__navigation .react-calendar__navigation__label {
      color: #fff; }
      .react-calendar .react-calendar__navigation .react-calendar__navigation__label:hover, .react-calendar .react-calendar__navigation .react-calendar__navigation__label:focus {
        background-color: #df674c; }
    .react-calendar .react-calendar__navigation button[disabled] {
      color: #d3d3d3;
      background-color: transparent; }
  .react-calendar .react-calendar__viewContainer {
    padding: 10px; }
    .react-calendar .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__weekdays {
      line-height: auto;
      border-bottom: 1px solid #b8b8bd;
      padding-bottom: 10px;
      margin-bottom: 10px; }
      .react-calendar .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr[title],
      .react-calendar .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr[data-original-title] {
        text-decoration: none;
        cursor: auto; }
    .react-calendar .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__days .react-calendar__tile {
      padding: 10px 10px 10px 10px;
      width: 50px;
      height: 47px;
      border-radius: 50%; }
      .react-calendar .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__days .react-calendar__tile:enabled:hover {
        background-color: #0ac297;
        color: #fff; }
    .react-calendar .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__days .react-calendar__tile--active {
      background-color: #009dda; }

.datepicker-wrapper .react-datepicker-popper[data-placement^="bottom"] .react-datepicker {
  width: 100%; }

.datepicker-wrapper .react-datepicker-popper .react-datepicker .react-datepicker__triangle {
  border-bottom-color: #df674c; }
  .datepicker-wrapper .react-datepicker-popper .react-datepicker .react-datepicker__triangle:before {
    border-bottom-color: #df674c; }

.datepicker-wrapper .react-datepicker {
  border: none;
  border-radius: 0; }

.react-datepicker .react-datepicker__header {
  border-radius: 0;
  background-color: #df674c;
  border-bottom: 0;
  padding-top: 0; }

.react-datepicker .react-datepicker__header--time {
  padding: 1rem 0.5rem; }

.react-datepicker .react-datepicker-time__header {
  color: #fff; }

.react-datepicker .react-datepicker__current-month {
  color: #fff;
  font-size: 1.25rem;
  font-family: "Muli", sans-serif;
  font-weight: 400;
  padding: 2rem 4rem 2rem; }

.react-datepicker .react-datepicker__navigation {
  top: 2px;
  width: 24px;
  height: 24px;
  margin: 2rem 0 0; }
  .react-datepicker .react-datepicker__navigation:after {
    text-indent: 0;
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    left: 0; }

.react-datepicker .react-datepicker__navigation--previous {
  border: none; }
  .react-datepicker .react-datepicker__navigation--previous:after {
    content: url(/static/media/icon-chevron-left-white.fa0cefa4.svg); }

.react-datepicker .react-datepicker__navigation--next {
  border: none; }
  .react-datepicker .react-datepicker__navigation--next:after {
    content: url(/static/media/icon-chevron-right-white.e5aa2e1c.svg); }

.react-datepicker .react-datepicker__day-names {
  background-color: #fff; }

.react-datepicker .react-datepicker__day-name,
.react-datepicker .react-datepicker__day,
.react-datepicker .react-datepicker__time-name {
  width: 36px;
  height: 36px;
  margin: 0 2px;
  padding: 4px; }

.react-datepicker .react-datepicker__day {
  border-radius: 100%; }
  .react-datepicker .react-datepicker__day:hover {
    border-radius: 100%; }

.react-datepicker .react-datepicker__day-names {
  border-bottom: 1px solid #b8b8bd; }

.react-datepicker .react-datepicker__day--selected {
  background-color: #009dda; }

.checkbox-wrapper {
  padding: 0.5rem; }
  .checkbox-wrapper label {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0;
    font-size: 0.875rem; }
    @media (max-width: 1200px) {
      .checkbox-wrapper label {
        font-size: 0.75rem; } }
    .checkbox-wrapper label input {
      margin-top: 0;
      display: none; }
    .checkbox-wrapper label .checkbox-placeholder {
      min-width: 20px;
      width: 20px;
      height: 20px;
      border: 2px solid rgba(0, 0, 0, 0.5);
      border-radius: 3px;
      margin: 0.25rem 0.5rem 0 0; }
      .checkbox-wrapper label .checkbox-placeholder.active {
        border-color: #1290fb;
        background: #1290fb; }
        .checkbox-wrapper label .checkbox-placeholder.active:after {
          content: " ";
          background: url(/static/media/icon-check-white.14ba30fe.svg) no-repeat center center;
          background-size: 18px;
          display: block;
          width: 100%;
          height: 100%; }
    .checkbox-wrapper label .checkbox-placeholder-black {
      min-width: 20px;
      width: 20px;
      height: 20px;
      border: 2px solid rgba(0, 0, 0, 0.5);
      border-radius: 3px;
      margin: 0.25rem 0.5rem 0 0; }
      .checkbox-wrapper label .checkbox-placeholder-black.active {
        border-color: #000000;
        background: #000000; }
        .checkbox-wrapper label .checkbox-placeholder-black.active:after {
          content: " ";
          background: url(/static/media/icon-check-white.14ba30fe.svg) no-repeat center center;
          background-size: 18px;
          display: block;
          width: 100%;
          height: 100%; }

.package-deliverables .checkbox-wrapper label {
  font-size: 1rem;
  font-weight: 700; }
  .package-deliverables .checkbox-wrapper label .checkbox-placeholder {
    margin-top: 0;
    width: 24px;
    height: 24px;
    margin-right: 1.5rem; }
    .package-deliverables .checkbox-wrapper label .checkbox-placeholder.active {
      background-color: #009dda; }
    .package-deliverables .checkbox-wrapper label .checkbox-placeholder:after {
      background-size: 18px; }

.card-statistics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  margin-bottom: 1.5rem; }
  @media screen and (max-width: 767px) {
    .card-statistics {
      grid-template-columns: repeat(2, 1fr); } }
  .card-statistics .card-statistic {
    padding: 1.5rem 0;
    margin: 0.625rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    @media screen and (max-width: 767px) {
      .card-statistics .card-statistic {
        margin-left: 5px;
        margin-right: 5px; } }
    .card-statistics .card-statistic:nth-child(1) {
      background-color: #0098cd; }
    .card-statistics .card-statistic:nth-child(2) {
      background-color: #d88219; }
    .card-statistics .card-statistic:nth-child(3) {
      background-color: #4c2683; }
    .card-statistics .card-statistic:nth-child(4) {
      background-color: #69be5a; }
    @media screen and (min-width: 768px) {
      .card-statistics .card-statistic:not(:last-child) {
        margin-right: 16px;
        border-right: 1px solid #e2e2e8; } }
    .card-statistics .card-statistic h2,
    .card-statistics .card-statistic h6 {
      color: #fff; }
    .card-statistics .card-statistic h6 {
      font-weight: 700; }
    .card-statistics .card-statistic svg {
      width: 38px;
      height: 38px; }

.border-curved {
  border-radius: 0.625rem; }
  .border-curved h2,
  .border-curved h6 {
    color: #000 !important; }
  .border-curved:nth-child(1) {
    background-color: #e3f2f9 !important; }
  .border-curved:nth-child(2) {
    background-color: #fcf4e6 !important; }
  .border-curved:nth-child(3) {
    background-color: #eff4eb !important; }
  .border-curved:nth-child(4) {
    background-color: #ebe6f2 !important; }

.report-card-statistics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  margin-bottom: 1.5rem;
  min-height: 190px; }
  @media screen and (max-width: 767px) {
    .report-card-statistics {
      grid-template-columns: repeat(3, 1fr); } }
  .report-card-statistics .card-statistic {
    padding: 1.5rem 0;
    margin: 0.625rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    @media screen and (max-width: 767px) {
      .report-card-statistics .card-statistic {
        margin-left: 5px;
        margin-right: 5px; } }
    @media screen and (min-width: 768px) {
      .report-card-statistics .card-statistic:not(:last-child) {
        margin-right: 16px;
        border-right: 1px solid #e2e2e8; } }
    .report-card-statistics .card-statistic h2,
    .report-card-statistics .card-statistic h6 {
      color: #458bd6; }
    .report-card-statistics .card-statistic h6 {
      color: #000;
      font-weight: 700; }
    .report-card-statistics .card-statistic svg {
      color: #458bd6;
      width: 38px;
      height: 38px; }

.course-card-statistics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  margin-bottom: 1.5rem;
  min-height: 190px; }
  @media screen and (max-width: 767px) {
    .course-card-statistics {
      grid-template-columns: repeat(1, 1fr); } }
  .course-card-statistics .card-statistic {
    padding: 1.5rem 0;
    margin: 0.625rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    @media screen and (max-width: 767px) {
      .course-card-statistics .card-statistic {
        margin-left: 5px;
        margin-right: 5px; } }
    @media screen and (min-width: 768px) {
      .course-card-statistics .card-statistic:not(:last-child) {
        margin-right: 16px;
        border-right: 1px solid #e2e2e8; } }
    .course-card-statistics .card-statistic h2,
    .course-card-statistics .card-statistic h6 {
      color: #458bd6; }
    .course-card-statistics .card-statistic h2 {
      font-size: 38px;
      color: #009dda; }
    .course-card-statistics .card-statistic h6 {
      color: #000;
      font-weight: 700;
      font-size: 18px; }
    .course-card-statistics .card-statistic svg {
      color: #458bd6;
      width: 38px;
      height: 38px; }

.list-view-card {
  display: grid;
  justify-content: center;
  margin-bottom: 1.5rem; }
  @media only screen and (min-width: 768px) {
    .list-view-card {
      grid-template-columns: repeat(2, 1fr); } }
  @media screen and (max-width: 767px) {
    .list-view-card {
      grid-template-columns: repeat(1, 1fr); } }
  .list-view-card .card {
    margin-bottom: 0.6rem; }
    @media only screen and (min-width: 768px) {
      .list-view-card .card:nth-child(odd) {
        margin-right: 0.3rem; }
      .list-view-card .card:nth-child(even) {
        margin-left: 0.3rem; } }
    @media screen and (max-width: 767px) {
      .list-view-card .card {
        width: 100%; } }
  .list-view-card .card-statistic {
    padding: 1.5rem 0;
    margin: 0.625rem 0; }
    .list-view-card .card-statistic:not(:last-child) {
      border-right: 1px solid #e2e2e8; }
    .list-view-card .card-statistic h2,
    .list-view-card .card-statistic h6 {
      color: #292934; }
    .list-view-card .card-statistic h6 {
      font-weight: 700; }

.card-count-statistics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  margin-bottom: 1.5rem; }
  @media screen and (max-width: 767px) {
    .card-count-statistics {
      grid-template-columns: repeat(2, 2fr) !important; } }
  .card-count-statistics .card-statistic {
    padding: 1.5rem 0;
    margin: 0.625rem 0; }
    .card-count-statistics .card-statistic:not(:last-child) {
      border-right: 1px solid #e2e2e8; }
      @media (max-width: 630px) {
        .card-count-statistics .card-statistic:not(:last-child) {
          border-right: 0px solid #e2e2e8; } }
    .card-count-statistics .card-statistic h6 {
      color: #292934; }
    .card-count-statistics .card-statistic h6 {
      font-weight: 700; }

.app-card-count-statistics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  margin-bottom: 1rem; }
  @media screen and (max-width: 767px) {
    .app-card-count-statistics {
      grid-template-columns: repeat(2, 2fr) !important; } }
  .app-card-count-statistics .card-statistic {
    padding: 1.2rem 0; }
    .app-card-count-statistics .card-statistic:not(:last-child) {
      border-right: 1px solid #e2e2e8; }
      @media (max-width: 630px) {
        .app-card-count-statistics .card-statistic:not(:last-child) {
          border-right: 0px solid #e2e2e8; } }
    .app-card-count-statistics .card-statistic h6 {
      color: #292934; }
    .app-card-count-statistics .card-statistic h6 {
      font-weight: 700; }

.expert-onboarding {
  margin-bottom: 1.5rem; }

.quick-link {
  background: #fff;
  padding: 2.5rem 2.5rem 0 2.5rem;
  display: flex;
  justify-content: stretch;
  align-items: center;
  color: #000; }
  .quick-link .profile-completing svg {
    width: 45px;
    height: 45px; }

.quick-link-account-mapping {
  background: #fff;
  padding: 0rem 2.5rem 0 2.5rem;
  display: flex;
  justify-content: stretch;
  align-items: center;
  color: #000; }

.need-attention {
  padding: 0 2.5rem 0 2.5rem; }
  @media only screen and (min-width: 1200px) {
    .need-attention {
      width: 650px; } }
  @media screen and (min-width: 991px) and (max-width: 1200px) {
    .need-attention {
      width: 520px; } }
  @media screen and (max-width: 990px) {
    .need-attention {
      width: 480px; } }
  @media screen and (max-width: 767px) {
    .need-attention {
      width: auto; } }

.partner-task {
  padding: 1.5rem 2.5rem 1.5rem 0 !important; }

.partner-onboarding {
  padding: 1.5rem 2.5rem 1.5rem 0 !important;
  display: flex;
  color: #000;
  text-align: center;
  justify-content: center;
  align-items: center; }
  .partner-onboarding:hover {
    text-decoration: none;
    color: #000; }

.onboarding-link {
  background: #fff;
  padding: 2.5rem 2.5rem 2.5rem 0;
  display: flex;
  justify-content: stretch;
  align-items: center;
  color: #000;
  cursor: pointer; }
  .onboarding-link:hover {
    text-decoration: none;
    background: #f2fafd;
    color: #000; }
  .onboarding-link:not(:last-child) {
    border-bottom: 1px solid #e2e2e8; }
  .onboarding-link .onboarding-header {
    min-width: 180px;
    max-width: 180px;
    display: flex;
    justify-content: center; }
    .onboarding-link .onboarding-header .icon-wrapper {
      align-self: center; }
      .onboarding-link .onboarding-header .icon-wrapper svg {
        margin-top: -20px;
        margin-left: 10px; }
  .onboarding-link .onboarding-icon {
    min-width: 180px;
    max-width: 180px;
    display: flex;
    justify-content: center; }
    @media (max-width: 670px) {
      .onboarding-link .onboarding-icon {
        min-width: 70px;
        max-width: 180px; } }
    .onboarding-link .onboarding-icon .icon-wrapper {
      align-self: center; }
      .onboarding-link .onboarding-icon .icon-wrapper svg {
        margin-top: -20px;
        margin-left: 10px; }
  .onboarding-link .step-wrapper {
    display: flex;
    align-items: stretch;
    margin: 0 auto; }
    .onboarding-link .step-wrapper .step-count {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      margin: auto; }
  .onboarding-link .onboarding-url {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: auto 0 auto auto; }
  .onboarding-link .onboarding-main p:last-child {
    margin-bottom: 0; }
  .onboarding-link .availability {
    -webkit-text-decoration: underline solid;
            text-decoration: underline solid;
    margin-bottom: -1.25rem;
    display: block; }
    .onboarding-link .availability.not-available {
      color: #e86c61; }
      .onboarding-link .availability.not-available:before {
        background-color: #e86c61; }
    .onboarding-link .availability.partially-available {
      color: #bd9732; }
      .onboarding-link .availability.partially-available:before {
        background-color: #bd9732; }
    .onboarding-link .availability.available {
      color: #0ac297; }
      .onboarding-link .availability.available:before {
        background-color: #0ac297; }
    .onboarding-link .availability:before {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      content: " ";
      display: inline-flex;
      margin-right: 0.5rem; }
  .onboarding-link .completion-gauge {
    margin: 0 0 -10px auto;
    max-width: 150px;
    min-width: 150px;
    width: 150px; }
    .onboarding-link .completion-gauge .feather {
      height: 44px;
      width: 44px;
      color: #009dda; }

#incoming-projects-tooltip {
  margin-bottom: 1.5rem; }

.incoming-project {
  position: relative; }
  .incoming-project .project-list {
    margin-top: 0; }
  .incoming-project .new-message,
  .incoming-project .new-alert-message,
  .incoming-project .card-body,
  .incoming-project .field-wrapper,
  .incoming-project .project-timeline {
    opacity: 0.5;
    cursor: pointer;
    position: relative;
    z-index: 1; }
    .incoming-project .new-message:hover,
    .incoming-project .new-alert-message:hover,
    .incoming-project .card-body:hover,
    .incoming-project .field-wrapper:hover,
    .incoming-project .project-timeline:hover {
      opacity: 1; }
  .incoming-project .card-header:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: -1px;
    width: 100%;
    content: " ";
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 0; }

.how-it-works {
  padding: 1.5rem 0 0;
  margin: 0 0 1.5rem;
  background-color: #fff;
  border-top: 1px solid #e2e2e8; }
  .how-it-works .how-it-works-item {
    display: flex;
    justify-content: stretch;
    align-items: center; }
    .how-it-works .how-it-works-item:not(:last-child) {
      margin-bottom: 80px; }
    .how-it-works .how-it-works-item:not(.item-right) {
      padding-right: 70px; }
    .how-it-works .how-it-works-item.item-right {
      flex-direction: row-reverse;
      padding-left: 70px; }
      .how-it-works .how-it-works-item.item-right .img-wrapper {
        margin-left: 60px;
        margin-right: 0; }
    .how-it-works .how-it-works-item .img-wrapper {
      margin-right: 60px; }
      .how-it-works .how-it-works-item .img-wrapper img {
        max-width: 400px; }
    .how-it-works .how-it-works-item .img-placeholder {
      width: 400px;
      height: 300px;
      background: #e2e2e8; }
    .how-it-works .how-it-works-item .content-wrapper h5 {
      font-weight: 700;
      margin-bottom: 1.25rem;
      position: relative; }
    .how-it-works .how-it-works-item .inline-number {
      font-size: 0.75rem;
      color: #df674c;
      line-height: 1;
      display: block;
      text-transform: uppercase; }
    .how-it-works .how-it-works-item .title,
    .how-it-works .how-it-works-item .inline-number {
      position: relative;
      z-index: 2; }
    .how-it-works .how-it-works-item .number {
      width: 61px;
      height: 126px;
      position: absolute;
      top: -63px;
      left: -30px;
      font-size: 100px;
      color: #f5eee5;
      font-weight: 700;
      display: block;
      opacity: 0.3;
      z-index: 0; }

.set-expert-visibility {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin: 0 0 1.5rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 11;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1); }
  .set-expert-visibility .switch-wrapper p {
    margin-bottom: 0 !important;
    font-size: 1rem;
    min-width: 24px; }
  .set-expert-visibility .switch-wrapper h6 {
    color: rgba(41, 41, 52, 0.5);
    font-weight: 300; }
  .set-expert-visibility .btn {
    font-size: 0.875rem; }

@media (min-width: 992px) {
  .expert-details-sidebar {
    max-width: 240px;
    min-width: 240px;
    width: 100%;
    position: relative; }
    .expert-details-sidebar .sidebar-content .upload-image {
      margin: -1.25rem -1.25rem 1.25rem !important; }
    .expert-details-sidebar .upload-image .avatar-placeholder,
    .expert-details-sidebar .upload-image .avatar-picture {
      padding-top: 73%; }
    .expert-details-sidebar .upload-image .profile-picture-preview {
      width: auto;
      height: auto;
      max-width: 100%;
      border-radius: 0 !important; }
    .expert-details-sidebar .form-wrapper .field-wrapper:not(:last-child) {
      margin-bottom: 0.75rem; }
    .expert-details-sidebar .form-wrapper .field-wrapper .form-group label {
      color: rgba(41, 41, 52, 0.5); }
    .expert-details-sidebar .form-group {
      margin-bottom: 0; } }

@media (max-width: 992px) {
  .expert-details-sidebar {
    max-width: 240px;
    min-width: 240px;
    width: 100%;
    position: relative; }
    .expert-details-sidebar .sidebar-content .upload-image {
      margin: -1.25rem -1.25rem 1.25rem !important; }
    .expert-details-sidebar .sidebar-content-box {
      width: 330px; }
    .expert-details-sidebar .upload-image .avatar-placeholder,
    .expert-details-sidebar .upload-image .avatar-picture {
      padding-top: 73%; }
    .expert-details-sidebar .upload-image .profile-picture-preview {
      width: auto;
      height: auto;
      max-width: 100%;
      border-radius: 0 !important; }
    .expert-details-sidebar .form-wrapper .field-wrapper:not(:last-child) {
      margin-bottom: 0.75rem; }
    .expert-details-sidebar .form-wrapper .field-wrapper .form-group label {
      color: rgba(41, 41, 52, 0.5); }
    .expert-details-sidebar .form-group {
      margin-bottom: 0; } }

@media (max-width: 992px) {
  .expert-details-sidebar {
    min-width: 240px;
    width: 100%;
    position: relative; }
    .expert-details-sidebar .sidebar-content .upload-image {
      margin: -1.25rem -1.25rem 1.25rem !important; }
    .expert-details-sidebar .upload-image .avatar-placeholder,
    .expert-details-sidebar .upload-image .avatar-picture {
      padding-top: 73%; }
    .expert-details-sidebar .upload-image .profile-picture-preview {
      width: auto;
      height: auto;
      max-width: 100%;
      border-radius: 0 !important; }
    .expert-details-sidebar .form-wrapper .field-wrapper:not(:last-child) {
      margin-bottom: 0.75rem; }
    .expert-details-sidebar .form-wrapper .field-wrapper .form-group label {
      color: rgba(41, 41, 52, 0.5); }
    .expert-details-sidebar .form-group {
      margin-bottom: 0; } }

.edit-user-profile .datepicker {
  font-size: 0.875rem; }

.edit-user-profile .marketplace-section-content {
  z-index: 0; }

.edit-user-profile .edit-btn {
  position: absolute;
  z-index: 2;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  padding: 2px;
  color: #000; }
  .edit-user-profile .edit-btn svg {
    width: 100%;
    height: 100%; }

.edit-user-profile textarea {
  min-height: 200px; }

.edit-user-profile .form-wrapper .field-wrapper {
  position: relative; }
  .edit-user-profile .form-wrapper .field-wrapper .form-group {
    margin-bottom: 0.125rem; }
    .edit-user-profile .form-wrapper .field-wrapper .form-group:last-of-type {
      margin-right: 0; }
  .edit-user-profile .form-wrapper .field-wrapper .invalid-feedback {
    top: 100%;
    margin-top: -0.275rem;
    font-size: 0.675rem; }
  .edit-user-profile .form-wrapper .field-wrapper:not(:last-child) {
    margin-bottom: 1rem; }

.edit-user-profile .error-messages {
  background-color: #e86c61;
  color: #fff;
  padding: 1.25rem;
  border: 1px solid #e2e2e8;
  font-size: 1rem;
  position: -webkit-sticky;
  position: sticky;
  z-index: 11;
  top: 72px;
  margin-bottom: 1.5rem; }

.alert-banner {
  border: 1px solid #e2e2e8;
  display: grid;
  grid-template-columns: repeat(4, auto);
  background-color: #e96d61; }
  @media (max-width: 630px) {
    .alert-banner {
      border: 1px solid #e2e2e8;
      display: block;
      grid-template-columns: repeat(4, auto); } }
  .alert-banner.options-list {
    display: flex;
    flex-wrap: wrap;
    border: none; }
    .alert-banner.options-list ul {
      margin: auto; }
    .alert-banner.options-list label.alert-option {
      padding: 0;
      border: none; }
    .alert-banner.options-list .card-logo {
      margin-top: 1rem; }
      .alert-banner.options-list .card-logo svg {
        margin: 0; }
    .alert-banner.options-list label .alert-option:before {
      content: " ";
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      border-radius: 100%;
      border: 2px solid #b8b8bd;
      margin-right: 1.5rem; }
    .alert-banner.options-list label input[type="radio"]:checked + .alert-option:before {
      border-color: #009dda;
      background-color: #009dda;
      box-shadow: inset 0 0 0 4px white; }
  .alert-banner .alert-option {
    padding: 1rem 2rem 0.5rem;
    background-color: #e96d61;
    color: white; }
    @media (max-width: 630px) {
      .alert-banner .alert-option {
        padding: 1rem 2rem 0.5rem;
        background-color: #e96d61;
        color: white; } }

.alert-icon {
  padding: 1.3rem 0rem 0.5rem;
  background-color: #e96d61;
  max-width: 45px !important;
  color: white; }

@media (max-width: 630px) {
  .alert-icon {
    display: none; } }

.account-banner {
  border: 1px solid #e2e2e8;
  display: grid;
  grid-template-columns: repeat(3, auto); }
  @media (max-width: 630px) {
    .account-banner {
      border: 1px solid #e2e2e8;
      display: block;
      grid-template-columns: repeat(3, auto); } }
  .account-banner.options-list {
    display: flex;
    flex-wrap: wrap;
    border: none; }
    .account-banner.options-list ul {
      margin: auto; }
    .account-banner.options-list label.payment-option {
      padding: 0;
      border: none; }
    .account-banner.options-list .card-logo {
      margin-top: 1rem; }
      .account-banner.options-list .card-logo svg {
        margin: 0; }
    .account-banner.options-list label .payment-option:before {
      content: " ";
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      border-radius: 100%;
      border: 2px solid #b8b8bd;
      margin-right: 1.5rem; }
    .account-banner.options-list label input[type="radio"]:checked + .payment-option:before {
      border-color: #009dda;
      background-color: #009dda;
      box-shadow: inset 0 0 0 4px white; }
  .account-banner .payment-option {
    padding: 1rem 1rem 0.5rem; }

.team-banner {
  display: grid;
  grid-template-columns: repeat(5, auto);
  border-bottom: none; }
  @media (max-width: 630px) {
    .team-banner {
      display: block;
      grid-template-columns: repeat(5, auto);
      border-bottom: 1px solid; } }
  .team-banner .team-option {
    padding: 1rem 1rem 0.5rem;
    padding-top: none;
    overflow: hidden; }
    @media (max-width: 630px) {
      .team-banner .team-option {
        padding: 0rem 1rem 0.5rem;
        padding-top: 1rem; } }

.team-emal {
  width: 400px; }
  @media (max-width: 620px) {
    .team-emal {
      width: 300px; } }

.payment-options {
  border: 1px solid #e2e2e8;
  display: grid;
  grid-template-columns: repeat(4, 1fr); }
  @media (max-width: 630px) {
    .payment-options {
      border: 1px solid #e2e2e8;
      display: block;
      grid-template-columns: repeat(4, 1fr); } }
  .payment-options.options-list {
    display: flex;
    flex-wrap: wrap;
    border: none; }
    .payment-options.options-list ul {
      margin: auto; }
    .payment-options.options-list label.payment-option {
      padding: 0;
      border: none; }
    .payment-options.options-list .payment-option {
      padding: 1.25rem;
      width: 100%;
      min-height: 139px;
      display: flex;
      align-items: center;
      justify-content: stretch;
      text-align: left;
      border: 1px solid #e2e2e8; }
      .payment-options.options-list .payment-option:not(:last-child) {
        margin-bottom: 1.5rem; }
      .payment-options.options-list .payment-option .btn-wrapper {
        margin: auto 0 auto auto; }
        .payment-options.options-list .payment-option .btn-wrapper .btn {
          display: flex;
          align-items: center; }
          .payment-options.options-list .payment-option .btn-wrapper .btn svg {
            margin: 0;
            width: 24px;
            height: auto; }
    .payment-options.options-list .card-logo {
      margin-top: 1rem; }
      .payment-options.options-list .card-logo svg {
        margin: 0; }
    .payment-options.options-list label .payment-option:before {
      content: " ";
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      border-radius: 100%;
      border: 2px solid #b8b8bd;
      margin-right: 1.5rem; }
    .payment-options.options-list label input[type="radio"]:checked + .payment-option:before {
      border-color: #009dda;
      background-color: #009dda;
      box-shadow: inset 0 0 0 4px white; }
  .payment-options .payment-option {
    padding: 3rem 1rem 2.5rem;
    text-align: center;
    border-right: 1px solid #e2e2e8; }
    .payment-options .payment-option button:hover svg {
      stroke: #00668e; }
    .payment-options .payment-option svg {
      margin: 0 auto 0.25rem;
      max-height: 20px;
      width: auto;
      stroke: #292934; }

.billing-details {
  justify-content: stretch;
  align-items: flex-start; }

.billing-details .btn,
.payment-methods-wrapper .btn {
  font-size: 0.875rem;
  padding-top: 0;
  margin-left: 1.25rem; }
  .billing-details .btn svg,
  .payment-methods-wrapper .btn svg {
    stroke: #292934; }
  .billing-details .btn:hover svg,
  .payment-methods-wrapper .btn:hover svg {
    stroke: currentColor; }

.edit-user-profile .Toastify,
.accounting-settings .Toastify {
  position: -webkit-sticky;
  position: sticky;
  top: 91px;
  z-index: 2;
  margin: 0 0 1.5rem; }
  .edit-user-profile .Toastify__toast-container--relative,
  .accounting-settings .Toastify__toast-container--relative {
    position: relative;
    padding: 0;
    width: 100%; }
  .edit-user-profile .Toastify__toast-body,
  .accounting-settings .Toastify__toast-body {
    display: flex;
    align-items: center;
    justify-content: stretch; }
    .edit-user-profile .Toastify__toast-body svg,
    .accounting-settings .Toastify__toast-body svg {
      margin-right: 0.75rem; }
  .edit-user-profile .Toastify__toast,
  .accounting-settings .Toastify__toast {
    padding: 1.25rem; }
    .edit-user-profile .Toastify__toast--success,
    .accounting-settings .Toastify__toast--success {
      background-color: #0ac297; }
    .edit-user-profile .Toastify__toast--error,
    .accounting-settings .Toastify__toast--error {
      background-color: #e86c61; }
    .edit-user-profile .Toastify__toast--warning,
    .accounting-settings .Toastify__toast--warning {
      background-color: #fec12f; }
  .edit-user-profile .Toastify__close-button,
  .accounting-settings .Toastify__close-button {
    font-size: 0;
    font-weight: 300;
    position: relative; }
    .edit-user-profile .Toastify__close-button:before, .edit-user-profile .Toastify__close-button:after,
    .accounting-settings .Toastify__close-button:before,
    .accounting-settings .Toastify__close-button:after {
      position: absolute;
      left: 0;
      content: " ";
      height: 20px;
      width: 2px;
      background-color: #fff;
      top: -8px; }
    .edit-user-profile .Toastify__close-button:before,
    .accounting-settings .Toastify__close-button:before {
      transform: rotate(45deg); }
    .edit-user-profile .Toastify__close-button:after,
    .accounting-settings .Toastify__close-button:after {
      transform: rotate(-45deg); }

.accounting-settings .Toastify {
  top: 0; }

.lets-talk .btn {
  padding: 0.75rem; }
  .lets-talk .btn svg {
    margin-right: 0.5rem; }

.card-2x2-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  margin-bottom: 1.5rem; }

.elite-resource-wrapper {
  background-color: #5b44f8;
  width: 33px;
  height: 33px;
  padding: 3px; }
  .elite-resource-wrapper svg {
    width: 27px;
    height: 25px;
    fill: #fff; }

.open-deal-item {
  border-bottom: 1px solid #e2e2e8; }

@media only screen and (min-width: 1200px) {
  .deal-status-border {
    width: 50px; } }

@media screen and (min-width: 320px) and (max-width: 767px) {
  .deal-status-border {
    width: 35px; } }

@media screen and (min-width: 991px) and (max-width: 1200px) {
  .deal-status-border {
    width: 40px; } }

@media screen and (min-width: 768px) and (max-width: 990px) {
  .deal-status-border {
    width: 40px; } }

@media screen and (max-width: 320px) {
  .deal-status-border {
    width: 40px; } }

.profile-percentage-pending {
  border: 2px solid #9a9a9a; }

.profile-percentage-completed {
  background: #0ac297;
  color: #fff; }

.crossbeam-icon svg {
  width: 30px;
  height: 30px;
  color: lightskyblue; }

.crossbeam-logo svg {
  width: 120px;
  height: 35px; }

.switch-wrapper {
  display: flex;
  justify-content: stretch;
  align-items: center; }
  .switch-wrapper h6 {
    margin-left: 0.5rem;
    margin-bottom: 0; }

.switch {
  position: relative;
  width: 80px;
  height: 40px;
  outline: none;
  margin-bottom: 0; }
  .switch input {
    visibility: hidden;
    display: none; }
  .switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f3f3f4;
    border: 1px solid #e2e2e8;
    transition: 280ms;
    border-radius: 34px; }
    .switch .slider::before {
      position: absolute;
      content: "";
      height: 30px;
      width: 30px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: 280ms;
      border-radius: 100%;
      border: 1px solid #e2e2e8; }
  .switch input:checked + .slider {
    background-color: #0ac297;
    border-color: #0ac297; }
  .switch input:focus + .slider {
    box-shadow: 0 0 1px #0ac297; }
  .switch input:checked + .slider::before {
    transform: translateX(40px); }

.switch-wrapper.small .switch {
  width: 32px;
  height: 20px; }
  .switch-wrapper.small .switch .slider::before {
    height: 12px;
    width: 12px;
    left: 3px;
    bottom: 2px; }

@media (max-width: 992px) {
  .switch-wrapper.small .switch {
    width: 45px;
    height: 20px; }
    .switch-wrapper.small .switch .slider {
      width: 35px;
      left: auto; }
      .switch-wrapper.small .switch .slider::before {
        height: 12px;
        width: 12px;
        left: 3px;
        bottom: 2px; } }

.switch-wrapper.primary.active p {
  color: #009dda; }

.switch-wrapper.primary p {
  color: rgba(41, 41, 52, 0.5);
  margin-right: 0.5rem;
  font-weight: 400 !important; }

.switch-wrapper.primary .switch input:checked + .slider {
  background-color: #009dda; }

.switch-wrapper.primary .switch input:focus + .slider {
  box-shadow: 0 0 1px #009dda; }

.switch-wrapper.primary.outlined .switch .slider {
  background-color: transparent;
  border: 2px solid #ccc; }
  .switch-wrapper.primary.outlined .switch .slider:before {
    background-color: #ccc; }

.switch-wrapper.primary.outlined .switch input:checked + .slider {
  background-color: transparent;
  border: 2px solid #009dda; }
  .switch-wrapper.primary.outlined .switch input:checked + .slider:before {
    background-color: #009dda;
    transform: translateX(11px); }

.site-footer {
  background-color: #f8f6ed; }
  .site-footer #footer-logo {
    max-width: 136px;
    margin: 0 0 1rem;
    display: block; }
    .site-footer #footer-logo svg {
      width: 100%;
      height: auto; }

.footer-widgets {
  flex-wrap: wrap; }
  .footer-widgets .footer-widget {
    max-width: 300px;
    min-width: 155px;
    margin-top: 1rem; }
    .footer-widgets .footer-widget:not(:last-child) {
      margin-bottom: 1.5rem; }

.footer-widget p {
  line-height: 1.25rem; }

.footer-widget dl dt {
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 1rem; }

.footer-widget dl dd {
  font-size: 0.875rem;
  line-height: 1.25rem; }
  .footer-widget dl dd:not(:last-child) {
    margin: 0 0 0.5rem; }

.newsletter-wrapper {
  max-width: 240px; }
  .newsletter-wrapper input {
    background: #eceae3;
    border-color: transparent;
    font-size: 0.875rem;
    height: 40px; }
  .newsletter-wrapper .btn {
    padding: 8px;
    width: 40px;
    height: 40px; }

.end-footer {
  padding: 1.25rem 0; }
  .end-footer .sidebar-content-box {
    box-shadow: none;
    padding: 0;
    background-color: transparent; }
    .end-footer .sidebar-content-box a {
      display: block; }
    .end-footer .sidebar-content-box svg {
      display: block;
      fill: #fff;
      color: #fff;
      height: 20px;
      width: 20px; }
  .end-footer .container {
    padding: 0 56px; }
  .end-footer .endfooter-wrapper {
    align-items: center; }
    .end-footer .endfooter-wrapper .social-icons {
      margin-left: auto; }
  .end-footer p {
    font-size: 0.75rem;
    margin: 0 1rem 0 0; }

.salesforce-footer {
  padding: 114px 0;
  background: #292934; }
  .salesforce-footer .container {
    display: flex;
    justify-content: stretch;
    align-items: center; }
  .salesforce-footer .text-wrapper p {
    font-size: 1.125rem; }
  .salesforce-footer .logo-wrapper {
    padding: 0 3rem 0 0;
    margin: 0 3rem 0 0;
    border-right: 1px solid #fff; }
    .salesforce-footer .logo-wrapper a {
      display: flex;
      align-items: center;
      text-decoration: none; }
    .salesforce-footer .logo-wrapper span {
      margin-left: 1.25rem; }
    .salesforce-footer .logo-wrapper img {
      max-height: 43px; }
    .salesforce-footer .logo-wrapper .h5 {
      margin: 0 0 0 1.25rem; }
  .salesforce-footer .link-wrapper {
    margin: 4rem auto 0; }
    .salesforce-footer .link-wrapper ul {
      list-style-type: none;
      display: flex;
      padding-left: 0;
      margin: 0;
      text-align: center;
      color: #fff;
      justify-content: space-between; }
      .salesforce-footer .link-wrapper ul li {
        margin: 0 1rem;
        max-width: 300px; }
        .salesforce-footer .link-wrapper ul li svg {
          margin: 0 auto 1.25rem; }
        .salesforce-footer .link-wrapper ul li a {
          color: #009dda;
          font-size: 19px;
          text-decoration: underline;
          margin-bottom: 1rem;
          display: block; }
        .salesforce-footer .link-wrapper ul li p {
          margin-bottom: 0;
          font-weight: 500;
          font-size: 1rem; }
  @media (max-width: 1200px) {
    .salesforce-footer .container {
      flex-wrap: wrap; }
    .salesforce-footer .logo-wrapper {
      margin: auto auto 2rem; }
    .salesforce-footer .link-wrapper {
      margin: auto; }
      .salesforce-footer .link-wrapper ul {
        flex-wrap: wrap;
        justify-content: center; }
        .salesforce-footer .link-wrapper ul li:first-child {
          margin-left: 0; } }

.pre-footer {
  background-color: #fbf8f3; }

.marketplace-landing + .site-footer .container {
  padding-right: 15px;
  padding-left: 15px; }

.marketplace-landing + .site-footer .footer-widgets {
  justify-content: space-between !important; }
  .marketplace-landing + .site-footer .footer-widgets .footer-widget {
    margin: 0 2rem 0 0 !important; }

.marketplace-landing {
  min-height: 100vh;
  background: #fff; }

.salesforce-playbooks .section-content {
  margin: 0 -15px; }

.salesforce-playbooks .playbook-wrapper {
  width: 100%; }
  .salesforce-playbooks .playbook-wrapper div[class*="col"],
  .salesforce-playbooks .playbook-wrapper a[class*="col"] {
    padding: 0; }

.salesforce-playbooks .playbook-card-link {
  display: block; }

.salesforce-playbooks .card {
  display: flex;
  flex-direction: row; }

.salesforce-playbooks .lazyload-wrapper {
  max-width: 265px;
  width: 100%; }

.salesforce-playbooks .card-header {
  max-width: 265px;
  width: 100%;
  height: 258px;
  background-position: center center; }

.salesforce-playbooks .body-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch; }
  .salesforce-playbooks .body-wrapper .card-body {
    padding: 1.875rem; }
    .salesforce-playbooks .body-wrapper .card-body p {
      margin-bottom: 0.25rem; }
      .salesforce-playbooks .body-wrapper .card-body p.card-title {
        line-height: 1.5rem; }
  .salesforce-playbooks .body-wrapper .card-footer {
    margin-top: auto;
    padding: 0.5rem 2rem; }

.salesforce-playbooks .card-footer {
  display: flex;
  justify-content: stretch; }
  .salesforce-playbooks .card-footer p {
    margin: 0; }
  .salesforce-playbooks .card-footer a {
    margin-left: auto;
    display: flex;
    text-decoration: none;
    color: #009dda;
    font-weight: 600; }
    .salesforce-playbooks .card-footer a svg {
      stroke: #000; }

.salesforce-playbooks .playbook-description {
  line-height: 1.125rem;
  font-weight: 300; }

@media (max-width: 1200px) {
  .salesforce-playbooks .card-header {
    height: auto; } }

@media (max-width: 991px) {
  .salesforce-playbooks .playbook-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 0; }
    .salesforce-playbooks .playbook-wrapper div[class*="col"],
    .salesforce-playbooks .playbook-wrapper a[class*="col"] {
      padding-right: 15px;
      padding-left: 15px; }
  .salesforce-playbooks .card {
    flex-direction: column; }
  .salesforce-playbooks .card-header {
    min-height: 222px; }
  .salesforce-playbooks .body-wrapper .card-body {
    padding: 1.25rem; }
  .salesforce-playbooks .body-wrapper .card-footer {
    padding: 0.5rem 1.25rem;
    align-items: center; }
    .salesforce-playbooks .body-wrapper .card-footer a,
    .salesforce-playbooks .body-wrapper .card-footer p {
      font-size: 0.75rem; }
    .salesforce-playbooks .body-wrapper .card-footer a {
      align-items: center; }
    .salesforce-playbooks .body-wrapper .card-footer svg {
      margin-left: 0.125rem;
      width: 1rem;
      height: 1rem; }
  .salesforce-playbooks .landing-page-sidebar .sidebar-cta {
    padding: 1.125rem; }
    .salesforce-playbooks .landing-page-sidebar .sidebar-cta .btn {
      padding: 0.5rem 0.25rem;
      font-size: 0.75rem; } }

@media (max-width: 575px) {
  .salesforce-playbooks .section-content {
    margin: 0; }
  .salesforce-playbooks .card-header {
    max-width: none; }
  .salesforce-playbooks .landing-page-sidebar {
    flex-wrap: wrap;
    padding: 0; }
    .salesforce-playbooks .landing-page-sidebar .sidebar-cta {
      flex: 0 0 50%;
      min-height: 1px;
      width: 50%;
      margin: 0; } }

@media (max-width: 460px) {
  .salesforce-playbooks .landing-page-sidebar {
    flex-direction: row; }
    .salesforce-playbooks .landing-page-sidebar .sidebar-cta {
      width: calc(100% - 30px);
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(100% - 30px);
      flex-wrap: wrap;
      margin: 1.5rem 15px 0;
      min-height: 330px; }
      .salesforce-playbooks .landing-page-sidebar .sidebar-cta .btn {
        font-size: 1rem; } }

.landing-page-sidebar {
  display: flex;
  flex-direction: column; }
  .landing-page-sidebar .sidebar-cta {
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    min-height: 280px;
    color: #fff;
    align-items: center;
    margin: 0 0 1.5rem;
    background-image: url(/static/media/header-triangles.3b655ed2.svg); }
    .landing-page-sidebar .sidebar-cta .content-wrapper {
      margin: auto;
      text-align: center; }
      .landing-page-sidebar .sidebar-cta .content-wrapper svg {
        width: 40px;
        height: auto;
        stroke: #fff;
        margin: 0 auto; }
      .landing-page-sidebar .sidebar-cta .content-wrapper span {
        display: block;
        margin: 0.675rem 0 0; }
    .landing-page-sidebar .sidebar-cta .btn-wrapper {
      margin-bottom: 0; }
    .landing-page-sidebar .sidebar-cta.purple {
      background-color: #9aa4e4;
      background-position: top left; }
    .landing-page-sidebar .sidebar-cta.green {
      background-color: #0ac297;
      background-position: top right; }
    .landing-page-sidebar .sidebar-cta.blue {
      background-color: #01a1e1;
      background-position: center center; }
    .landing-page-sidebar .sidebar-cta.pale-blue {
      background-color: #69bada;
      background-position: bottom right; }
    .landing-page-sidebar .sidebar-cta .btn-wrapper {
      width: 100%; }
    .landing-page-sidebar .sidebar-cta .btn {
      font-size: 1.25rem;
      width: 100%;
      border-width: 2px; }
  @media (max-width: 1200px) {
    .landing-page-sidebar .sidebar-cta {
      min-height: 258px; }
      .landing-page-sidebar .sidebar-cta .btn {
        font-size: 0.875rem; } }

.kickoff-scheduler {
  padding: 1.25rem;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem; }
  .kickoff-scheduler__communication-method {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem; }
    .kickoff-scheduler__communication-method p {
      margin: 0; }
    .kickoff-scheduler__communication-method .dropdown-wrapper {
      justify-content: flex-start !important;
      margin-left: 1rem; }
    .kickoff-scheduler__communication-method .dropdown-toggle:after {
      content: "";
      display: none; }
    .kickoff-scheduler__communication-method .btn {
      background-color: #f3f3f4; }
  .kickoff-scheduler__timezone {
    padding: 0.625rem 1.25rem;
    border: 1px solid #e2e2e8;
    margin-bottom: 0.625rem; }
    .kickoff-scheduler__timezone p {
      margin: 0; }
    .kickoff-scheduler__timezone .btn {
      margin-left: 1rem;
      border: none; }
  .kickoff-scheduler__wrapper {
    position: relative;
    overflow: hidden; }
  .kickoff-scheduler__timepicker {
    display: flex;
    flex-wrap: nowrap;
    transition: transform 0.4s ease; }
  .kickoff-scheduler__scroll-btn {
    border: none;
    background-color: transparent;
    color: #fff;
    position: absolute;
    top: 6px;
    z-index: 2; }
    .kickoff-scheduler__scroll-btn--left {
      left: 0; }
    .kickoff-scheduler__scroll-btn--right {
      right: 0; }
    .kickoff-scheduler__scroll-btn svg {
      width: 30px;
      height: auto; }
  .kickoff-scheduler__days {
    flex: 0 0 auto;
    width: calc(100% / 3);
    margin: 0 3px 0 0; }
  .kickoff-scheduler__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-transform: uppercase;
    border: 1px solid #e2e2e8;
    margin: 0; }
    .kickoff-scheduler__list--expert {
      grid-template-columns: repeat(1, 1fr); }
  .kickoff-scheduler__list-item {
    padding: 0.75rem 0.625rem;
    border-bottom: 1px solid #e2e2e8;
    margin-bottom: -1px; }
    .kickoff-scheduler__list-item:nth-child(odd) {
      border-right: 1px solid #e2e2e8; }
  .kickoff-scheduler .checkbox-wrapper {
    padding: 0; }
  .kickoff-scheduler .checkbox-placeholder {
    margin-top: 0 !important; }
  .kickoff-scheduler__checkbox--radio + .radio-placeholder {
    border-radius: 100% !important; }
    .kickoff-scheduler__checkbox--radio + .radio-placeholder:after {
      background: #1290fb !important;
      border-radius: 100%;
      border: 3px solid #fff; }
  .kickoff-scheduler__checkbox:checked + .checkbox-placeholder {
    border-color: #1290fb;
    background: #1290fb; }
    .kickoff-scheduler__checkbox:checked + .checkbox-placeholder:after {
      content: " ";
      background: url(/static/media/icon-check-white.14ba30fe.svg) no-repeat center center;
      background-size: 18px;
      display: block;
      width: 100%;
      height: 100%; }
  .kickoff-scheduler__label {
    margin-bottom: 0; }
  .kickoff-scheduler__header {
    padding: 0.625rem 0;
    background-color: #87878c;
    text-align: center;
    font-weight: 700;
    color: #fff; }
  .kickoff-scheduler__button {
    border: none;
    background-color: #dbdbe3;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    width: 100%;
    margin: 3px 0 0; }
    .kickoff-scheduler__button:active, .kickoff-scheduler__button:focus {
      outline: none; }
    .kickoff-scheduler__button svg {
      height: 22px;
      width: 22px; }
  .kickoff-scheduler__btn-wrapper.disabled {
    pointer-events: all;
    opacity: 1; }
    .kickoff-scheduler__btn-wrapper.disabled .btn {
      pointer-events: none;
      opacity: 0.4; }
      .kickoff-scheduler__btn-wrapper.disabled .btn.btn-outline-secondary {
        pointer-events: all; }
  @media (max-width: 767px) {
    .kickoff-scheduler__header {
      font-size: 0.875rem; }
    .kickoff-scheduler__communication-method .dropdown-wrapper {
      min-width: 1px; }
    .kickoff-scheduler__label span {
      margin: auto 0; }
    .kickoff-scheduler__scroll-btn svg {
      width: 20px; }
    .kickoff-scheduler__scroll-btn--left {
      left: -8px; }
    .kickoff-scheduler__scroll-btn--right {
      right: -8px; }
    .kickoff-scheduler__days {
      width: calc(100% / 2); } }
  @media (max-width: 575px) {
    .kickoff-scheduler__communication-method {
      flex-wrap: wrap; }
      .kickoff-scheduler__communication-method .dropdown-wrapper {
        margin: 1rem 0 0; }
    .kickoff-scheduler__days {
      width: calc(100%); } }

.react-add-to-calendar {
  padding: 0.625rem 1.25rem;
  border: 1px solid #e2e2e8; }
  .react-add-to-calendar__button {
    font-weight: 600;
    font-size: 1rem;
    display: block; }
    .react-add-to-calendar__button span {
      display: flex;
      width: 100%;
      justify-content: space-between; }
  .react-add-to-calendar__dropdown {
    margin: 1rem 0; }
    .react-add-to-calendar__dropdown ul {
      padding: 0;
      list-style-type: none;
      display: grid;
      grid-gap: 1.25rem;
      grid-template-columns: repeat(2, 1fr); }
    .react-add-to-calendar__dropdown a {
      padding: 0.625rem 1.25rem;
      border-radius: 7px;
      display: block;
      color: #fff;
      font-weight: 600;
      font-size: 1.25rem; }
      .react-add-to-calendar__dropdown a:hover {
        color: #fff;
        text-decoration: none; }
      .react-add-to-calendar__dropdown a.apple-link {
        background-color: #292934; }
      .react-add-to-calendar__dropdown a.google-link {
        background-color: #4287f6; }
      .react-add-to-calendar__dropdown a.outlookcom-link {
        background-color: #0078d4; }
      .react-add-to-calendar__dropdown a.yahoo-link {
        background-color: #4a00a0; }
      .react-add-to-calendar__dropdown a .fa {
        font-size: 1.25rem;
        margin-right: 1.5rem;
        width: 24px; }
  @media (max-width: 1200px) {
    .react-add-to-calendar__dropdown a {
      font-size: 1rem; } }
  @media (max-width: 991px) {
    .react-add-to-calendar__dropdown a .fa {
      margin-right: 1rem; } }
  @media (max-width: 767px) {
    .react-add-to-calendar__dropdown a {
      padding: 0.5rem 0.75rem;
      font-size: 0.875rem; }
      .react-add-to-calendar__dropdown a .fa {
        width: 1rem;
        font-size: 1rem;
        margin-right: 0.625rem; } }
  @media (max-width: 575px) {
    .react-add-to-calendar__dropdown ul {
      grid-template-columns: repeat(1, 1fr); } }

.kickoff-tips__tip {
  display: flex;
  align-items: center; }
  .kickoff-tips__tip:not(:last-child) {
    margin: 0 0 1.25rem; }

.kickoff-tips__number {
  height: 50px;
  max-width: 50px;
  min-width: 50px;
  width: 100%;
  color: #009dda;
  background: rgba(0, 157, 218, 0.1);
  border-radius: 100%;
  font-size: 1.25rem;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center; }

.kickoff-tips__description {
  margin: 0 0 0 1.25rem; }
  .kickoff-tips__description--main {
    margin: 0 0 1.5rem; }

.expert-message {
  background-color: #f3f3f5;
  padding: 0.625rem;
  border-radius: 5px;
  line-height: 1.5rem;
  margin: 0 0 1.25rem; }
  .expert-message__description {
    margin-bottom: 0; }

.preview-wrapper .card-footer {
  min-height: 40px; }

.preview-wrapper .preview-full svg {
  margin-right: 1.25rem;
  width: 20px;
  height: 20px; }

.expert-list-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr); }
  .expert-list-wrapper .expert-card {
    border: 2px solid transparent !important;
    margin: 0.375rem !important; }
    .expert-list-wrapper .expert-card.selected {
      border: 2px solid #0ac297 !important; }

.left-align {
  margin-right: 0;
  margin-left: auto;
  min-width: 64px; }

.single-payment .download-pdf {
  font-size: 1rem !important;
  display: flex;
  align-items: center; }
  .single-payment .download-pdf svg {
    width: 1.125em;
    height: 1.125em;
    stroke: black;
    margin-right: 0.75em; }

.form-wrapper .field-wrapper-group {
  padding-bottom: 2.25rem; }
  .form-wrapper .field-wrapper-group:not(:last-child) {
    margin-bottom: 1.25rem;
    border-bottom: 1px solid #d8d8d8; }
  .form-wrapper .field-wrapper-group > label + .inline-tooltip-wrapper {
    margin-top: 0; }
    .form-wrapper .field-wrapper-group > label + .inline-tooltip-wrapper + .grouped-fields {
      margin-top: 1.25rem; }
  .form-wrapper .field-wrapper-group.group-outlined {
    padding: 1.25rem;
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); }
    .form-wrapper .field-wrapper-group.group-outlined .field-wrapper:not(:last-child) {
      margin-bottom: 1.25em; }

.form-wrapper .grouped-fields {
  display: grid;
  grid-template-columns: 55% 45%;
  grid-column-gap: 1.25rem; }
  .form-wrapper .grouped-fields .field-wrapper:not(:last-child) {
    margin-bottom: 1.25rem; }

.form-wrapper .field-wrapper {
  display: flex;
  flex-wrap: wrap; }
  .form-wrapper .field-wrapper .inline-field-wrapper {
    width: 50%;
    min-width: 0;
    flex: 1 1; }
    .form-wrapper .field-wrapper .inline-field-wrapper:not(:last-child) {
      margin-right: 1rem; }
    .form-wrapper .field-wrapper .inline-field-wrapper .form-group {
      width: 100%; }
  .form-wrapper .field-wrapper .form-group {
    width: 50%;
    flex: 1 1;
    min-width: 0; }
    .form-wrapper .field-wrapper .form-group.is-invalid {
      margin-bottom: 1.5rem; }
      .form-wrapper .field-wrapper .form-group.is-invalid input {
        border: 1px solid #dc3545 !important; }
      .form-wrapper .field-wrapper .form-group.is-invalid ~ .invalid-feedback {
        display: block; }
    .form-wrapper .field-wrapper .form-group:not(:last-child) {
      margin-right: 1rem; }
    .form-wrapper .field-wrapper .form-group label {
      font-size: 0.875rem;
      color: #292934; }
    .form-wrapper .field-wrapper .form-group .form-control {
      color: #292934; }
      .form-wrapper .field-wrapper .form-group .form-control:not(.is-invalid) {
        border-color: transparent; }
      .form-wrapper .field-wrapper .form-group .form-control:focus, .form-wrapper .field-wrapper .form-group .form-control:active {
        color: #292934; }
      .form-wrapper .field-wrapper .form-group .form-control::placeholder {
        color: rgba(41, 41, 52, 0.25); }
    .form-wrapper .field-wrapper .form-group .select__placeholder {
      color: rgba(41, 41, 52, 0.25); }
    .form-wrapper .field-wrapper .form-group .select__control {
      background-color: #f3f3f4;
      border-color: #f3f3f4; }
      .form-wrapper .field-wrapper .form-group .select__control .select__multi-value {
        background-color: #15b2d1 !important;
        color: #fff;
        border-radius: 5px; }
        .form-wrapper .field-wrapper .form-group .select__control .select__multi-value .select__multi-value__label {
          color: #fff; }
    .form-wrapper .field-wrapper .form-group .input-group .input-group-prepend + input {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important; }
    .form-wrapper .field-wrapper .form-group .input-group-text {
      background-color: #f3f3f4;
      color: rgba(41, 41, 52, 0.5);
      border: none; }
  .form-wrapper .field-wrapper:not(:last-child) {
    margin-bottom: 1.25rem; }
  .form-wrapper .field-wrapper p:last-child {
    margin-bottom: 0; }
  .form-wrapper .field-wrapper .file-upload label {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background: #f3f3f4;
    border: none;
    border-radius: 5px;
    font-size: 0.875rem;
    height: 40px; }
    .form-wrapper .field-wrapper .file-upload label span {
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0; }
  .form-wrapper .field-wrapper .file-upload input {
    display: none; }

.form-wrapper .expert-questions .field-wrapper {
  padding: 0.5rem 1.5rem 0.5rem 0; }
  .form-wrapper .expert-questions .field-wrapper:not(:last-child) {
    margin-bottom: 1rem !important; }
  .form-wrapper .expert-questions .field-wrapper p,
  .form-wrapper .expert-questions .field-wrapper label {
    margin-bottom: 0; }

.form-wrapper .radio-button {
  display: none; }
  .form-wrapper .radio-button + p {
    cursor: pointer; }
    .form-wrapper .radio-button + p .radio-placeholder {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      border-radius: 100%;
      border: 2px solid #b8b8bd;
      margin-right: 1.5rem; }
    .form-wrapper .radio-button + p .checkbox-placeholder {
      border-radius: 0; }
    .form-wrapper .radio-button + p:hover .radio-placeholder {
      border-color: #009dda; }
  .form-wrapper .radio-button:checked + p .radio-placeholder {
    border-color: #df674c; }
    .form-wrapper .radio-button:checked + p .radio-placeholder:before {
      content: " ";
      background-color: #df674c;
      border: 4px solid #fff;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 100%; }
  .form-wrapper .radio-button:checked + p .checkbox-placeholder {
    border-color: #009dda; }
    .form-wrapper .radio-button:checked + p .checkbox-placeholder:before {
      background-color: #009dda;
      border-radius: 0; }

.form-wrapper .radio-item .info-icon {
  margin: 1px 0 0 1rem; }
  .form-wrapper .radio-item .info-icon svg {
    color: #009dda;
    width: 16px;
    height: 16px; }

.upload-image .avatar-placeholder {
  border: 1px solid rgba(41, 41, 52, 0.25); }

.divider {
  height: 1px;
  background-color: #b8b8bd;
  width: 100%; }
  .divider + .field-wrapper {
    margin-top: 0.875rem; }

.login-form-inline-title {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin: 1.25rem 0 0.875rem; }
  .login-form-inline-title p {
    padding: 0 0.5rem;
    width: 100%;
    white-space: nowrap;
    margin: 0 0 2px;
    font-size: 0.875rem; }
  .login-form-inline-title:after, .login-form-inline-title:before {
    content: " ";
    height: 2px;
    background-color: #f3f3f4;
    width: 100%;
    margin: auto; }

.playbook-checkout span.h5 {
  margin-bottom: 1.25rem; }

.playbook-checkout .form-wrapper .field-wrapper {
  margin-bottom: 0; }
  .playbook-checkout .form-wrapper .field-wrapper label {
    font-weight: 700; }

.playbook-checkout .definition-list dd {
  font-size: 1rem; }

.playbook-checkout .payment-methods-wrapper {
  border-top: 1px solid #e2e2e8;
  padding: 2rem 0 0;
  margin: 0.875rem 0 2rem; }
  .playbook-checkout .payment-methods-wrapper .edit-btn,
  .playbook-checkout .payment-methods-wrapper > .h5 {
    display: inline-flex !important; }

.client-login-wrapper {
  width: 360px;
  margin: 0 auto; }

.basic-login-form .field-wrapper .form-group {
  margin-bottom: 0.75rem; }

.contrast-color-picker {
  display: flex; }
  .contrast-color-picker .contrast-color {
    border-radius: 5px;
    border: 1px solid #9a9a9a;
    margin-bottom: 0;
    display: flex;
    padding: 0.5rem;
    align-items: center; }
    .contrast-color-picker .contrast-color span {
      font-weight: 600;
      font-size: 0.75rem; }
    .contrast-color-picker .contrast-color .radio-button + p {
      margin: 0; }
      .contrast-color-picker .contrast-color .radio-button + p .radio-placeholder {
        margin-right: 0.5rem; }
    .contrast-color-picker .contrast-color:not(:last-child) {
      margin: 0 1rem 0 0; }

.invalid-feedback.strong-password {
  display: flex !important;
  align-items: center; }
  .invalid-feedback.strong-password #password-tooltip {
    display: block;
    margin-left: 0.5rem; }
    .invalid-feedback.strong-password #password-tooltip svg {
      width: 15px;
      height: 15px; }

.MuiDrawer-paper {
  z-index: 1050 !important;
  width: 25% !important; }

@media (max-width: 767px) {
  .MuiDrawer-paper {
    width: 100% !important; } }

@media (min-width: 768px) and (max-width: 1300px) {
  .MuiDrawer-paper {
    width: 40% !important; } }

@media (min-width: 1301px) {
  .MuiDrawer-paper {
    width: 30% !important; } }

html body .app.flex-row.align-items-center {
  height: 100vh; }

img {
  display: block;
  max-width: 100%; }

.image-center {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%); }

@media (max-width: 992px) {
  .desktop-only {
    display: none; } }

@media (max-width: 768px) {
  .desktop-only {
    display: none; } }

.p1 {
  font-size: 20px; }

.p2 {
  font-size: 18px; }

@media (min-width: 768px) {
  .font-size {
    font-size: 9px; } }

@media (min-width: 992px) {
  .font-size {
    font-size: 10px; } }

@media (min-width: 1200px) {
  .font-size {
    font-size: 10px; } }

@media (min-width: 1400px) {
  .font-size {
    font-size: 12px; } }

@media (min-width: 1800px) {
  .font-size {
    font-size: 15px; } }

.color-white {
  color: #fff; }

.cursor-pointer {
  cursor: pointer; }

.cursor-default {
  cursor: default; }

.btn.btn-link.text-underline {
  text-decoration: underline; }

.btn.btn-outline-grayed {
  border: 1px solid rgba(41, 41, 52, 0.5);
  color: rgba(41, 41, 52, 0.5); }

.text-underline {
  text-decoration: underline; }

.text-link {
  color: #009dda; }

.text-grayed {
  opacity: 0.1; }

.text-inline-grayed {
  color: rgba(41, 41, 52, 0.5); }

.text-gray-dark {
  color: #676d72; }

.text-orange {
  color: #df674c; }

.wrap-text {
  white-space: normal !important; }

.text-truncate {
  max-width: 100%; }

.h8 {
  font-size: 0.625rem; }

.h7 {
  font-size: 0.75rem; }

.h7-i {
  font-size: 0.75rem !important; }

.h6-5 {
  font-size: 0.875rem; }

.h5-5 {
  font-size: 1.3125rem;
  font-weight: 500; }
  .h5-5 strong {
    font-weight: 700; }

.h6-5-important {
  font-size: 0.875rem !important; }

.h1-5 {
  font-size: 2.25rem; }

.lh-2 {
  line-height: 2rem !important; }

.mb-4-5 {
  margin-bottom: 2.5rem !important; }

.p-3-5 {
  padding: 1.25rem !important; }

.badge-outline {
  background-color: #fff !important;
  font-weight: 700 !important; }
  .badge-outline.badge-dark {
    border: 1px solid rgba(41, 41, 52, 0.25);
    color: #292934; }

.badges .input-group:not(:last-child) {
  margin-bottom: 0.5rem; }

.badges .input-group > .input-group-prepend > .input-group-text {
  padding: 0.25rem 0.5rem; }
  .badges .input-group > .input-group-prepend > .input-group-text svg {
    width: 1rem; }

.input-group > .badge {
  align-items: center;
  display: flex;
  flex: 1 1;
  justify-content: flex-start;
  margin: 0;
  min-width: 0;
  position: relative; }
  .input-group > .badge:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }

.input-group.group-dark .input-group-text {
  background-color: #292934;
  border-color: rgba(41, 41, 52, 0.25);
  color: #fff; }
  .input-group.group-dark .input-group-text svg {
    color: currentColor; }

.blue-faded {
  background: #e5f5fb;
  color: #009dda; }

.red-faded {
  background: #fcf0ed;
  color: #e86c61; }

.green-faded {
  background: #e6f8f4;
  color: #0ac297; }

.bg-white-ivory {
  background-color: #f5eee5; }

.justify-content-stretch {
  justify-content: stretch !important; }

.definition-list dd {
  font-size: 0.875rem;
  margin: 0; }

/* Common styles for all devices */
.filter-label {
  background-color: white;
  font-size: 12px;
  padding: 6px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.filter-values {
  display: flex;
  flex-wrap: wrap; }

.filter-value {
  margin-right: 5px;
  margin-bottom: 5px; }

.badges {
  display: flex;
  background-color: black;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 0.25em 0.4em;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-right: 2px !important; }

.remove-icon {
  cursor: pointer; }

/* Media query for small devices */
@media (max-width: 767px) {
  .filter-value {
    margin-right: 0;
    margin-bottom: 5px; }
  .badges {
    display: block;
    text-align: center;
    margin-right: 0;
    color: white;
    font-size: 14px;
    margin-right: 2px !important; }
  .mobile-filter-container {
    display: flex; }
  .filter-label {
    display: inline-block;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-transform: capitalize; } }

/* Media query for medium and large devices */
@media (min-width: 768px) {
  .mobile-filter-container {
    display: flex; }
  .filter-label {
    background-color: white;
    font-size: 14px;
    padding: 6px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; } }

.filter-label {
  display: inline-block;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: capitalize; }

.dropdown-menu.show {
  display: block; }

.bg-light-grey {
  background-color: #e7e7ea; }

.intentTag .form-group {
  margin-bottom: 0px !important; }

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */ }

.react-player {
  position: absolute;
  top: 0;
  left: 0; }

.custom-backdrop {
  opacity: 0.1 !important; }

#slider-container .slick-next {
  right: 35px !important;
  z-index: 1; }

#slider-container .slick-prev {
  left: 20px !important;
  z-index: 1; }

#slider-container .slick-prev:before,
#slider-container .slick-next:before {
  font-size: 35px !important;
  color: darkgray !important; }

.color-dark-grey {
  color: darkgray; }

.find-an-expert-left-side-section-background-image .partner-logos .img-wrapper img:not(:last-child) {
  margin-right: 4rem; }

.find-an-expert-left-side-section-background-image .welcome-alternative {
  margin-bottom: 209px !important; }
  .find-an-expert-left-side-section-background-image .welcome-alternative + .partner-logos {
    margin-bottom: 27vh; }

@media screen and (min-width: 320px) and (max-width: 767px) {
  .find-an-expert-budget-right-side-section {
    padding: 7rem 2rem 7rem 2rem !important; } }

.find-an-expert-budget-right-side-section {
  padding: 7rem 7rem 7rem 7.375rem;
  margin-top: 0;
  margin-bottom: 0;
  justify-content: stretch;
  flex-wrap: nowrap !important;
  min-height: 100%; }
  .find-an-expert-budget-right-side-section .btn-wrapper {
    margin: 1.5rem 0 0; }
    .find-an-expert-budget-right-side-section .btn-wrapper .btn {
      font-size: 0.875rem;
      font-weight: bold; }
    .find-an-expert-budget-right-side-section .btn-wrapper svg {
      height: 20px;
      margin: 0 0 0 0.5rem; }
  .find-an-expert-budget-right-side-section > form {
    margin-top: auto;
    margin-bottom: auto; }
  .find-an-expert-budget-right-side-section.form-wrapper .field-wrapper {
    margin-bottom: 0; }
    .find-an-expert-budget-right-side-section.form-wrapper .field-wrapper label {
      font-weight: 700; }
  .find-an-expert-budget-right-side-section .content-wrapper {
    max-width: 484px;
    margin-bottom: 2.5rem; }
    @media (max-width: 1366px) {
      .find-an-expert-budget-right-side-section .content-wrapper.custom-width {
        margin-right: -30px; } }
    @media (max-width: 991px) {
      .find-an-expert-budget-right-side-section .content-wrapper.custom-width {
        margin-right: 0; } }
    .find-an-expert-budget-right-side-section .content-wrapper .content {
      margin-top: 1.5rem; }
  .find-an-expert-budget-right-side-section .accepted-terms {
    margin-right: 0 !important;
    padding-bottom: 0; }

.welcome-alternative span.d-flex {
  flex-wrap: wrap; }

.welcome-alternative img {
  margin: 0 1rem; }

.expert-request-active-btn-section {
  margin: 0 0 1.5rem; }
  .expert-request-active-btn-section .box {
    border-radius: 100%;
    height: 11px;
    width: 11px;
    background-color: #f3f3f4; }
    .expert-request-active-btn-section .box:not(:last-child) {
      margin: 0 0.5rem 0 0; }
    .expert-request-active-btn-section .box.active {
      background-color: #3a9eda; }

.signup-buttons {
  margin-top: 2rem; }
  .signup-buttons .icon-wrapper {
    width: 26px;
    display: block;
    margin-right: 2rem;
    height: 18px; }
    .signup-buttons .icon-wrapper svg {
      width: auto;
      display: block;
      height: 100%;
      max-width: 100%; }
  .signup-buttons .signup-button:not(:last-child) {
    margin-bottom: 0.75rem; }
  .signup-buttons .signup-button a,
  .signup-buttons .google-login {
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: flex-start;
    padding: 0.325rem 1.25rem !important;
    width: 360px;
    border-radius: 4px; }
    .signup-buttons .signup-button a:not(.btn-link),
    .signup-buttons .google-login:not(.btn-link) {
      color: #fff; }
      .signup-buttons .signup-button a:not(.btn-link):hover,
      .signup-buttons .google-login:not(.btn-link):hover {
        color: #fff;
        opacity: 0.9;
        text-decoration: none; }
  .signup-buttons .google-login {
    border-radius: 4px !important;
    font-family: inherit !important;
    font-size: 1rem !important; }
    .signup-buttons .google-login > div {
      width: 26px;
      display: block;
      margin-right: 2rem !important;
      height: 18px;
      padding: 0 !important; }
      .signup-buttons .google-login > div svg {
        width: auto;
        display: block;
        height: 100%;
        max-width: 100%; }
    .signup-buttons .google-login > span {
      padding: 0 !important; }
  .signup-buttons .button-salesforce {
    background-color: #01a0e1; }
  .signup-buttons .button-google {
    background-color: #4287f6; }
  .signup-buttons .button-office {
    background-color: #d93c01; }
  .signup-buttons .button-linkedin {
    background-color: #0277b6; }
  .signup-buttons .btn-link {
    color: #009dda; }

.find-an-expert-contact-information-icon {
  width: 46px;
  height: 46px;
  background: url(/static/media/icon-sprite.1ababf40.png) -145px -10px; }

.find-an-expert-experts-icon {
  width: 40px;
  height: 46px;
  background: url(/static/media/icon-sprite.1ababf40.png) -275px -10px; }

.find-an-expert-experts-type-icon {
  width: 46px;
  height: 46px;
  background: url(/static/media/icon-sprite.1ababf40.png) -10px -83px; }

.find-an-expert-get-started-icon {
  width: 46px;
  height: 35px;
  background: url(/static/media/icon-sprite.1ababf40.png) -74px -149px; }

.find-an-expert-location-icon {
  width: 46px;
  height: 46px;
  background: url(/static/media/icon-sprite.1ababf40.png) -76px -83px; }

.find-an-expert-project-type-icon {
  width: 44px;
  height: 44px;
  background: url(/static/media/icon-sprite.1ababf40.png) -10px -149px; }

.find-an-expert-request-received-icon {
  width: 46px;
  height: 45px;
  background: url(/static/media/icon-sprite.1ababf40.png) -142px -83px; }

.find-an-expert-start-date-icon {
  width: 49px;
  height: 47px;
  background: url(/static/media/icon-sprite.1ababf40.png) -76px -10px; }

.find-an-expert-timeline-icon {
  width: 44px;
  height: 48px;
  background: url(/static/media/icon-sprite.1ababf40.png) -211px -10px; }

.find-an-expert-time-commitment-icon {
  width: 44px;
  height: 48px;
  background: url(/static/media/icon-sprite.1ababf40.png) -211px -78px; }

.find-an-expert-budget-icon {
  width: 46px;
  height: 53px;
  background: url(/static/media/icon-sprite.1ababf40.png) -10px -10px; }

@media (max-width: 991px) {
  .container {
    max-width: 100% !important; } }

@media all and (max-width: 767px) {
  html {
    font-size: 14px; } }

/*!
 * Bootstrap v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #009dda;
  --secondary: #6c757d;
  --success: #0ac297;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #df674c;
  --light: #f8f9fa;
  --dark: #292934;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #009dda;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #00668e;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.75rem;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.75rem;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8e4f5; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7accec; }

.table-hover .table-primary:hover {
  background-color: #a2dcf2; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a2dcf2; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #baeee2; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #80dfc9; }

.table-hover .table-success:hover {
  background-color: #a6e9d9; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a6e9d9; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f6d4cd; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #eeb0a2; }

.table-hover .table-danger:hover {
  background-color: #f2c1b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f2c1b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c3c3c6; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #909095; }

.table-hover .table-dark:hover {
  background-color: #b6b6ba; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b6b6ba; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #5bd1ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 157, 218, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
          appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #0ac297; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(10, 194, 151, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #0ac297;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%230ac297' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #0ac297;
    box-shadow: 0 0 0 0.2rem rgba(10, 194, 151, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #0ac297;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%230ac297' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #0ac297;
    box-shadow: 0 0 0 0.2rem rgba(10, 194, 151, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #0ac297; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #0ac297; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #0ac297; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #0df3bd;
  background-color: #0df3bd; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(10, 194, 151, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #0ac297; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #0ac297; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #0ac297;
  box-shadow: 0 0 0 0.2rem rgba(10, 194, 151, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #df674c; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(223, 103, 76, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #df674c;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23df674c' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23df674c' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #df674c;
    box-shadow: 0 0 0 0.2rem rgba(223, 103, 76, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #df674c;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23df674c' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23df674c' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #df674c;
    box-shadow: 0 0 0 0.2rem rgba(223, 103, 76, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #df674c; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #df674c; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #df674c; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e78c77;
  background-color: #e78c77; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(223, 103, 76, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #df674c; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #df674c; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #df674c;
  box-shadow: 0 0 0 0.2rem rgba(223, 103, 76, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 157, 218, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #009dda;
  border-color: #009dda; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0081b4;
    border-color: #0078a7; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0081b4;
    border-color: #0078a7;
    box-shadow: 0 0 0 0.2rem rgba(38, 172, 224, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #009dda;
    border-color: #009dda; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0078a7;
    border-color: #006f9a; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 172, 224, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #0ac297;
  border-color: #0ac297; }
  .btn-success:hover {
    color: #fff;
    background-color: #089e7b;
    border-color: #089271; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #089e7b;
    border-color: #089271;
    box-shadow: 0 0 0 0.2rem rgba(47, 203, 167, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #0ac297;
    border-color: #0ac297; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #089271;
    border-color: #078568; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(47, 203, 167, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #df674c;
  border-color: #df674c; }
  .btn-danger:hover {
    color: #fff;
    background-color: #d94b2c;
    border-color: #d24526; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #d94b2c;
    border-color: #d24526;
    box-shadow: 0 0 0 0.2rem rgba(228, 126, 103, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #df674c;
    border-color: #df674c; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #d24526;
    border-color: #c84224; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(228, 126, 103, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #292934;
  border-color: #292934; }
  .btn-dark:hover {
    color: #fff;
    background-color: #18181f;
    border-color: #131317; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #18181f;
    border-color: #131317;
    box-shadow: 0 0 0 0.2rem rgba(73, 73, 82, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #292934;
    border-color: #292934; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #131317;
    border-color: #0d0d10; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(73, 73, 82, 0.5); }

.btn-outline-primary {
  color: #009dda;
  border-color: #009dda; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #009dda;
    border-color: #009dda; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 157, 218, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #009dda;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #009dda;
    border-color: #009dda; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 157, 218, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #0ac297;
  border-color: #0ac297; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #0ac297;
    border-color: #0ac297; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(10, 194, 151, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #0ac297;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #0ac297;
    border-color: #0ac297; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(10, 194, 151, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #df674c;
  border-color: #df674c; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #df674c;
    border-color: #df674c; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(223, 103, 76, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #df674c;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #df674c;
    border-color: #df674c; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(223, 103, 76, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #292934;
  border-color: #292934; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #292934;
    border-color: #292934; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(41, 41, 52, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #292934;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #292934;
    border-color: #292934; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(41, 41, 52, 0.5); }

.btn-link {
  font-weight: 400;
  color: #009dda;
  text-decoration: none; }
  .btn-link:hover {
    color: #00668e;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #009dda; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
          color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #009dda;
    background-color: #009dda; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 157, 218, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #5bd1ff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #8edfff;
    border-color: #8edfff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #009dda;
  background-color: #009dda; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 157, 218, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 157, 218, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 157, 218, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 157, 218, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #5bd1ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 157, 218, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #5bd1ff;
    box-shadow: 0 0 0 0.2rem rgba(0, 157, 218, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 157, 218, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 157, 218, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 157, 218, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #009dda;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #8edfff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #009dda;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #8edfff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #009dda;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #8edfff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #009dda; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #009dda;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #00668e;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 157, 218, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #009dda;
  border-color: #009dda; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #009dda; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0078a7; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 157, 218, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #0ac297; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #089271; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(10, 194, 151, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #df674c; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #d24526; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(223, 103, 76, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #292934; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #131317; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(41, 41, 52, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #005271;
  background-color: #ccebf8;
  border-color: #b8e4f5; }
  .alert-primary hr {
    border-top-color: #a2dcf2; }
  .alert-primary .alert-link {
    color: #002d3e; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #05654f;
  background-color: #cef3ea;
  border-color: #baeee2; }
  .alert-success hr {
    border-top-color: #a6e9d9; }
  .alert-success .alert-link {
    color: #033429; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #743628;
  background-color: #f9e1db;
  border-color: #f6d4cd; }
  .alert-danger hr {
    border-top-color: #f2c1b7; }
  .alert-danger .alert-link {
    color: #4e241b; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #15151b;
  background-color: #d4d4d6;
  border-color: #c3c3c6; }
  .alert-dark hr {
    border-top-color: #b6b6ba; }
  .alert-dark .alert-link {
    color: black; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #009dda;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #009dda;
    border-color: #009dda; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #005271;
  background-color: #b8e4f5; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #005271;
    background-color: #a2dcf2; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #005271;
    border-color: #005271; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #05654f;
  background-color: #baeee2; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #05654f;
    background-color: #a6e9d9; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #05654f;
    border-color: #05654f; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #743628;
  background-color: #f6d4cd; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #743628;
    background-color: #f2c1b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #743628;
    border-color: #743628; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #15151b;
  background-color: #c3c3c6; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #15151b;
    background-color: #b6b6ba; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #15151b;
    border-color: #15151b; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #009dda !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0078a7 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #0ac297 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #089271 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #df674c !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #d24526 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #292934 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #131317 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #009dda !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #0ac297 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #df674c !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #292934 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #009dda !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #00668e !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #0ac297 !important; }

a.text-success:hover, a.text-success:focus {
  color: #06795e !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #df674c !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd3e22 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #292934 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #070709 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }



.redux-table .search-input {
  position: relative;
  /* Safari */ }
  .redux-table .search-input input {
    height: 35px;
    padding-right: 30px; }
  .redux-table .search-input .search-icon {
    position: absolute;
    right: 10px;
    top: 0;
    line-height: 35px; }
  .redux-table .search-input .loader {
    right: 10px;
    top: 10px;
    border: 2px solid #ddd;
    border-radius: 50%;
    border-top: 2px solid #3498db;
    width: 15px;
    height: 15px;
    animation: spin 2s linear infinite;
    position: absolute; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.redux-table .table th {
  font-weight: 400;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  border: 1px solid #292934;
  border-right: 1px solid #000;
  min-width: 107px; }
  .redux-table .table th svg {
    float: right;
    padding: 3px;
    width: 24px;
    height: 24px; }
  .redux-table .table th.action-column {
    width: 90px; }

.redux-table .table td {
  padding: 1.5rem 0.5rem 1.5rem 0.5rem; }

.redux-table .table tbody {
  background-color: #fff; }

.redux-table .table .thead-light th {
  background-color: #292934;
  color: #fff;
  vertical-align: top; }

.redux-table .table .dropdown-wrapper.more-dropdown .dropdown > .btn {
  padding: 0; }

.imagecarousel-photos .remove-icon {
  visibility: hidden; }
  .imagecarousel-photos .remove-icon:hover {
    color: #fff;
    background-color: red;
    border-radius: 54px; }

.imagecarousel-photos:hover .remove-icon {
  visibility: visible; }

.lds-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(0.5);
  margin-bottom: -1.25rem; }

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite; }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #335262; }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s; }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s; }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s; }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s; }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s; }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s; }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s; }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s; }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s; }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s; }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s; }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s; }

@keyframes lds-spinner {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }


/**
* Shows how you can use CSS to style your Element's container.
*/
.MyCardElement {
  background: #f3f3f4;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  margin-top: 15px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease; }

.MyCardElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df; }

.MyCardElement--invalid {
  border-color: #fa755a; }

.MyCardElement--webkit-autofill {
  background-color: #fefde5 !important; }

.videocarousel-photos .remove-icon {
  visibility: hidden; }
  .videocarousel-photos .remove-icon:hover {
    color: #fff;
    background-color: red;
    border-radius: 54px; }

.videocarousel-photos:hover .remove-icon {
  visibility: visible; }

#sidebar {
  border-right: 1px solid #e2e2e8;
  color: lightgray;
  min-height: calc(100vh - 61px);
  height: 100%;
  position: relative;
  padding-top: 1.5rem; }
  #sidebar .primary-sidebar {
    border-right: 1px solid #e2e2e8;
    color: lightgray;
    min-height: calc(100vh - 61px);
    height: 100%;
    position: relative;
    padding-top: 1.5rem; }
  #sidebar .sidebar-menu {
    margin-top: 10px; }
    @media screen and (min-width: 991px) {
      #sidebar .sidebar-menu {
        float: left; } }
  #sidebar .sidebar-header {
    padding: 20px;
    background: #6d7fcc; }
  #sidebar ul p {
    color: #292934;
    margin-top: 10px;
    font-size: 20px; }
  #sidebar ul li {
    border-left: 3px solid transparent;
    transition: all 180ms ease; }
    #sidebar ul li.active {
      background-color: #fff;
      border-left: 3px solid #009dda; }
      #sidebar ul li.active svg {
        color: #009dda; }
        #sidebar ul li.active svg path {
          fill: currentColor !important; }
    #sidebar ul li:not(:first-child) {
      margin-top: 10px; }
    #sidebar ul li a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0.8125rem 0.875rem;
      font-size: 1rem;
      color: #292934;
      text-decoration: none; }
    #sidebar ul li svg {
      max-height: 22px;
      margin: 0 1rem 0 0;
      min-width: 24px; }
      @media (max-width: 991px) {
        #sidebar ul li svg {
          margin-right: 0; } }
    #sidebar ul li:hover {
      background-color: #fff;
      border-left: 3px solid #3572b0;
      color: #000; }
      #sidebar ul li:hover .sidebar-menu {
        color: #2496cd; }

a[aria-expanded="true"] {
  color: #292934; }

@media (max-width: 991px) {
  .sidebar-wrapper.w-auto {
    padding-left: 0;
    max-width: 71px; } }

@media (max-width: 575px) {
  .sidebar-wrapper {
    position: fixed;
    z-index: 13;
    background-color: rgba(0, 0, 0, 0.1); }
    .sidebar-wrapper.w-auto {
      padding-right: 0;
      border-right: 0;
      max-width: none;
      width: 100% !important; }
    .sidebar-wrapper.menu-hidden {
      display: none; }
    .sidebar-wrapper.menu-toggle {
      display: block; }
    .sidebar-wrapper #sidebar {
      width: calc(100vw - 80px);
      background-color: #f4f4f6;
      border-right: 0;
      min-height: calc(100vh - 46px); }
    .sidebar-wrapper ul li svg {
      margin-right: 1rem !important; }
    .sidebar-wrapper ul .desktop-only {
      display: block; }
    .sidebar-wrapper #sidebar {
      padding-top: 1rem;
      position: absolute;
      overflow-y: scroll; } }

